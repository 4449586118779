import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";

const useParams = (filterParams = undefined) => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const removeQueryParams = (toDeleteParams) => {
    toDeleteParams.forEach((param) => queryParams.delete(param));
    history.push({ search: queryParams.toString() });
  };

  const getQueryParam = (param) => queryParams.get(param);

  const mergeQueryParams = (newQueryParams, redirectUrl = null) => {
    const entries = queryParams.entries(); //returns an iterator of decoded [key,value] tuples
    const params = Object.fromEntries(entries);
    setQueryParams({
      ...params,
      ...newQueryParams,
    }, redirectUrl);
  };

  const setQueryParams = (newQueryParams, redirectUrl = null) => {
    let stringifiedQueryParams = "";

    if (filterParams) {
      const filteredQueryParams = filterParams(newQueryParams);
      stringifiedQueryParams = queryString.stringify(filteredQueryParams, {
        arrayFormat: "bracket",
      });
    } else {
      stringifiedQueryParams = queryString.stringify(newQueryParams, {
        arrayFormat: "bracket",
      });
    }

    const historyObj = {
      search: stringifiedQueryParams,
    };

    if (redirectUrl) {
      // We are loading inside a portal where most of the page is SSR.
      // If we use history API, then React page is loaded inside the portal and HTML remains static.
      window.location.replace(`${redirectUrl}?${historyObj.search}`);
    } else {
      history.push(historyObj);
    }
  };


  return {
    getQueryParam,
    setQueryParams,
    mergeQueryParams,
    removeQueryParams,
  };
};

export default useParams;
