import { useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { track } from "@app/components/common/analytics";
import { showErrorAlertFromErr } from "@app/redux/alert";
import api from "@app/api";

const useSignupToDownload = ({
  defaultEmail = undefined,
  analyticsEventName = "In Article Download",
  contentToDownload = undefined,
  linkToContentToDownload = undefined,
  onSuccess = undefined,
}) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(defaultEmail);
  const [isEmailMissing, setIsEmailMissing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleEmailChange = eventOrValue => {
    if (typeof eventOrValue === "string") {
      setEmail(eventOrValue);
    } else {
      setEmail(eventOrValue.target.value);
    }
    setIsEmailMissing(false);
  };

  const resetForm = () => {
    setSaving(false);
    setSuccess(false);
  };

  const handleSubmit = async (e) => {
    e && e.preventDefault();
    setSaving(true);
    setIsEmailMissing(false);
    if (!email) {
      setIsEmailMissing(true);
      setSaving(false);
      return false;
    }
    try {
      await api.clickToDownload(email, contentToDownload, linkToContentToDownload);
      setSaving(false);
      setSuccess(true);
      track(analyticsEventName, {
        type: analyticsEventName,
        source: contentToDownload,
      });
      onSuccess && onSuccess();
    } catch (e) {
      setSaving(false);
      dispatch(showErrorAlertFromErr(e));
    }
  };

  return {
    handleEmailChange,
    handleSubmit,
    isEmailMissing,
    saving,
    success,
    resetForm,
    email
  };
};

export default useSignupToDownload;
