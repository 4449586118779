import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { track } from "@app/components/common/analytics";
import styles from "./index.module.scss";

const LinkedInButton = ({ type, text }) => {
  const code = useSelector(state => state.userData.code);
  const isMobileApp = useSelector(state => state.userData.isMobileApp);
  const liClientId = useSelector(state => state.userData.liClientId);
  const liCallback = useSelector(state => state.userData.liCallback);
  const modalData = useSelector(state => state.modals.data);
  const { isSupporter, clickSource, community = {} } = modalData;
  let stateVal = `type=${type}`;
  if (code) {
    stateVal += `,code=${code}`;
  }

  if (community.slug) {
    stateVal += `,group=${community.slug}`;
  }

  if (clickSource) {
    stateVal += `,signup_source=${clickSource}`;
  }

  if (isSupporter) {
    stateVal += `,is_supporter=${isSupporter}`;
  }
  stateVal += `,final_url=${window.location.href}`;

  const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${liClientId}&redirect_uri=${liCallback}&scope=r_liteprofile r_emailaddress&state=${stateVal}`;

  const handleClick = () => {
    if (type === "login") {
      gtag("event", "sign_in", { method: "LinkedIn" });
      track("User Logged In", { type: "LinkedIn" });
    } else {
      gtag("event", "sign_up", { method: "LinkedIn" });
    }
    window.location = url
  };

  if (isMobileApp) return null;
  return (
    <button type="button" onClick={handleClick} className={classNames(styles.auth, "btn")}>
      {text}
    </button>
  );
};
export default LinkedInButton;
