import { createSlice } from "@reduxjs/toolkit";

const modals = createSlice({
  name: "modals",
  initialState: {
    active: null,
    data: {},
  },
  reducers: {
    setActiveModal(state, action) {
      state.active = action.payload.active;
      state.data = action.payload.data || {};
      return state;
    },
    clearActiveModal(state) {
      state.active = null;
      state.data = {};
      return state;
    },
    togglePendingReminderModal(state) {
      if (state.active === null) {
        state.active = "pending-reminder-modal";
        return state;
      }
      if (state.active === "pending-reminder-modal") {
        state.active = null;
        return state;
      }
      return state;
    },
  },
});

export const {
  setActiveModal,
  clearActiveModal,
  togglePendingReminderModal,
} = modals.actions;

export default modals.reducer;
