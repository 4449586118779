import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import useTestChecker from "@app/hooks/useTestChecker";
import { track } from "@app/components/common/analytics"
import CloseModalButton from "@app/components/common/CloseModalButton";
import Thumbnail from "@app/components/common/Thumbnail";
import JoinBox from "../JoinBox";
import AlreadyAMemberButton from "../AlreadyAMemberButton";
import SupporterButton from "../SupporterButton";
import styles from "./index.module.scss";
import ExitIntent from "../utils/ExitIntent"
import EmailToDownload from "./EmailToDownload";

const MembersWorkAt = () => (
  <div className={styles.box}>
    <h2 className={styles.text}>Elpha members work at</h2>
    <img className={styles.logo} src="https://d27zilrr3yp6cg.cloudfront.net/landing/companies/sequoia.svg" />
    <img className={styles.logo} src="https://d27zilrr3yp6cg.cloudfront.net/landing/companies/google.svg" />
    <img className={classNames(styles.logo, styles.smallLogo)} src="https://d27zilrr3yp6cg.cloudfront.net/landing/companies/stripe-2.svg" />
    <img className={styles.logo} src="https://d27zilrr3yp6cg.cloudfront.net/landing/companies/airbnb.svg" />
  </div>
);

const LoginOverlay = ({ closable = true, delayed = false }) =>{
  const active = useSelector(state => state.modals.active);
  const anotherActiveModalRef = useRef(!!active);
  useEffect(() => {
    anotherActiveModalRef.current = active;
  }, [active]);

  const unsubscribeEvent = () => ExitIntent({
    threshold: 30,
    eventThrottle: 100,
    onExitIntent: () => {
      if (anotherActiveModalRef.current) return;
      setClosed(false)
    },
  });
  const [closed, setClosed] = useState(delayed);
  const [removeExitIntent, setRemoveExitIntent] = useState(unsubscribeEvent);

  const {
    testVersion,
    titleText,
    bodyText,
    isPostPage,
    isResourcePage,
    isContentDownload,
    isJobRelatedPage,
    isCompRelatedPage,
  } = useTestChecker();

  useEffect(() => {
    if (delayed) {
      setTimeout(() => {
        if (anotherActiveModalRef.current) return;
        setClosed(false);
        removeExitIntent && removeExitIntent();
      }, 45000);
    }

    return () => {
      removeExitIntent && removeExitIntent()
    }
  }, []);

  if (!isPostPage && !isResourcePage) {
    return null;
  }

  if (closed) {
    return null;
  }

  track("Sign Up Overlay", {
    isPostPage,
    isResourcePage,
    isContentDownload,
    isJobRelatedPage,
    isCompRelatedPage,
    isDelayed: delayed,
    testVersion,
  });

  const handleClose = () => {
    if (closable) {
      removeExitIntent && removeExitIntent();
      setClosed(true);
    }
  };

  return (
    <div className={styles.testContainerV02}>
      {closable && <CloseModalButton onClick={handleClose} />}
      <div className={styles.testTitle}>{titleText}</div>
      <div className={styles.testV02Subheader}>{bodyText}</div>
      {isContentDownload && <EmailToDownload />}

      {!isContentDownload && (
      <>
        <div className={styles.testV01Button}>
          <JoinBox ctaOverride="Join" />
        </div>
        <div className={styles.testV01AlreadyMember}><AlreadyAMemberButton /></div>
      </>
      )}
      <hr className={styles.testV02Hr} />
      <div className={styles.testV02Testimonial}>

        <Thumbnail imgUrl="https://d27zilrr3yp6cg.cloudfront.net/registration/v02/testimonial-1.png" clazz={styles.thumbnail} />
        <div className={styles.testV02TestimonialCaption}>
          “Elpha is a wonderful product and I'm so happy to be part of the community. Elpha changed my life in terms of salary negotiation, networking, and more!”
          <span className={styles.testV02TestimonialCaptionAuthor}>  – Sam C.</span>
        </div>
      </div>
      <hr />
      <MembersWorkAt />
    </div>
  );
};
export default LoginOverlay;
