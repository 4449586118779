import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal } from "@app/redux/modals";
import registerAPI from "@app/redux/register/api";
import { track } from "@app/components/common/analytics";
import { logIn } from "@app/redux/userData";
import { showErrorAlertFromErr } from "@app/redux/alert";

/**
 * @callback handleEmailChange
 * @param {(Event | string)} eventOrValue - The DOM onChange event from the
 * email text field, or simply the value of the field itself.
 */

/**
 * @callback handleSubmit
 * @param {Event} [event] - The DOM onSubmit event for the form. Optional - you
 * can also call handleSubmit directly if the email address has already been
 * passed to `useSimpleSignup` via `config.defaultEmail`
 */

/**
 * @callback onSuccess
 */

/**
 * @typedef UseSimpleSignupHook
 * @property {handleEmailChange} handleEmailChange - A callback to call when the
 * email field is changed.
 * @property {handleSubmit} handleSubmit - A callback to call when the form is
 * submitted.
 * @property {boolean} isEmailMissing - A boolean indicating whether the email
 * address is missing.
 * @property {boolean} saving - A boolean indicating whether the user is
 * currently being saved.
 */

/**
 * A hook for doing a simple email-based signup form. Attach the listeners and
 * properties to your form to get the functionality you need to register a user.
 *
 * @param {object} config
 * @param {string} [config.defaultEmail] - The default email address to use
 * (e.g., from a query string).
 * @param {string} [config.analyticsEventName] - The name to use for the
 * analytics event when the user signs up. Defaults to "Email Sign Up Banner",
 * which is used for any modal that someone signs up from.
 * @param {string} [config.analyticsSource] - The source for this signup, to be
 * saved in analytics. Used for specifying whether this signup occurred on a
 * resource page vs. a post, etc.
 * @param {string} [config.redirectUrl] - The URL to redirect the user to after
 * they successfully sign up.
 * @param {object} [config.additionalDataForSignup] - An object of key-value
 * pairs to merge with the data sent to the `/authenticate/pending` endpoint.
 * @param {onSuccess} [config.onSuccess] - This callback will be called when the
 * user successfully signs up.
 *
 * @returns {UseSimpleSignupHook}
 */
const useSimpleSignup = ({
  isSupporter,
  defaultEmail = undefined,
  analyticsEventName = "Email Sign Up Banner",
  analyticsSource = undefined,
  redirectUrl = undefined,
  additionalDataForSignup = {},
  onSuccess = undefined,
}) => {
  const dispatch = useDispatch();
  const active = useSelector(state => state.userData.active);
  const code = useSelector(state => state.userData.code);
  const source = useSelector(state => state.userData.source);

  const [email, setEmail] = useState(defaultEmail);
  const [isEmailMissing, setIsEmailMissing] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleEmailChange = eventOrValue => {
    if (typeof eventOrValue === "string") {
      setEmail(eventOrValue);
    } else {
      setEmail(eventOrValue.target.value);
    }
    setIsEmailMissing(false);
  };

  const handleSubmit = async e => {
    e && e.preventDefault();
    setSaving(true);
    setIsEmailMissing(false);
    if (!email) {
      setIsEmailMissing(true);
      setSaving(false);
      return false;
    }
    try {
      const { data } = await registerAPI.submitStep1({
        isSupporter,
        code: code || source,
        email,
        redirectUrl,
        additionalDataForSignup
      });
      const { user } = data;
      track("User Created v5", {
        week: user.created_at_week,
        month: user.created_at_month,
        status: user.status,
        isMobileApp: false,
        code: code || source,
        source: user.source,
        signup_source: user.signup_source
      });
      if (onSuccess) onSuccess();
      gtag("event", "sign_up", { method: "Email" });
      track(analyticsEventName, {
        type: active,
        source: analyticsSource,
      });
      window.lintrk && window.lintrk('track', { conversion_id: 5345425 });
      dispatch(logIn(user, active));
      dispatch(setActiveModal({ active: "signup", data: { step: 2 }}))
    } catch (e) {
      setSaving(false);
      dispatch(showErrorAlertFromErr(e));
    }
  };

  return {
    handleEmailChange,
    handleSubmit,
    isEmailMissing,
    saving,
  };
};

export default useSimpleSignup;
