import React from "react";
import { useSelector } from "react-redux";
import useCommunityActionButtons from "@app/hooks/useCommunityActionButtons";
import { isCompaniesPage } from "@app/helpers/navbarHelper";
import DesktopButtonGroup from "../DesktopButtonGroup";
import MobileButtonGroup from "../MobileButtonGroup";

const CommunityActions = ({ isDesktop }) => {
  const { communityActionButtons } = useCommunityActionButtons();
  const active = useSelector(state => state.userData.active);

  const showForMobile = !isDesktop && !isCompaniesPage(active);
  if (isDesktop) {
    return (
      <>
        <DesktopButtonGroup buttons={communityActionButtons} isLoading={false} />
        <hr/>
      </>
    );
  } else if (showForMobile) {
    return <MobileButtonGroup buttons={communityActionButtons} isLoading={false} />;
  }
  return null;
};
export default CommunityActions;
