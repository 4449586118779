import React from "react";
import Thumbnail from "@app/components/common/Thumbnail";
import styles from "./shared.module.scss";

const CommunityMember = ({ member }) => {
  return (
    <a className={styles.memberBox} href={`/members/${member.username}`}>
      <Thumbnail imgUrl={member.picture_url} />
      <div className={styles.memberInfo}>
        <div className={styles.memberInfoName}>{member.full_name}</div>
        <div className={styles.memberInfoSubtitle}>{member.title_line}</div>
      </div>
    </a>
  );
};
export default CommunityMember;
