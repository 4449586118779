import React from "react";
import DesktopButtonGroup from "../DesktopButtonGroup";
import MobileButtonGroup from "../MobileButtonGroup";
import styles from "../../shared.module.scss";
import useCommunityButtons from "./useCommunityButtons";

const CommunityButtons = ({ isDesktop }) => {
  const { isLoading, communityButtons } = useCommunityButtons();
  if (isDesktop) {
    return (
      <>
        <div className={styles.sideBarSubheader}>Your communities</div>
        <DesktopButtonGroup
          buttons={communityButtons}
          isLoading={isLoading}
        />
      </>
    );
  } else {
    return (
      <>
        <div className={styles.mobileSideBarSubheader}>Your communities</div>
        <MobileButtonGroup
          buttons={communityButtons}
          isLoading={isLoading}
        />
      </>
    );
  }
};
export default CommunityButtons;
