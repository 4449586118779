import axios from "axios";

import { setCSRFTokenForRequest } from "@app/api/utils";

const commentAPI = {
  createComment: ({
    postPath,
    postSlug,
    parentCommentPath,
    text,
    picture,
    anon,
    author
  }) => {
    const comment = {
      text,
      picture
    };
    return setCSRFTokenForRequest(() => {
      let url = `/posts/${postPath}/${postSlug}/comments`;
      if (parentCommentPath) {
        url = `${url}/${parentCommentPath}`;
      }
      return axios.post(url, { comment, anon, author });
    });
  },
  fetchBotCommentDraft: (postPath, postSlug, commentPath) => {
    return setCSRFTokenForRequest(() => {
      return axios.get(`/posts/${postPath}/${postSlug}/comments/${commentPath}/bot.json`);
    });
  },
  updateComment: (postPath, postSlug, commentPath, text, picture) => {
    const comment = {
      text,
      picture
    };
    return setCSRFTokenForRequest(() => {
      const url = `/posts/${postPath}/${postSlug}/comments/${commentPath}`;
      return axios.put(url, { comment });
    });
  },
  pinComment: (postPath, postSlug, commentPath) => {
    return setCSRFTokenForRequest(() => {
      return axios.put(`/posts/${postPath}/${postSlug}/comments/${commentPath}/pin`);
    });
  },
  unpinComment: (postPath, postSlug, commentPath) => {
    return setCSRFTokenForRequest(() => {
      return axios.put(`/posts/${postPath}/${postSlug}/comments/${commentPath}/unpin`);
    });
  },
  deleteComment: (postPath, postSlug, commentPath) => {
    return setCSRFTokenForRequest(() => {
      return axios.delete(`/posts/${postPath}/${postSlug}/comments/${commentPath}`);
    });
  },
  deleteReaction: (postPath, postSlug, commentPath, unicode) => {
    return setCSRFTokenForRequest(() => {
      return axios.delete(`/posts/${postPath}/${postSlug}/comments/${commentPath}/reaction/${unicode}`);
    });
  },
  createReaction: (postPath, postSlug, commentPath, shortcode, unicode) => {
    return setCSRFTokenForRequest(() => {
      const url = `/posts/${postPath}/${postSlug}/comments/${commentPath}/reaction`;
      return axios.post(url, { unicode, shortcode });
    });
  },
  fetchComments: (postPath, postSlug) => {
    return setCSRFTokenForRequest(() => {
      return axios.get(`/posts/${postPath}/${postSlug}/comments.json`);
    });
  },
};

export default commentAPI;
