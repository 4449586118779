import React from "react";
import { useMediaQuery } from "react-responsive";
import { Glyphicon } from "react-bootstrap";
import styles from "./index.module.scss";

const MobileAccountMenu = ({ onClick }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)'});

  if (isMobile) {
    return (
      <div onClick={onClick} className={styles.root}>
        <Glyphicon glyph="menu-hamburger" />
      </div>
    );
  }
  return null;
};
export default MobileAccountMenu;
