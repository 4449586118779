import React from "react";
import { useDispatch } from "react-redux";
import { Glyphicon } from "react-bootstrap";
import { toggleMobileSidebar } from "@app/redux/sidebar";
import styles from "./index.module.scss";

const CloseMobileSideBar = () => {
  const dispatch = useDispatch();
  const handleMobileClick = () => dispatch(toggleMobileSidebar());
  return (
    <div className={styles.mobileSideBarSubheader}>
      <span className={styles.close} onClick={handleMobileClick}>
        <Glyphicon glyph="remove" />
      </span>
    </div>
  );
};
export default CloseMobileSideBar;
