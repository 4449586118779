import React, { useEffect, useState } from "react";
import classNames from "classnames"
import Thumbnail from "@app/components/common/Thumbnail";
import { track } from "@app/components/common/analytics";
import styles from "./index.module.scss";

const FeaturedCompany = ({
  clazz,
  company
}) => {

  const handleClick = () => {
    track("Featured Company Page", { company: company.slug });
    window.location = `/companies/${company.slug}`;
  };
  
  return (
    <div onClick={handleClick} className={classNames(styles.featuredCompany, clazz)}>
      <Thumbnail imgUrl={company.logo} clazz={styles.thumbnail} />
      <div className={styles.details}>
        <div className={styles.hideOverflow}>
          <div className={styles.name}>{company.name}</div>
          <div className={styles.count}>{company.positions} {company.positions == 1 ? "job" : "jobs"}</div>
        </div>
        <div className={styles.depts}>{company.departments.join(", ")}</div>
      </div>
    </div>
  );
};
export default FeaturedCompany;
