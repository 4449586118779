const companiesPages = [
  "companies-partnerships",
  "companies-index",
  "companies-show",
  "positions-index",
  "positions-show",
  "positions-mine",
];

export const isCompaniesPage = page => (
  companiesPages.indexOf(page) !== -1
);

export const COMPANY_PAGES = [
  "company-index",
  "company-description",
  "company-quick-facts",
  "company-share",
  "company-team-share",
  "company-linkedin-share",
];

const employerPages = [
  "ats_integration-home",
  "candidates-index",
  "company_intros-index",
  "hiring-billing",
  "hiring-company",
  "hiring-index",
  "hiring-team",
  "messages-home",
  "message_templates-edit",
  "message_templates-index",
  "message_templates-new",
  "jobs-index",
  "jobs-show",
  "jobs-new",
  "self_serve_jobs-new",
] + COMPANY_PAGES;

export const isEmployerPage = page => employerPages.includes(page);
