import { createSlice } from "@reduxjs/toolkit";

import combineSlices from "@app/helpers/combineSlices";
import companyIntros from "./companyIntros";
import messageTemplates from "./messageTemplates";
import candidates from "./candidates";
import hiringAPI from "./api";
import parseError from "@app/helpers/parseError";
import { setSuccessAlert, showErrorAlertFromErr } from "../alert";

const initialState = {
  stripePortalSessionUrl: undefined,
  teamMembers: [],
  jobs: [],
  submittedJobId: undefined,
  submittedUserHash: undefined,
  company: undefined,
  companyDescription: undefined,

  isJobSaving: false,
  isJobStateChanging: false,
  isReadyToRedirect: false,
  isCompanySaving: false,
  isFetchingJobs: false,
  isCompanyDescriptionSaving: false,

  // Candidate messages
  candidateMessages: [],
  candidateMessagesError: undefined,
  isFetchingCandidateMessages: false,

  // Saved candidates
  savedCandidates: [],
  savedCandidatesError: undefined,
  isFetchingSavedCandidates: false,

  // Stats
  stats: {},
  statsError: undefined,
  isFetchingStats: false,
  hasStatsData: false,

  candidate: {
    uuid: null,
    possibleHiringMatches: [],
    isFetchingHiringMatches: false,
  },
};

const hiring = createSlice({
  name: "hiring",
  initialState,
  reducers: {
    changeJobState(state, action) {
      const { jobId, jobState } = action.payload;
      state.jobs = state.jobs.map(job => {
        if (job.id === jobId) {
          job.state = jobState;
        }
        return job;
      });
    },
    setJob(state, action) {
      const index = state.jobs.findIndex(job => job.id === action.payload.id);
      state.jobs[index] = action.payload;
    },
    setIsJobSaving(state, action) {
      state.isJobSaving = action.payload;
    },
    setIsJobStateChanging(state, action) {
      state.isJobStateChanging = action.payload;
    },
    setSubmittedJobId(state, action) {
      state.submittedJobId = action.payload;
    },
    setSubmittedUserHash(state, action) {
      state.submittedUserHash = action.payload;
    },
    setCompanyDescription(state, action) {
      state.companyDescription = action.payload;
    },
    setCompanyDescriptionStatus(state, action) {
      state.companyDescription.status = action.payload;
    },
    setIsCompanyDescriptionSaving(state, action) {
      state.isCompanyDescriptionSaving = action.payload;
    },
    setIsFetchingJobs(state, action) {
      state.isFetchingJobs = action.payload;
    },
    setCompany(state, action) {
      state.company = action.payload.company;
    },
    setJobs(state, action) {
      state.jobs = action.payload;
    },
    setTeamMembers(state, action) {
      state.teamMembers = action.payload.teamMembers;
    },
    setStripePortalSessionUrl(state, action) {
      state.stripePortalSessionUrl = action.payload.url;
    },
    setIsCompanySaving(state, action) {
      state.isCompanySaving = action.payload;
    },

    setIsReadyToRedirect(state, action) {
      state.isReadyToRedirect = true;
    },
    clearIsReadyToRedirect(state, action) {
      state.isReadyToRedirect = false;
    },
    // Candidate messages
    setCandidateMessagesError(state, action) {
      state.candidateMessagesError = action.payload;
    },
    setIsFetchingCandidateMessages(state, action) {
      state.isFetchingCandidateMessages = action.payload;
    },
    setCandidateMessages(state, action) {
      state.candidateMessages = action.payload;
    },

    // Saved candidates
    setSavedCandidatesError(state, action) {
      state.savedCandidatesError = action.payload;
    },
    setIsFetchingSavedCandidates(state, action) {
      state.isFetchingSavedCandidates = action.payload;
    },
    setSavedCandidates(state, action) {
      state.savedCandidates = action.payload;
    },

    // Stats
    setStats(state, action) {
      state.stats = action.payload;
      state.hasStatsData = true;
    },
    setStatsError(state, action) {
      state.statsError = action.payload;
    },
    setIsFetchingStats(state, action) {
      state.isFetchingStats = action.payload;
    },

    // Matches
    setIsFetchingHiringMatchesForCandidate(state, action) {
      state.candidate.isFetchingHiringMatches = action.payload;
    },
    toggleHiringMatchForCandidate(state, action) {
      const hitIndex = state.candidate.possibleHiringMatches.findIndex(
        match => match.company.slug === action.payload.company.slug
      );
      const currMatch = state.candidate.possibleHiringMatches[hitIndex];
      const newMatch = { ...currMatch };
      newMatch.company.selected = !currMatch.company.selected;
      state.candidate.possibleHiringMatches[hitIndex] = newMatch;
    },
    toggleHiringMatchPositionsForCandidate(state, action) {
      const hitIndex = state.candidate.possibleHiringMatches.findIndex(
        match => match.company.slug === action.payload.companySlug
      );
      const currMatch = state.candidate.possibleHiringMatches[hitIndex];
      const newMatch = { ...currMatch };

      const posHitIndex = newMatch.company.positions.findIndex(
        pos => pos.id === action.payload.positionId
      );
      const currPos = newMatch.company.positions[posHitIndex];
      currPos.selected = !currPos.selected;
      state.candidate.possibleHiringMatches[hitIndex] = newMatch;
    },
    updateCandidate(state, action) {
      state.candidate.uuid = action.payload.candidate;
      state.candidate.possibleHiringMatches = action.payload.possibleHiringMatches;
      state.candidate.isFetchingHiringMatches =
        action.payload.isFetchingHiringMatches;
    },
    setMatchReasonForCandidate(state, action) {
      const hitIndex = state.candidate.possibleHiringMatches.findIndex(
        match => match.company.slug === action.payload.companySlug
      );

      const currMatch = state.candidate.possibleHiringMatches[hitIndex];
      const newMatch = { ...currMatch };

      const posHitIndex = newMatch.company.positions.findIndex(
        pos => pos.id === action.payload.positionId
      );
      const currPos = newMatch.company.positions[posHitIndex];
      currPos.candidate_reason = action.payload.candidateReason;
      state.candidate.possibleHiringMatches[hitIndex] = newMatch;
    },
    setMatchReasonForCompany(state, action) {
      const hitIndex = state.candidate.possibleHiringMatches.findIndex(
        match => match.company.slug === action.payload.companySlug
      );
      const currMatch = state.candidate.possibleHiringMatches[hitIndex];
      const newMatch = { ...currMatch };

      const posHitIndex = newMatch.company.positions.findIndex(
        pos => pos.id === action.payload.positionId
      );
      const currPos = newMatch.company.positions[posHitIndex];
      currPos.company_reason = action.payload.companyReason;
      state.candidate.possibleHiringMatches[hitIndex] = newMatch;
    },
  },
});

export const {
  changeJobState,
  setSubmittedJobId,
  setSubmittedUserHash,
  setJob,
  setIsJobSaving,
  setIsJobStateChanging,
  setIsFetchingJobs,
  setJobs,
  setCompany,
  setCompanyDescription,
  setCompanyDescriptionStatus,
  setIsCompanySaving,
  setIsReadyToRedirect,
  clearIsReadyToRedirect,
  setIsCompanyDescriptionSaving,
  setTeamMembers,
  setStripePortalSessionUrl,
  toggleHiringMatchForCandidate,
  toggleHiringMatchPositionsForCandidate,
  setMatchReasonForCandidate,
  setMatchReasonForCompany,
} = hiring.actions;

export const fetchTeamMembers = (viewas = null) => async dispatch => {
  try {
    const { data } = await hiringAPI.fetchTeamMembers(viewas);
    return dispatch(setTeamMembers({ teamMembers: data }));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export const createStripePortalSession = (viewas = null) => async dispatch => {
  try {
    const { data } = await hiringAPI.createStripePortalSession(viewas);
    return dispatch(setStripePortalSessionUrl({ url: data }));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export const removeTeamMember = (id, viewas = null) => async (dispatch, getState) => {
  try {
    await hiringAPI.removeTeamMember(id, viewas);
    const teamMembers = getState().hiring.teamMembers.filter(
      teamMember => teamMember.id !== id
    );
    dispatch(setTeamMembers({ teamMembers }));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export const deleteJob = (job_id, viewas = null) => async dispatch => {
  try {
    dispatch(setIsJobStateChanging(true));
    await hiringAPI.deleteJob(job_id, viewas);
    dispatch(changeJobState({ jobId: job_id, jobState: "hidden" }));
    dispatch(setIsJobStateChanging(false));
  } catch (e) {
    dispatch(setIsJobStateChanging(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const showJob = (job_id, viewas = null) => async dispatch => {
  try {
    dispatch(setIsJobStateChanging(true));
    await hiringAPI.showJob(job_id, viewas);
    dispatch(changeJobState({ jobId: job_id, jobState: "visible" }));
    dispatch(setIsJobStateChanging(false));
  } catch (e) {
    dispatch(setIsJobStateChanging(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const syncJob = (job, viewas = null) => async dispatch => {
  try {
    dispatch(setIsJobSaving(true));
    await hiringAPI.syncJob(job, viewas);
    dispatch(setIsJobSaving(false));
  } catch (e) {
    dispatch(setIsJobSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const createJob = (job, viewas = null) => async dispatch => {
  try {
    dispatch(setIsJobSaving(true));
    const { data } = await hiringAPI.createJob(job, viewas);
    dispatch(setSubmittedJobId(data.id));
    dispatch(setIsJobSaving(false));
  } catch (e) {
    dispatch(setIsJobSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const createSelfServeJob = (job) => async dispatch => {
  try {
    dispatch(setIsJobSaving(true));
    const { data } = await hiringAPI.createSelfServeJob(job);
    dispatch(setSubmittedJobId(data.job_id));
    dispatch(setSubmittedUserHash(data.user_hash));
    dispatch(setIsJobSaving(false));
  } catch (e) {
    dispatch(setIsJobSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const saveJob = (job, viewas = null) => async dispatch => {
  try {
    dispatch(setIsJobSaving(true));
    const { data } = await hiringAPI.saveJob(job, viewas);
    dispatch(setJob(data));
    dispatch(setIsJobSaving(false));
  } catch (e) {
    dispatch(setIsJobSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const fetchJobs = (viewas = null) => async dispatch => {
  try {
    dispatch(setIsFetchingJobs(true));
    const { data } = await hiringAPI.fetchJobs(viewas);
    dispatch(setJobs(data));
    dispatch(setIsFetchingJobs(false));
  } catch (e) {
    dispatch(setIsFetchingJobs(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const fetchCompany = (viewas = null) => async dispatch => {
  try {
    const { data } = await hiringAPI.fetchCompany(viewas);
    dispatch(setCompany({ company: data }));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export const fetchCompanyDescription = (viewas = null) => async dispatch => {
  try {
    const { data } = await hiringAPI.fetchCompanyDescription(viewas);
    return dispatch(setCompanyDescription(data));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export const saveCompany = (company, viewas = null) => async dispatch => {
  try {
    dispatch(setIsCompanySaving(true));
    await hiringAPI.saveCompany(company, viewas);
    dispatch(setCompany({ company }));
    dispatch(setIsCompanySaving(false));
    dispatch(setIsReadyToRedirect());
  } catch (e) {
    dispatch(setIsCompanySaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const saveCompanyDescription = (companyDescription, viewas = null) => async dispatch => {
  try {
    dispatch(setIsCompanyDescriptionSaving(true));
    const { data } = await hiringAPI.saveCompanyDescription(companyDescription, viewas);
    dispatch(setCompanyDescription(data));
    dispatch(setIsCompanyDescriptionSaving(false));
    dispatch(setIsReadyToRedirect());
  } catch (e) {
    dispatch(setIsCompanyDescriptionSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const saveCompanyDescriptionStatus = (status, viewas = null) => async dispatch => {
  try {
    dispatch(setIsCompanyDescriptionSaving(true));
    const { data } = await hiringAPI.saveCompanyDescriptionStatus(status, viewas);
    dispatch(setCompanyDescriptionStatus(status));
    dispatch(setIsCompanyDescriptionSaving(false));
  } catch (e) {
    dispatch(setIsCompanyDescriptionSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const fetchCandidateMessages = (from, status, viewas = null) => async dispatch => {
  const {
    setCandidateMessages,
    setCandidateMessagesError,
    setIsFetchingCandidateMessages,
  } = hiring.actions;

  try {
    dispatch(setCandidateMessagesError(undefined));
    dispatch(setIsFetchingCandidateMessages(true));
    const { data } = await hiringAPI.fetchCandidateMessages(from, status, viewas);
    dispatch(setCandidateMessages(data));
    dispatch(setIsFetchingCandidateMessages(false));
  } catch (e) {
    const errorMessage = parseError(
      e,
      "There was an error loading candidate messages."
    );
    dispatch(setIsFetchingCandidateMessages(false));
    dispatch(setCandidateMessagesError(errorMessage));
  }
};

export const fetchSavedCandidates = (viewas = null) => async dispatch => {
  const {
    setSavedCandidates,
    setSavedCandidatesError,
    setIsFetchingSavedCandidates,
  } = hiring.actions;

  try {
    dispatch(setSavedCandidatesError(undefined));
    dispatch(setIsFetchingSavedCandidates(true));
    const {
      data: { candidates },
    } = await hiringAPI.fetchSavedCandidates(viewas);
    dispatch(setSavedCandidates(candidates));
    dispatch(setIsFetchingSavedCandidates(false));
  } catch (e) {
    const errorMessage = parseError(
      e,
      "There was an error loading saved candidates."
    );
    dispatch(setIsFetchingSavedCandidates(false));
    dispatch(setSavedCandidatesError(errorMessage));
  }
};

export const fetchStats = (viewas = null) => async dispatch => {
  const { setStats, setStatsError, setIsFetchingStats } = hiring.actions;

  dispatch(setIsFetchingStats(true));
  dispatch(setStatsError(undefined));

  try {
    const { data } = await hiringAPI.fetchStats(viewas);
    dispatch(setStats(data));
  } catch (e) {
    dispatch(
      setStatsError(
        parseError(e, "There was an error loading overview statistics.")
      )
    );
  }

  dispatch(setIsFetchingStats(false));
};

export const fetchHiringMatchesForCandidate = (candidate, viewas = null, isQuickRecommend = false) => async (
  dispatch,
  getState
) => {
  const {
    updateCandidate,
    setIsFetchingHiringMatchesForCandidate,
  } = hiring.actions;

  const candidateUUID = getState().hiring.candidateUUID;
  if (candidate.id === candidateUUID) {
    return;
  }
  try {
    dispatch(setIsFetchingHiringMatchesForCandidate(true));
    const { data } = await hiringAPI.fetchHiringMatchesForCandidate(candidate, viewas, isQuickRecommend);
    dispatch(
      updateCandidate({
        uuid: candidate.id,
        possibleHiringMatches: data,
        isFetchingHiringMatches: false,
      })
    );
  } catch (e) {
    dispatch(setIsFetchingHiringMatchesForCandidate(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export default combineSlices(hiring.reducer, initialState, {
  companyIntros,
  messageTemplates,
  candidates,
});
