import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { togglePendingReminderModal } from "@app/redux/modals";

const useCommunityActionButtons = () => {
  const dispatch = useDispatch();
  const isApproved = useSelector(state => !!(state.userData.user || {}).approved);

  const handleDiscoverCommunitiesClick = () => {
    if (isApproved) {
      window.location = "/c/top";
    } else {
      dispatch(togglePendingReminderModal());
    }
  };

  const communityActionButtons = [
    {
      glyph: "globe",
      index: "globe",
      caption: "Discover communities",
      onClick: handleDiscoverCommunitiesClick
    },
  ];

  return {
    communityActionButtons
  };
};

export default useCommunityActionButtons;
