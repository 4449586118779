import { createSlice } from "@reduxjs/toolkit";

import combineSlices from "@app/helpers/combineSlices";
import introRequestsAPI from "./api";
import { showSuccessAlert, showErrorAlertFromErr } from "../alert";

const initialState = {
  isSaving: true,
  alreadyRequested: false,
};

const introRequests = createSlice({
  name: "introRequests",
  initialState,
  reducers: {
    setAlreadyRequested(state, action) {
      state.alreadyRequested = action.payload;
    },
    setIsSaving(state, action) {
      state.isSaving = action.payload;
    },
  }
});

export const {
  setIsSaving
} = introRequests.actions;

export const lookupIntro = (companySlug) => async (dispatch, getState)  => {
  const { setIsSaving, setAlreadyRequested } = introRequests.actions;
  try {
    dispatch(setIsSaving(true));
    const { data } = await introRequestsAPI.lookupIntro(companySlug);
    dispatch(setIsSaving(false));
    dispatch(setAlreadyRequested(!!data.intro));
  } catch (e) {
    dispatch(setIsSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const requestIntro = (company, intro) => async (dispatch, getState)  => {
  const { setIsSaving, setAlreadyRequested } = introRequests.actions;
  try {
    dispatch(setIsSaving(true));
    const { data } = await introRequestsAPI.requestIntro(company, intro);
    dispatch(setIsSaving(false));
    dispatch(setAlreadyRequested(true));
    dispatch(showSuccessAlert(`Your request has been sent to ${company.name}!`));
  } catch (e) {
    dispatch(setIsSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};
export default introRequests.reducer;
