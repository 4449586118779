import React from "react";
import { useSelector } from "react-redux";
import { Dropdown, MenuItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Thumbnail from "../common/Thumbnail";
import { dompurify } from "@app/components/common/utils";

const AccountMenu = ({
  active,
  onTogglePendingReminderModal
}) => {
  const userThumbnailUrl = useSelector(state => state.userData.user && state.userData.user.thumbnail_url);

  return (
    <div className="hidden-xs btn-group" id="account_menu">
      <Dropdown pullRight className="nav-profile-dropdown" id="account_menu_dropdown">
        <Dropdown.Toggle noCaret className="nav-profile-more">
          <span className="nav-profile-container">
            <Thumbnail imgUrl={dompurify.sanitize(userThumbnailUrl)} />
          </span>
          <div className="nav-profile-more-dd">
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem
            eventKey="profile"
            key="profile"
            href="/account"
            className={active === "accounts-show" ? "active" : ""}
          >
            Your profile
          </MenuItem>
          <MenuItem
            className={`${active === "companies-index" || active === "companies-jobs" ? "active" : ""}`}
            eventKey="jobs"
            key="jobs"
            href="/jobs"
          >
            Explore jobs
          </MenuItem>
          <MenuItem
            className={active === "logged_out-top_companies_database" ? "active" : ""}
            eventKey="rate"
            key="rate"
            href="/top-companies-database"
          >
            Company Ratings Database
          </MenuItem>
          <MenuItem
            className={active === "logged_out-salary_database" ? "active" : ""}
            eventKey="salary"
            key="salary"
            href="/salary-database"
          >
            Salary Database
          </MenuItem>
          <MenuItem
            eventKey="email"
            key="email"
            href="/account/email_settings/edit"
            className={active === "email-settings-edit" ? "active" : ""}
          >
            Email subscription settings
          </MenuItem>
          <MenuItem
            eventKey="faq"
            href="/faq"
            key="faq"
            className={active === "faq" ? "active" : ""}
          >
            FAQ
          </MenuItem>
          <MenuItem divider />
          <MenuItem className="signout" href="/signout" eventKey="4">
            Sign out
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default AccountMenu;
