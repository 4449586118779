import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { faBook, faHeart, faHourglassHalf, faCertificate, faInbox, faBell, faSearch, faBriefcase, faUserPlus, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import AccountMenu from "./AccountMenu";
import MobileAccountMenu from "./MobileAccountMenu";
import CommunityTitle from "./CommunityTitle";
import { fetchAccountAlerts } from "@app/redux/account";
import { togglePendingReminderModal } from "@app/redux/modals";
import JoinTalentPoolLinkWrapper from "@app/components/common/JoinTalentPoolLinkWrapper";

const InternalNavbar = ({
  extraButtons,
  onMobileClick,
  active,
}) => {
  const dispatch = useDispatch();
  const notificationsCount = useSelector(state => state.account.notificationsCount);
  const messagesCount = useSelector(state => state.account.messagesCount);
  const isLoggedIn = useSelector(state => state.userData.user && state.userData.user.id);
  const isUserApproved = isLoggedIn && useSelector(state => state.userData.user.approved);
  const isUserSupporter = isLoggedIn && useSelector(state => state.userData.user.supporter);
  const isUserTalentTeamMember = isLoggedIn && useSelector(state => state.userData.user.talent_team_member);
  const isUserInReview = isLoggedIn && useSelector(state => (state.userData.user.awaiting_approval || state.userData.user.r || state.userData.user.i));
  const userSbToken = isLoggedIn && useSelector(state => state.userData.user.sb_token);
  const isUserWithTalentAccess = isLoggedIn && useSelector(state => (state.userData.user.talent_team_member || state.userData.user.partnership_admin));
  const isUserWithFullAccess = isLoggedIn && useSelector(state => state.userData.user.full_access);

  useEffect(() => {
    dispatch(fetchAccountAlerts());
    if (active !== "messages-index") {
      setInterval(() => {
        dispatch(fetchAccountAlerts());
      }, 300000);
    }
  }, [active, dispatch]);

  const renderAccountTypeBanner = () => {
    if (isUserApproved) {
      return null;
    }
    if (isUserSupporter && isUserTalentTeamMember) {
      return (
        <button onClick={() => dispatch(togglePendingReminderModal())} className="btn btn-warning text-and-icon">
          <FontAwesomeIcon icon={faBriefcase} />
          <span className="hidden-sm hidden-xs">Employer</span>

        </button>
      );
    }
    if (isUserInReview) {
      return (
        <button onClick={() => dispatch(togglePendingReminderModal())} className="btn btn-warning text-and-icon">
          <FontAwesomeIcon icon={faHourglassHalf} />
          <span className="hidden-sm hidden-xs">Application in review</span>

        </button>
      );
    }
    if (isUserSupporter) {
      return (
        <button onClick={() => dispatch(togglePendingReminderModal())} className="btn btn-warning text-and-icon">
          <FontAwesomeIcon icon={faHeart} className="pink" />
          <span className="hidden-sm hidden-xs">Supporter</span>

        </button>
      );
    }
    return null;
  };

  const renderMessageNotifications = () => {
    if (userSbToken) {
      const dot = messagesCount && active !== "messages-index";
      return (
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="messages_tooltip">Messages</Tooltip>}>
          <a
            className={`private-nav-top-link ${
              active === "messages-index" ? "active" : ""
            }`}
            href="/messages"
          >
            <FontAwesomeIcon icon={faInbox} />
            {dot ? <span className="notification-count">{messagesCount}</span> : null}
          </a>
        </OverlayTrigger>
      );
    }
    return null;
  };

  const renderNotifications = () => {
    const dot = notificationsCount && active !== "notifications";
    return (
      <OverlayTrigger placement="bottom" overlay={<Tooltip id="notification_tooltip">Notifications</Tooltip>}>
        <a
          className={`last-nav-top-link private-nav-top-link ${
            active === "notifications-index" ? "active" : ""
          }`}
          href="/notifications"
        >
          <FontAwesomeIcon icon={faBell} />
          {dot ? <span className="notification-count">{notificationsCount}</span> : null}
        </a>
      </OverlayTrigger>
    );
  };

  const renderMenu = () => {
    return (
      <>
        <MobileAccountMenu onClick={onMobileClick} />
        <AccountMenu active={active} />
      </>
    );
  };

  const renderJobsLink = () => {
    if (isUserWithTalentAccess) {
      return (
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="employer_tools_tooltip">Employer Tools</Tooltip>}>
          <a
            href="/hiring/home"
            className={`${active === "candidates-index" ? "active" : ""} private-nav-top-link`}
          >
            <FontAwesomeIcon icon={faBriefcase} />
          </a>
        </OverlayTrigger>
      );
    } else {
      return (
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="employer_tools_tooltip">Jobs</Tooltip>}>
          <a
            href="/jobs/mine"
            className={`${active === "candidates-index" ? "active" : ""} private-nav-top-link`}
          >
            <FontAwesomeIcon icon={faBriefcase} />
          </a>
        </OverlayTrigger>
      );
    }
  };

  return (
    <Navbar collapseOnSelect id="private_navbar">
      <Navbar.Header>
        <Navbar.Brand>
          <a href="/">elpha</a>
        </Navbar.Brand>
        {extraButtons}
        <CommunityTitle />

        <div className="nav-buttons nav-btn pull-right">
          { isLoggedIn && (
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="salary_db_tooltip">Elpha Salary Database</Tooltip>}>
              <a
                href="/salary-database"
                className={`${
                  active === "home_salary-database" ? "active" : ""
                } private-nav-top-link`}
              >

                <FontAwesomeIcon icon={faDollarSign} />
              </a>
            </OverlayTrigger>
          )}
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="resources_tooltip">Resources</Tooltip>}>
            <a
              className={`${
                active === "resources-index" || active === "resources-show" ? "active" : ""
              } private-nav-top-link`}
              href="/resources"
            >
              <FontAwesomeIcon icon={faBook} />
            </a>
          </OverlayTrigger>
          {renderJobsLink()}
          { isUserWithFullAccess && (
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="search_tooltip">Search</Tooltip>}>
              <a
                className={`search ${
                  active === "home-search" ? "active" : ""
                } private-nav-top-link`}
                href="/search"
              >
                <FontAwesomeIcon icon={faSearch} />
              </a>
            </OverlayTrigger>
          )}
          {renderMessageNotifications()}
          {renderNotifications()}
          <a
            className={`hidden-xs private-nav-top-link btn btn-primary ${active === "invitations-index" ? "active" : ""}`}
            href="/invite"
          >
            Refer a friend ✨
          </a>
          <a
            className={`emphasize hidden-sm hidden-md hidden-lg private-nav-top-link ${active === "invitations-index" ? "active" : ""}`}
            href="/invite"
          >
            <FontAwesomeIcon icon={faUserPlus} />
          </a>
          {renderAccountTypeBanner()}
          {renderMenu()}
        </div>
      </Navbar.Header>
    </Navbar>
  );
};

export default InternalNavbar;
