import React from "react";
import { Navbar } from "react-bootstrap";

import CompaniesTabs from "./CompaniesTabs";
import MobileAccountMenu from "./MobileAccountMenu";

const CompaniesNavbar = ({ active, onMobileClick }) => (
  <Navbar collapseOnSelect id="private_navbar">
    <Navbar.Header>
      <Navbar.Brand>
        <a href="/">elpha</a>
      </Navbar.Brand>

      {active !== "companies-partnerships" && <CompaniesTabs />}
      {active !== "companies-partnerships" && (
        <div className="nav-buttons nav-btn pull-right">
          <a
            href="/companies/partnerships"
            className="btn nav-invite-friends-btn hidden-xs"
          >
            For employers
          </a>
        </div>
      )}
      {active === "companies-partnerships" && (
        <div className="nav-buttons nav-btn pull-right">
          <a
            href="/jobs"
            className="btn nav-invite-friends-btn hidden-xs"
          >
            For job seekers
          </a>
        </div>
      )}
      <div className="visible-xs">
        <MobileAccountMenu
          onClick={onMobileClick}
          active={active}
        />
      </div>
    </Navbar.Header>
  </Navbar>
);

export default CompaniesNavbar;
