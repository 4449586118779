import React from "react";
import classNames from "classnames";
import Thumbnail from "@app/components/common/Thumbnail";
import ThumbnailPlaceholder from "@app/components/common/ThumbnailPlaceholder";
import { textToColor } from "@app/components/common/utils";
import styles from "./index.module.scss";

const ThumbnailWrapper = ({ user }) => (
  <>
    {user.canonical && <Thumbnail label={user.admin} imgUrl={user.thumbnail_url} clazz={styles.thumbnail} />}
    {!user.canonical && <ThumbnailPlaceholder clazz={classNames(styles.thumbnail, textToColor(user.username || "placeholder"))} />}
  </>
);
export default ThumbnailWrapper;
