import React from "react";
import { Glyphicon } from "react-bootstrap";
import styles from "./index.module.scss";

const Toggle = ({ show, onClick }) => (
  <span className={styles.root}>
    <Glyphicon glyph={show ? "chevron-down" : "chevron-right"} onClick={onClick} />
  </span>
);
export default Toggle;
