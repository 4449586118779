import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames";


const Button = ({ onClick, element, selected, overrideStyles }) => {
  let buttonStyle = overrideStyles ? overrideStyles : styles;
  return (
    <div onClick={() => onClick(element.value)}
      className={classNames(buttonStyle.buttonRoot,
        { [buttonStyle.active]: selected, })}>
      <span className={buttonStyle.buttonText}>{element.label}</span>
    </div>
  );
}
const ButtonGroup = ({ onClick, elements, selectedValues, overrideStyles, singleSelect = false }) => {
  const handleClick = (val) => {
    if (singleSelect) {
      onClick(val);
    } else {
      const newSelectedValues = selectedValues.slice(0);
      const index = newSelectedValues.indexOf(val);

      if (index === -1) {
        newSelectedValues.push(val);
      } else {
        newSelectedValues.splice(index, 1);
      }
      onClick(newSelectedValues);
    }
  };

  const selected = (value) => {
    if (singleSelect) return selectedValues[0] === value;
    else return selectedValues.indexOf(value) !== -1;
  }

  return (
    <div className={overrideStyles ? overrideStyles.root: styles.root}>
      {elements.map((ele, index) => (
        <Button
          selected={selected(ele.value)}
          element={ele}
          onClick={handleClick}
          index={index}
          overrideStyles={overrideStyles ? overrideStyles : styles}
        />
      ))}
    </div>
  );
};
export default ButtonGroup;
