import axios from "axios";

import { setCSRFTokenForRequest } from "@app/api/utils";

const registerAPI = {
  submitStep1: ({
    code,
    email,
    redirectUrl,
    additionalDataForSignup,
    isSupporter
  }) => {
    return setCSRFTokenForRequest(() => {
      return axios.post("/register/step1", {
        code,
        email,
        is_supporter: isSupporter,
        redirect_url: redirectUrl,
        ...additionalDataForSignup
      });
    });
  },
  submitStep2: ({
    firstName,
    lastName,
    applyUrl,
  }) => {
    return setCSRFTokenForRequest(() => {
      return axios.post("/register/step2", {
        user: {
          first_name: firstName,
          last_name: lastName,
          apply_url: applyUrl
        }
      });
    });
  },
};
export default registerAPI;
