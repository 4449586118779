import React from "react";
import { useLocation, useRouteMatch } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { setActiveModal } from "@app/redux/modals";

import Tabs from "../common/Tabs";

const CompaniesTabs = () => {
  const isCurrentUser = useSelector(state => state.userData.user && state.userData.user.id);
  const location = useLocation();
  const dispatch = useDispatch();

  const companiesMatch = useRouteMatch("/companies");
  const jobsMatch = useRouteMatch("/jobs");
  const jobsMineMatch = useRouteMatch("/jobs/mine");
  const jobsShowMatch = useRouteMatch("/companies/:slug/jobs/:id");

  let selectedTab;
  if ((jobsMatch || jobsShowMatch) && !jobsMineMatch) {
    selectedTab = "jobs";
  } else if (jobsMineMatch) {
    selectedTab = "jobs-mine";
  } else if (companiesMatch) {
    selectedTab = "companies";
  }

  const tabs = [
    {
      title: "companies",
      label: "companies",
      onClick: () => window.location = `/companies${location.search}`
    },
    {
      title: "jobs",
      label: "jobs",
      onClick: (url) => window.location = `/jobs${location.search}`
    },
    {
      title: "my search",
      label: "jobs-mine",
      onClick: (url) => {
        if (isCurrentUser) {
          window.location = `/jobs/mine${location.search}`
        } else {
          dispatch(setActiveModal({ active: "signup", data: { clickSource: "job-matches" }}));
        }
      }
    },
  ];

  return <Tabs tabs={tabs} selectedTab={selectedTab} />;
};
export default CompaniesTabs;
