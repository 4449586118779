import React, { useState } from "react";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import useParams from "@app/hooks/useParams";
import SimpleInputField from "@app/components/common/SimpleInputField";
import { dompurify, parseError } from "@app/components/common/utils";
import styles from "./index.module.scss";
import { ModalTitle, ModalBody, ModalCloseXOut } from "@app/components/common/Modal";

const ErrorMessage = ({ text }) => (
  <div className="messages">
    <div className="warning-text">
      <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(text) }} />
    </div>
  </div>
);

const SuccessMessage = ({ text }) => (
  <div className="messages">
    <div className="success-text">{text}</div>
  </div>
);

const MagicLinkModal = ({ reset, onSignInClick, onAfterClose }) => {
  const { getQueryParam, removeQueryParams } = useParams();
  const defaultEmail = getQueryParam("email");

  const [isEmailMissing, setIsEmailMissing] = useState(false);
  const [email, setEmail] = useState(defaultEmail || "");
  const [successMsg, setSuccessMsg] = useState("");
  const [failMsg, setFailMsg] = useState("");
  const [saving, setSaving] = useState(false);
  const [show, setShow] = useState(true);

  const handleSignInClick = () => {
    onSignInClick && onSignInClick();
  };

  const handleCloseModal = () => {
    setShow(false);
    setEmail("");
    setSaving(false);
    setIsEmailMissing(false);
    removeQueryParams(["modal"]);
    onAfterClose && onAfterClose();
  };

  const handleEmailChange = val => {
    setEmail(val);
    setIsEmailMissing(false);
    setSaving(false);
  };

  const handleMagicLinkClick = e => {
    e.preventDefault();
    setSaving(true);
    setFailMsg(null);
    setIsEmailMissing(false);
    if (!email) {
      setIsEmailMissing(true);
      setSaving(false);
      return false;
    }
    $.ajax({
      url: "/sign_in/magic_link_v2",
      method: "POST",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({ email, reset }),
      success: data => {
        setSuccessMsg("Check your email!");
        setFailMsg(null);
      },
      error: e => {
        const failMsg = parseError(e);
        setSaving(false);
        setFailMsg(parseError(e));
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      id="sign_in_step_one_modal"
      className="full-mobile-modal"
    >
      <Modal.Body className={styles.modalBody}>
        <div className="flex-row">
          <div className="left-col">
            <ModalCloseXOut onClick={handleCloseModal} />
            <ModalTitle text={reset ? "Reset password" : "Request magic link"} />
            <ModalBody text="We'll email you a link to log back in." />
          </div>
          <form onSubmit={e => handleMagicLinkClick(e)} className="right-col">
            {failMsg && <ErrorMessage text={failMsg} />}
            {successMsg && <SuccessMessage text={successMsg} />}
            <div
              className={`relative-div ${isEmailMissing ? "invalid-input-text" : ""}`}
            >
              <label style={{ display: "none" }} htmlFor="email">
                Email
              </label>
              <SimpleInputField
                focus
                autocomplete="email"
                id="email"
                placeholder="Email"
                value={email || ""}
                onChange={handleEmailChange}
              />
              {isEmailMissing ? (
                <div className="invalid-input-text-caption">Invalid Email</div>
              ) : null}
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={saving}
              onClick={handleMagicLinkClick}
            >
              {reset ? "Reset password" : "Get a magic link to sign in"}
            </button>
            <div onClick={handleSignInClick} className={styles.signInInsteadLink}>
              Sign in with your password instead
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default MagicLinkModal;
