import React, { useState, useEffect } from "react";
import $ from "jquery";
import InputField from "@app/components/common/InputField";
import LinkedInButton from "@app/components/Register/LinkedInButton";
import { track } from "@app/components/common/analytics";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "react-bootstrap";
import { clearActiveModal } from "@app/redux/modals";
import { logIn } from "@app/redux/userData";
import { setActiveModal } from "@app/redux/modals";
import { showErrorAlert, showErrorAlertFromErr } from "@app/redux/alert";


const AcceptInviteStep1 = () => {
  const dispatch = useDispatch();
  const isActive = useSelector(state => state.modals.active === "invite");

  const source = useSelector(state => state.userData.source);
  const ack = useSelector(state => state.userData.ack);
  const active = useSelector(state => state.userData.active);
  const isLoggedIn = useSelector(state => state.userData.user && state.userData.user.id);
  const community = useSelector(state => (state.userData.invitation || {}).community || state.userData.community);
  const invitation = useSelector(state => state.userData.invitation || {});
  const code = useSelector(state => state.userData.code);
  const queryParams = new URLSearchParams(location.search);
  const [submitting, setSubmitting] = useState(false);
  const prefilledEmail = invitation.email || queryParams.get("invite-email");
  const [email, setEmail] = useState(prefilledEmail);
  const [isEmailMissing, setIsEmailMissing] = useState(false);

  const handleEmailChange = val => setEmail(val);
  const handleSignInClick = e => {
    e.preventDefault();
    if (!email && !invitation.email) {
      setIsEmailMissing(true);
      return false;
    }
    if (isLoggedIn) {
      dispatch(showErrorAlert("You're already logged in!"));
      return false;
    }
    setSubmitting(true);

    const data = { code };
    if (!invitation.email) {
      data.email = email;
    }
    if (community && community.slug) {
      data.group = community.slug;
    }

    $.ajax({
      url: `/auth/invitation/${code}`,
      method: "POST",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify(data),
      success: ({ user }) => {
        track("User Created v5", {
          week: user.created_at_week,
          month: user.created_at_month,
          status: user.status,
          isMobileApp: false,
          code: code || source,
          source: user.source,
          signup_source: invitation.signup_source
        });
        window.lintrk && window.lintrk('track', { conversion_id: 5345425 });
        if (active === "top-companies-rate") {
          dispatch(logIn(user, "invitation"));
          dispatch(clearActiveModal());
        } else if (community) {
          window.location = `/onboarding/step1?community=${community.slug}`;
        } else {
          dispatch(setActiveModal({ active: "signup", data: { step: 2 }}))
        }
      },
      error: e => {
        setSubmitting(false);
        dispatch(showErrorAlertFromErr(e));
      }
    });
  };

  return (
    <form onSubmit={e => handleSignInClick(e)}>
      {prefilledEmail ? null : (
        <>
          <LinkedInButton
            type="join"
            text="Join with LinkedIn"
          />
          <br/><br/>
          <div
            className={`input-field-container ${
              isEmailMissing ? "invalid-input-text" : ""
            }`}
          >
            <InputField
              placeholder="Email address*"
              value={email || ""}
              onChange={handleEmailChange}
            />
            {isEmailMissing ? (
              <div className="invalid-input-text-caption">Invalid</div>
            ) : null}
          </div>
        </>
      )}
      <button
        disabled={submitting}
        className="btn btn-xl btn-primary btn-full"
      >
       {submitting ? "Creating your account..." : "Accept invite"}
      </button>
      {ack ? null : (
        <div className="public-help">
          By clicking Accept invite, you agree to our{" "}
          <a target="_blank" href="/privacy">
            Privacy Policy
          </a>
          ,{" "}
          <a href="/terms" target="_blank">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="/faq#guidelines" target="_blank">
            Guidelines
          </a>
          .
        </div>
      )}
      <br />
      <br />
    </form>
  );
}

export default AcceptInviteStep1;
