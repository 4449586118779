import {
  createSlice,
  Dispatch,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { DefaultRootState } from "react-redux";

import { showErrorAlertFromErr, showSuccessAlert } from "../alert";
import Application from "@app/types/Application";

import Candidate from "@app/types/Candidate";
import HiringMatchForCandidate from "@app/types/Hiring/HiringMatchForCandidate";
import candidatesAPI from "./api";
import applicationAPI from "../application/api";
import { createOrUpdateApplication } from "@app/redux/userData";
import combineSlices from "@app/helpers/combineSlices";
import form from "./form";
import replyMadLibs from "./replyMadLibs";

export interface State {
  candidate?: Candidate;
  /**
   * Will be `true` after the first time we fetch. Used to determine whether we
   * should assume that no candidate exists for this user, vs. if it just hasn't
   * been loaded yet.
   */
  hasFetched: boolean;
  hasFetchedMatches: boolean;
  hasFetchedSaved: boolean;
  hasFetchedApplied: boolean;
  hasFetchedInterviewing: boolean;
  isFetching: boolean;
  isUpdatingTabs: boolean,
  myMatches: HiringMatchForCandidate[];
  recommendedPositions: any[];
  savedPositions: Application[];
  appliedPositions: Application[];
  interviewingPositions: Application[];
}

const initialState: State = {
  candidate: undefined,
  hasFetched: false,
  hasFetchedMatches: false,
  hasFetchedSaved: false,
  hasFetchedApplied: false,
  hasFetchedInterviewing: false,
  isFetching: false,
  isUpdatingTabs: false,
  myMatches: [],
  recommendedPositions: [],
  savedPositions: [],
  appliedPositions: [],
  interviewingPositions: [],
};

const candidate = createSlice<State, SliceCaseReducers<State>>({
  name: "candidate",
  initialState,
  reducers: {
    setCandidate(state, action: PayloadAction<Candidate | undefined>) {
      state.candidate = action.payload;
    },
    setHasFetched(state, action: PayloadAction<boolean>) {
      state.hasFetched = action.payload;
    },
    setIsUpdatingTabs(state, action: PayloadAction<boolean>) {
      state.isUpdatingTabs = action.payload;
    },
    setIsFetching(state, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },
    setHasFetchedMatches(state, action: PayloadAction<boolean>) {
      state.hasFetchedMatches = action.payload;
    },
    setHasFetchedSaved(state, action: PayloadAction<boolean>) {
      state.hasFetchedSaved = action.payload;
    },
    setHasFetchedApplied(state, action: PayloadAction<boolean>) {
      state.hasFetchedApplied = action.payload;
    },
    setHasFetchedInterviewing(state, action: PayloadAction<boolean>) {
      state.hasFetchedInterviewing = action.payload;
    },
    setMyMatches(state, action: PayloadAction<Array<HiringMatchForCandidate>>) {
      state.myMatches = action.payload;
    },
    setRecommendedPositions(state, action: PayloadAction<Array<any>>) {
      state.recommendedPositions = action.payload;
    },
    setSavedPositions(state, action: PayloadAction<Array<Application>>) {
      state.savedPositions = action.payload;
    },
    setAppliedPositions(state, action: PayloadAction<Array<Application>>) {
      state.appliedPositions = action.payload;
    },
    setInterviewingPositions(state, action: PayloadAction<Array<Application>>) {
      state.interviewingPositions = action.payload;
    },
    addSavedPositions(state, action: PayloadAction<Array<Application>>) {
      state.savedPositions = state.savedPositions.concat(action.payload);
    },
    addAppliedPositions(state, action: PayloadAction<Array<Application>>) {
      state.appliedPositions = state.appliedPositions.concat(action.payload);
    },
    addInterviewingPositions(state, action: PayloadAction<Array<Application>>) {
      state.interviewingPositions = state.interviewingPositions.concat(action.payload);
    },
    updatePositionStatus(state, action: PayloadAction<Application>) {
      const position = state.interviewingPositions.find(a => a.id === action.payload.id);
      position.status = action.payload.status;
    },
    removeMyMatch(state, action) {
      const matchUuid = action.payload;
      const newMyMatches = state.myMatches.filter(m => m.uuid !== matchUuid);
      state.myMatches = newMyMatches;
    },
    removeRecommendedPosition(state, action) {
      const id = action.payload;
      const newRecommendedPositions = state.recommendedPositions.filter(p => p.id !== id);
      state.recommendedPositions = newRecommendedPositions;
    },
    removeSavedPosition(state, action: PayloadAction<Application>) {
      const newSaved = state.savedPositions.filter(a => a.id !== action.payload.id);
      state.savedPositions = newSaved;
    },
    removeAppliedPosition(state, action: PayloadAction<Application>) {
      const newApplied = state.appliedPositions.filter(a => a.id !== action.payload.id);
      state.appliedPositions = newApplied;
    },
    removeInterviewingPosition(state, action: PayloadAction<Application>) {
      const newInterviewing = state.interviewingPositions.filter(a => a.id !== action.payload.id);
      state.interviewingPositions = newInterviewing;
    }
  },
});

export const { setCandidate, setMyMatches } = candidate.actions;

export const fetchMyMatches = (viewas) => async (dispatch: Dispatch) => {
  const { setRecommendedPositions, setMyMatches, setHasFetchedMatches } = candidate.actions;
  dispatch(setHasFetchedMatches(false));

  try {
    let { data } = await candidatesAPI.fetchDashboardMatches(viewas);
    const { matches, positions } = data;
    dispatch(setMyMatches(matches));
    dispatch(setRecommendedPositions(positions));

    dispatch(setHasFetchedMatches(true));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
    dispatch(setHasFetchedMatches(true));
  }
};

export const fetchSavedPositions = (viewas) => async (dispatch: Dispatch) => {
  const { setSavedPositions, setHasFetchedSaved } = candidate.actions;
  dispatch(setHasFetchedSaved(false));

  try {
    let { data } = await candidatesAPI.fetchSavedPositions(viewas);
    dispatch(setSavedPositions(data));
    dispatch(setHasFetchedSaved(true));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
    dispatch(setHasFetchedSaved(true));
  }
};

export const fetchAppliedPositions = (viewas) => async (dispatch: Dispatch) => {
  const { setAppliedPositions, setHasFetchedApplied } = candidate.actions;
  dispatch(setHasFetchedApplied(false));

  try {
    let { data } = await candidatesAPI.fetchAppliedPositions(viewas);
    dispatch(setAppliedPositions(data));
    dispatch(setHasFetchedApplied(true));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
    dispatch(setHasFetchedApplied(true));
  }
};

export const fetchInterviewingPositions = (viewas) => async (dispatch: Dispatch) => {
  const { setInterviewingPositions, setHasFetchedInterviewing } = candidate.actions;
  dispatch(setHasFetchedInterviewing(false));

  try {
    let { data } = await candidatesAPI.fetchInterviewingPositions(viewas);
    dispatch(setInterviewingPositions(data));
    dispatch(setHasFetchedInterviewing(true));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
    dispatch(setHasFetchedInterviewing(true));
  }
};

export const createApplication = (match, position, status, skipAts = false) => async (dispatch: Dispatch) => {
  const {
    removeMyMatch,
    removeRecommendedPosition,
    addSavedPositions,
    addAppliedPositions,
    addInterviewingPositions,
    setIsUpdatingTabs,
  } = candidate.actions;

  try {
    dispatch(setIsUpdatingTabs(true));
    const { data } = await applicationAPI.createApplication(position, status, skipAts);
    dispatch(createOrUpdateApplication({ status: status, position_id: position.id }));
    if (match) dispatch(removeMyMatch(match.uuid));
    dispatch(removeRecommendedPosition(position.id));
    if (status === "saved") dispatch(addSavedPositions(data));
    if (status === "interviewing") dispatch(addInterviewingPositions(data));
    if (status === "applied") dispatch(addAppliedPositions(data));
    dispatch(setIsUpdatingTabs(false));
  } catch(e) {
    dispatch(showErrorAlertFromErr(e));
    dispatch(setIsUpdatingTabs(false));
  }
};

export const saveApplication = (application, existingApplication) => async (dispatch: Dispatch) => {
  const {
    addSavedPositions,
    removeAppliedPosition,
    removeInterviewingPosition,
    setIsUpdatingTabs
  } = candidate.actions;
  const position = application.position;
  try {
    dispatch(setIsUpdatingTabs(true));
    const { data } = existingApplication ?
      await applicationAPI.updateApplication(position, "saved") :
      await applicationAPI.createApplication(position, "saved");
    dispatch(createOrUpdateApplication({ status: "saved", position_id: position.id }));
    dispatch(addSavedPositions(data));
    dispatch(removeAppliedPosition(data));
    dispatch(removeInterviewingPosition(data));
    dispatch(setIsUpdatingTabs(false));
  } catch(e) {
    dispatch(setIsUpdatingTabs(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const unSaveApplication = application => async (dispatch: Dispatch) => {
  const {
    removeSavedPosition,
    removeAppliedPosition,
    removeInterviewingPosition,
    setIsUpdatingTabs
  } = candidate.actions;

  try {
    dispatch(setIsUpdatingTabs(true));
    const position = application.position;
    const { data } = await applicationAPI.updateApplication(position, "unsaved");
    dispatch(createOrUpdateApplication({ status: "unsaved", position_id: position.id }));
    dispatch(removeSavedPosition(data));
    dispatch(removeAppliedPosition(data));
    dispatch(removeInterviewingPosition(data));
    dispatch(setIsUpdatingTabs(false));
  } catch(e) {
    dispatch(setIsUpdatingTabs(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const applyApplication = (match, application, position, existingApplication, skipAts = false) => async (dispatch: Dispatch) => {
  const {
    removeMyMatch,
    removeRecommendedPosition,
    removeSavedPosition,
    addAppliedPositions,
    removeInterviewingPosition,
    setIsUpdatingTabs
  } = candidate.actions;

  try {
    dispatch(setIsUpdatingTabs(true));
    const { data } = existingApplication ?
      await applicationAPI.updateApplication(position, "applied", skipAts) :
      await applicationAPI.createApplication(position, "applied", skipAts)
    dispatch(createOrUpdateApplication({ status: "applied", position_id: position.id }));
    if (match) dispatch(removeMyMatch(match.uuid));
    if (position) dispatch(removeRecommendedPosition(position.id));
    if (existingApplication) dispatch(removeSavedPosition(data));
    dispatch(addAppliedPositions(data));
    dispatch(removeInterviewingPosition(data));
    dispatch(setIsUpdatingTabs(false));
  } catch(e) {
    dispatch(setIsUpdatingTabs(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const viewApplication = (match, application, position, existingApplication) => async (dispatch: Dispatch) => {
  const {
    removeMyMatch,
    removeRecommendedPosition,
    removeSavedPosition,
    addAppliedPositions,
    setIsUpdatingTabs,
  } = candidate.actions;

  try {
    dispatch(setIsUpdatingTabs(true));
    const { data } = existingApplication ?
      await applicationAPI.updateApplication(position, "viewed") :
      await applicationAPI.createApplication(position, "viewed");
    dispatch(createOrUpdateApplication({ status: "viewed", position_id: position.id }));
    if (match) dispatch(removeMyMatch(match.uuid));
    if (position) dispatch(removeRecommendedPosition(position.id));
    if (existingApplication) dispatch(removeSavedPosition(data));
    dispatch(addAppliedPositions(data));
    dispatch(setIsUpdatingTabs(false));
  } catch(e) {
    dispatch(setIsUpdatingTabs(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const interviewApplication = application => async (dispatch: Dispatch) => {
  const {
    removeAppliedPosition,
    addInterviewingPositions,
    removeSavedPosition,
    setIsUpdatingTabs,
  } = candidate.actions;

  try {
    dispatch(setIsUpdatingTabs(true));
    const position = application.position;
    const { data } = await applicationAPI.updateApplication(position, "interviewing");
    dispatch(createOrUpdateApplication({ status: "interviewing", position_id: position.id }));
    dispatch(removeAppliedPosition(data));
    dispatch(removeSavedPosition(data));
    dispatch(addInterviewingPositions(data));
    dispatch(setIsUpdatingTabs(false));
  } catch(e) {
    dispatch(setIsUpdatingTabs(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const revertApplicationStatus = (application, newStatus) => async (dispatch: Dispatch) => {
  const { updatePositionStatus } = candidate.actions;
  
  try {
    const position = application.position;
    const { data } = await applicationAPI.updateApplication(position, newStatus);
    dispatch(updatePositionStatus(data));
  } catch(e) {
    dispatch(showErrorAlertFromErr(e));
  }
}

export const offerApplication = application => async (dispatch: Dispatch) => {
  const {
    removeAppliedPosition,
    updatePositionStatus,
    addInterviewingPositions,
    setIsUpdatingTabs
  } = candidate.actions;
  
  try {
    dispatch(setIsUpdatingTabs(true));
    const position = application.position;
    const { data } = await applicationAPI.updateApplication(position, "offered");
    dispatch(createOrUpdateApplication({ status: "offered", position_id: position.id }));
    if (application.status === "interviewing") dispatch(updatePositionStatus(data));
    if (["applied", "viewed"].includes(application.status)) dispatch(addInterviewingPositions(data));
    dispatch(removeAppliedPosition(data));
    dispatch(showSuccessAlert("Yes!! Nice work! Check your inbox for resources from the Elpha team."));
    dispatch(setIsUpdatingTabs(false));
  } catch(e) {
    dispatch(setIsUpdatingTabs(false));
    dispatch(showErrorAlertFromErr(e));
  }  
}

export const acceptApplication = application => async (dispatch: Dispatch) => {
  const {
    removeInterviewingPosition,
    removeAppliedPosition,
    updatePositionStatus,
    setIsUpdatingTabs,
  } = candidate.actions;
  
  try {
    dispatch(setIsUpdatingTabs(true));
    const position = application.position;
    const { data } = await applicationAPI.updateApplication(position, "accepted");
    dispatch(createOrUpdateApplication({ status: "accepted", position_id: position.id }));
    dispatch(updatePositionStatus(data));
    dispatch(removeAppliedPosition(data));
    dispatch(showSuccessAlert("👏 🎉 👏 Congrats! 👏 🎉 👏 Your new team is very lucky to have you onboard."));
    dispatch(setIsUpdatingTabs(false));
  } catch(e) {
    dispatch(setIsUpdatingTabs(false));
    dispatch(showErrorAlertFromErr(e));
  }  
}

export const fetchCandidate = (viewas) => async (
  dispatch: Dispatch,
  getState: () => DefaultRootState
) => {
  if (getState().candidate.isFetching) return;

  const { setCandidate, setHasFetched, setIsFetching } = candidate.actions;

  dispatch(setIsFetching(true));

  try {
    let { data } = await candidatesAPI.fetchCandidate(viewas);
    const user = getState().userData.user;
    if (!data && user) {
      data = {};
      data.first_name = user.first_name;
      data.last_name = user.last_name;
      data.email = user.email;
      data.title = user.title;
      data.company = user.company;
      data.location = user.address;
      data.url = user.linkedin || user.apply_url;
      data.job_status = user.gigs;
      data.experience = user.experience;
      data.department = user.department;
      data.work_experiences = [];
      data.education_experiences = [];
    }
    dispatch(setCandidate(data));
    dispatch(setHasFetched(true));
    dispatch(setIsFetching(false));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
    dispatch(setIsFetching(false));
  }
};

export default combineSlices(candidate.reducer, initialState, {
  form,
  replyMadLibs,
});
