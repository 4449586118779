import React from "react";
import { useSelector } from "react-redux";
import JoinBoxStep1 from "./Step1";
import JoinBoxStep2 from "./Step2";

const JoinBox = () => {
  const step = useSelector(state => (state.modals.data || {}).step);

  if (step === 2) {
    return <JoinBoxStep2 />
  }
  return <JoinBoxStep1 />
};
export default JoinBox;
