import {
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  ContentState,
  EditorState,
  RawDraftContentState,
} from "draft-js";
import Editor from "@draft-js-plugins/editor";
import { FormGroup } from "react-bootstrap";
import React, { forwardRef, useImperativeHandle, useState } from "react";

import "draft-js/dist/Draft.css";
import "@draft-js-plugins/mention/lib/plugin.css";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";

import useLinkPlugin from "./useLinkPlugin";
import useMentionPlugin from "./useMentionPlugin";
import useMentions from "./useMentions";
import useImagePlugin from "./useImagePlugin";
import RichTextToolbar from "./RichTextToolbar";
import styles from "./index.module.scss";

const getInitialEditorState = (richText) => {
  if (!richText) return EditorState.createEmpty();
  return EditorState.createWithContent(convertFromRaw(richText));
};

const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

const getInitialEditorStateFromHtml = (htmlText) => {
  const blocksFromHTML = convertFromHTML(htmlDecode(htmlText));
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  return EditorState.createWithContent(state);
};

const BodyRichTextField = forwardRef(
  ({ disabled, value, placeholder, isHtml = false }, ref) => {
    const [editorState, setEditorState] = useState(
      isHtml ?
      getInitialEditorStateFromHtml(value) :
      getInitialEditorState(value)
    );

    useImperativeHandle(ref, () => ({
      getRichText: () => {
        const currentContent = editorState.getCurrentContent();
        if (!currentContent.hasText()) return;
        return convertToRaw(currentContent);
      },
    }));

    const { mentionPlugin, MentionSuggestions } = useMentionPlugin();
    const { imagePlugin } = useImagePlugin();
    const { linkPlugin, LinkButton } = useLinkPlugin();

    const [{ toolbarPlugin, Toolbar }] = useState(() => {
      const toolbarPlugin = createToolbarPlugin();
      const { Toolbar } = toolbarPlugin;
      return {
        toolbarPlugin,
        Toolbar,
      };
    });

    const { onOpenChange, onSearchChange, suggestions, MentionEntry, open } = useMentions();

    return (
      <FormGroup>
        <div className={styles.editorWrapper}>
          <Editor
            editorKey="BodyRichTextField"
            editorState={editorState}
            onChange={setEditorState}
            placeholder={placeholder}
            plugins={[toolbarPlugin, linkPlugin, mentionPlugin, imagePlugin]}
          />
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            suggestions={suggestions}
            onSearchChange={onSearchChange}
            entryComponent={MentionEntry}
          />

          <div className={styles.toolbarWrapper}>
            <RichTextToolbar
              Toolbar={Toolbar}
              LinkButton={LinkButton}
            />
          </div>
        </div>
      </FormGroup>
    );
  }
);

export default BodyRichTextField;
