import React from "react";
import classNames from "classnames";
import DesktopButtonGroup from "../../DesktopButtonGroup";
import FeaturedCompanies from "../../FeaturedCompanies";
import SuccessStoriesWidget from "../../SuccessStoriesWidget";
import SideBarSignUp from "../SideBarSignUp";
import styles from "./index.module.scss";

const DesktopSideBar = ({ data, clazz }) => {
  return (
    <div className={classNames(styles.root, clazz)}>
      <div className="side-bar-container">
        <div className="side-bar-body-container">
          <div className={styles.pageBlur}>
            <DesktopButtonGroup buttons={data} loading={false} />
          </div>
          <SideBarSignUp />
          <FeaturedCompanies divider header />
          <hr className={styles.bottom} />
          <SuccessStoriesWidget mobile={false} />
        </div>
      </div>
    </div>
  );
};
export default DesktopSideBar;
