import { memberCount, oneLiner, oneLinerContinued } from "@app/constants/text";

const baseInvite = `Elpha - a professional network ${oneLiner.toLowerCase()} - and thought you might find it useful. ${oneLinerContinued} Check it out!`;

const defaultThirdPersonText = (inviterName) => `${inviterName} is part of ${baseInvite}`;
const defaultFirstPersonText = `I am part of ${baseInvite}`;
const defaultFirstPersonSupporterText = `I've discovered ${baseInvite}`;

const talentEmailText = (companyName) => `Hi! ${companyName} is partnering with Elpha – a professional network of ${memberCount} women in engineering, product, design, sales, marketing + more. Join the ${companyName} team to recruit talent from Elpha. Thanks!`;


export const getFirstPersonInviteText = (isTalent, employerName, isSupporter) => {
  if (isTalent) {
    return talentEmailText(employerName);
  }
  if (isSupporter) {
    return defaultFirstPersonSupporterText;
  }
  return defaultFirstPersonText;
};

export const getThirdPersonInviteText = (employerName, inviterName) =>
  employerName ? talentEmailText(employerName) : defaultThirdPersonText(inviterName);

export const defaultEmailCount = 2;
