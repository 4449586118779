import React, { useEffect, useState, useContext } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { setActiveModal } from "@app/redux/modals";
import CustomCheckbox from "@app/components/common/CustomCheckbox"
import useCandidateModal from "@app/hooks/useCandidateModal";
import styles from "./index.module.scss";

const TodoChecklistWidget = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userData.user) || {};
  const { openCandidateModal } = useCandidateModal();

  const readGuide = user.onboarding_checklist.read_getting_started_guide;
  const saidHello = user.onboarding_checklist.said_hello;
  const wroteFirstPost = user.onboarding_checklist.wrote_first_post;
  const completedProfile = user.profile_completed;
  const isCandidate = user.is_candidate;
  const sharedElpha = user.onboarding_checklist.sent_invite;
  const isApproved = user.approved;

  const handleTalentPoolClick = () => (
    openCandidateModal({ source: "todo-list" })
  );

  const handleShareClick = () => (
    dispatch(setActiveModal({ active: "invite-friends" }))
  );

  if (readGuide &&
    saidHello &&
    wroteFirstPost &&
    completedProfile &&
    isCandidate &&
    sharedElpha
  ) return null;

  if (!isApproved) {
    return (
      <div className={styles.root}>
        <div className={styles.title}>🎉  Welcome! Don't forget to</div>
        <div className={styles.checklist}>
          <CustomCheckbox
            className={classNames({
              [styles.checkmarkLabelChecked]: readGuide
            })}
            label={<div className={styles.text}><a className={styles.link} href="/posts/x61y3d54/getting-started-with-elpha">Read</a>  our Getting Started Guide</div>}
            checked={readGuide}
          />
          <CustomCheckbox
            label={<div className={styles.text}><span className={styles.link} onClick={handleTalentPoolClick}>Join</span>  the Talent Pool</div>}
            className={classNames({
              [styles.checkmarkLabelChecked]: isCandidate
            })}
            checked={isCandidate}
          />
          <CustomCheckbox
            label={<div className={styles.text}><span className={styles.link} onClick={handleShareClick}>Refer</span>  your friends</div>}
            className={classNames({
              [styles.checkmarkLabelChecked]: sharedElpha
            })}
            checked={sharedElpha}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>WELCOME TO ELPHA! 🎉</div>
      <div className={styles.checklist}>
        <>
          <CustomCheckbox
            className={classNames({
              [styles.checkmarkLabelChecked]: readGuide
            })}
            label={<div className={styles.text}><a className={styles.link} href="/posts/x61y3d54/getting-started-with-elpha">Read</a>  our Getting Started Guide</div>}
            checked={readGuide}
          />
          <CustomCheckbox
            className={classNames({
              [styles.checkmarkLabelChecked]: saidHello
            })}
            label={<div className={styles.text}><a className={styles.link} href="/posts/welcome">Introduce</a> yourself</div>}
            checked={saidHello}
          />
        </>
        <CustomCheckbox
          label={<div className={styles.text}><a className={styles.link} href="/posts/new">Write </a> your first post</div>}
          className={classNames({
            [styles.checkmarkLabelChecked]: wroteFirstPost
          })}
          checked={wroteFirstPost}
        />
        <CustomCheckbox
          label={<div className={styles.text}><a className={styles.link} href="/account/edit">Update</a> your profile</div>}
          className={classNames({
            [styles.checkmarkLabelChecked]: completedProfile
          })}
          checked={completedProfile}
        />
        <CustomCheckbox
          label={<div className={styles.text}><span className={styles.link} onClick={handleTalentPoolClick}>Join</span>  the Talent Pool</div>}
          className={classNames({
            [styles.checkmarkLabelChecked]: isCandidate
          })}
          checked={isCandidate}
        />
        <CustomCheckbox
          label={<div className={styles.text}><span className={styles.link} onClick={handleShareClick}>Refer</span>  your friends</div>}
          className={classNames({
            [styles.checkmarkLabelChecked]: sharedElpha
          })}
          checked={sharedElpha}
        />
      </div>
    </div>
  );
};
export default TodoChecklistWidget;
