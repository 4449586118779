import { createSlice } from "@reduxjs/toolkit";

const sidebar = createSlice({
  name: "sidebar",
  initialState: {
    active: null,
  },
  reducers: {
    toggleMobileSidebar(state) {
      if (state.active === null) {
        state.active = "mobile";
        return state;
      }
      if (state.active === "mobile") {
        state.active = null;
        return state;
      }
      return state;
    },
  },
});

export const {
  toggleMobileSidebar,
} = sidebar.actions;

export default sidebar.reducer;
