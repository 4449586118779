import React from "react";
import { render } from "react-dom";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

import App from "@app/components/App";
import rootReducer from "@app/redux";

window.addEventListener("load", () => {
  const node = document.getElementById("data");
  const data = node && JSON.parse(node.getAttribute("data-react"));
  const container = document.getElementById("js_container");
  const div = document.createElement("div");

  if (container) {
    container.appendChild(div);
    const store = configureStore({
      reducer: rootReducer,
    });

    render(
      <Provider store={store}>
        <App />
      </Provider>,
      div
    );
  }
  null;
});
