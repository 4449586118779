import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Alert from "@app/redux/alert/Alert";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { track } from "@app/components/common/analytics";
import AcceptInviteStep1 from "./Step1";
import JoinBoxStep2 from "../JoinBox/Step2";
import { memberCount, oneLiner } from "@app/constants/text";
import { getThirdPersonInviteText } from "@app/components/pages/Invitations/utils";
import styles from "./index.module.scss";

const AcceptInviteModal = () => {
  const step = useSelector(state => (state.modals.data || {}).step);
  const isActive = useSelector(state => state.modals.active === "invite");
  const code = useSelector(state => state.userData.code)
  const inviterName = useSelector(state => state.userData.inviterName)
  const community = useSelector(state => state.userData.community || {})
  const invitation = useSelector(state => state.userData.invitation || {})
  const isLoggedIn = useSelector(state => state.userData.user && state.userData.user.id);

  useEffect(() => {
    if (isActive) {
      const data = {
        code,
        group: (community || {}).slug,
        inviterId: invitation.inviter_id
      };
      track("Referral Clicked", data);
    }
  }, [isActive]);

  const handleCloseModal = () => {
    if (community) {
      dispatch(clearActiveModal());
    }
  };

  const renderInviterTxt = () => (
    <div className="inviter-text">
      <div className={styles.title}>Hi 👋</div>
      <div className={styles.text}>
        {renderWelcome()}
        <br/>
      </div>
    </div>
  );

  const renderWelcome = () => {
    if (invitation.signup_source === "salary-database") {
      return (
        <p>You've been invited to join Elpha, the (free!) professional network {oneLiner.toLowerCase()}. At Elpha we're building a salary database with real salaries contributed by our {memberCount} members. Join Elpha to get access to the database.</p>
      );
    }

    return <p>{getThirdPersonInviteText(invitation.employer_name, invitation.inviter_name || inviterName || "Your friend")}</p>;
  };

  const content = step === 2 ? <JoinBoxStep2 /> : <AcceptInviteStep1 />;
  return (
    <Modal className={classNames(styles.root, "onboarding-steps")} show={isActive} onHide={handleCloseModal}>
      <Modal.Body className="invitation">
        {isLoggedIn && (
          <div className="alert alert-info">You're already logged in to Elpha. <a href="/top" title="See top posts on Elpha">See the top posts on Elpha 🔥</a></div>
        )}
        {community && (
          <button type="button" onClick={handleCloseModal} className="close" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        )}
        <Alert />
        {renderInviterTxt()}
        {content}
      </Modal.Body>
    </Modal>
  );
};
export default AcceptInviteModal;
