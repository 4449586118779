import axios from "axios";

import { setCSRFTokenForRequest } from "@app/api/utils";

const atsIntegrationAPI = {
  fetchCompany: () => {
    return setCSRFTokenForRequest(() => {
      return axios.get("/hiring/ats_integration/company.json");
    });
  },
};

export default atsIntegrationAPI;
