import React from "react";
import { useSelector } from "react-redux";
import styles from "./index.module.scss";
import DesktopButtonGroup from "../../../DesktopButtonGroup";
import MobileButtonGroup from "../../../MobileButtonGroup";

const TagsWidget = ({ isDesktop }) => {
  const activeCommunity = useSelector(state => (state.userData.community || {}));
  const activeTag = new URLSearchParams(window.location.search).get("tag");
  const tagButtons = activeCommunity.tags.map((tag, index) => {
    return {
      index,
      caption: tag,
      thumbnail: `https://d2i0px8cen0nqy.cloudfront.net/premium/tags_${index + 1}.png`,
      active: activeTag === tag,
      onClick: () => { window.location = `/c/${activeCommunity.slug}?tag=${tag}` }
    }
  });

  return (
    <div className={styles.root}>
      <div className={styles.header}>Tags</div>
      {isDesktop && (<DesktopButtonGroup
        buttons={tagButtons}
        isLoading={false}
      />)}
      {!isDesktop && (<MobileButtonGroup
        buttons={tagButtons}
        isLoading={false}
      />)}
    </div>
  );
};
export default TagsWidget;
