import React from "react";
import parse from "html-react-parser";
import { useSelector } from "react-redux";

const Alert = () => {
  const alert = useSelector(state => state.alert);
  if (alert.text) {
    return <div className={`main-alert center alert alert-${alert.type}`}>{parse(alert.text)}</div>;
  }
  return null;
};

export default Alert;
