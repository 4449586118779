import axios from "axios";

import AcceptOrReject from "@app/types/AcceptOrReject";
import { setCSRFTokenForRequest } from "@app/api/utils";

/** NOTE: DELETE/GET have { parameters } in side the config object, PUT and POST have params object separate **/

const prepareFiltersForRequest = filters => ({
  ...filters,
  ...(filters.connectionStates
    ? { connection_states: filters.connectionStates }
    : {}),
  connectionStates: undefined,
});

const hiringAPI = {
  syncJob: (job, viewas = null) => {
    const params: {
      viewas?: string,
    } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.put(`/hiring/jobs/${job.id}/sync`, params);
    });
  },
  fetchSelfServeCompany: (email) => {
    const params: { email: string } = { email };
    return setCSRFTokenForRequest(() => {
      return axios.get("/ss/jobs/company", { params });
    });
  },
  createSelfServeJob: (job) => {
    const params: {
      job: object
    } = { job };

    return setCSRFTokenForRequest(() => {
      return axios.post("/ss/jobs", params);
    });
  },
  cancelSelfServeJobSubscription: (jobId, userHash) => {
    const params: {
      u: string,
    } = { u: userHash };

    return setCSRFTokenForRequest(() => {
      return axios.delete(`/ss/jobs/${jobId}/cancel`, { params });
    });
  },
  removeSelfServeJob: (jobId, userHash) => {
    const params: {
      u: string,
    } = { u: userHash };

    return setCSRFTokenForRequest(() => {
      return axios.delete(`/ss/jobs/${jobId}`, { params });
    });
  },
  updateSelfServeJob: (job, userHash) => {
    const params: {
      job: object
    } = { job };

    return setCSRFTokenForRequest(() => {
      return axios.put(`/ss/jobs/${job.id}?u=${userHash}`, params);
    });
  },
  createJob: (job, viewas = null) => {
    const params: {
      viewas?: string,
      job: object
    } = { job };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.post("/hiring/jobs", params);
    });
  },
  saveJob: (job, viewas = null) => {
    const params: {
      viewas?: string,
      job: object
    } = { job };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.put(`/hiring/jobs/${job.id}`, params);
    });
  },
  showJob: (jobId, viewas = null) => {
    const params: {
      viewas?: string,
    } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.post(`/hiring/jobs/${jobId}`, { params });
    });
  },
  deleteJob: (jobId, viewas = null) => {
    const params: {
      viewas?: string,
    } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.delete(`/hiring/jobs/${jobId}`, { params });
    });
  },
  fetchJobs: (viewas = null) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.get("/hiring/jobs.json", { params });
    });
  },
  fetchCompanies: () => {
    return setCSRFTokenForRequest(() => {
      return axios.get("/admin/hiring/companies.json");
    });
  },
  skipCandidate: (candidateId) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/hiring/candidates/${candidateId}/skip`);
    });
  },
  unskipCandidate: (candidateId) => {
    return setCSRFTokenForRequest(() => {
      return axios.delete(`/hiring/candidates/${candidateId}/skip`);
    });
  },
  saveCandidate: candidateId => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/hiring/candidates/${candidateId}/save`);
    });
  },
  unsaveCandidate: candidateId => {
    return setCSRFTokenForRequest(() => {
      return axios.delete(`/hiring/candidates/${candidateId}/save`);
    });
  },
  messageCandidate: (candidateUuid, body, company, source, hiringMatchUuid) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/hiring/candidates/${candidateUuid}/message`, {
        candidate_message: {
          body,
          company,
        },
        tracking_props: {
          Source: source,
          HiringMatchUUID: hiringMatchUuid,
        },
      });
    });
  },
  fetchMatchingCandidates: (viewas = null) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.post("/hiring/candidates/matches", params);
    });
  },
  fetchFilteredCandidates: (filters, offset, viewas = null) => {
    const params: {
      viewas?: string,
      filters: { [key: string]: any },
      offset: number
    } = { filters, offset };

    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.post("/hiring/candidates/filter", params);
    });
  },
  createStripePortalSession: (viewas = null) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.post("/hiring/billing.json", params);
    });
  },
  fetchTeamMembers: (viewas = null) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.get("/hiring/team.json", { params });
    });
  },
  removeTeamMember: (id, viewas = null) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.delete(`/hiring/team/${id}`, { params });
    });
  },
  updateTeamMemberAccountType: (id, newType, viewas = null) => {
    const params: {
      viewas?: string,
      employee_type: string
    } = { employee_type: newType };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.put(`/hiring/team/${id}`, params);
    });
  },
  updateCompanyDescription: (companyDescription, viewas = null) => {
    const params: {
      viewas?: string,
      company_description: object
    } = { company_description: companyDescription };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.put("/hiring/company/description", params);
    });
  },
  fetchCompanyDescription: (viewas = null) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.get("/hiring/company/description.json", { params });
    });
  },
  fetchCompany: (viewas = null) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.get("/hiring/company.json", { params });
    });
  },
  saveCompany: (company, viewas = null) => {
    const params: { viewas?: string, company: object } = { company };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.put("/hiring/company", params);
    });
  },
  saveCompanyDescription: (companyDescription, viewas = null) => {
    const params: {
      viewas?: string,
      company_description: object
    } = { company_description: companyDescription };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.put("/hiring/company/description", params);
    });
  },
  saveCompanyDescriptionStatus: (status, viewas = null) => {
    const params: {
      viewas?: string,
      company_description: object
    } = { company_description: { status } };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.put("/hiring/company/description/status", params);
    });
  },
  fetchHiringMatchesForCandidate: (candidate, viewas = null, isQuickRecommend = false) => {
    const params: {
      viewas?: string,
      is_quick_recommend?: boolean
    } = {};
    if (viewas) params.viewas = viewas;
    if (isQuickRecommend) params.is_quick_recommend = true;

    return setCSRFTokenForRequest(() => {
      return axios.get(`/hiring/matches/candidates/${candidate.uuid}.json`, { params });
    });
  },
  fetchHiringMatchesForCompany: (viewas = null) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.get("/hiring/hiring_matches/matches_for_company.json", { params });
    });
  },
  saveHiringMatchRecs: (candidate, hiringRecs, viewas = undefined) => {
    const params: {
      viewas?: string,
      hiring_recs: Array<object>
    } = { hiring_recs: hiringRecs };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.put(`/hiring/matches/candidates/${candidate.uuid}`, params);
    });
  },
  replyToHiringMatchByUuid: (
    acceptOrReject: AcceptOrReject,
    /** The UUID of the hiring intro to reply to */
    uuid: string
  ) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(
        `/hiring/hiring_matches/${uuid}/company/${acceptOrReject}.json`
      );
    });
  },

  /**
   * @param {object} [filters] - Additional params to pass in the request, such
   * as `connectionStates`, `scope`, etc.
   * @param {number} [page] - Which page number to fetch. (default: 1)
   * @param {number} [perPage] - How many items per page to fetch. (default:
   * 100)
   */
  fetchIntros: (
    filters: { [key: string]: any } = {},
    page = 1,
    perPage = 100,
    viewas = null
  ) => {
    const params: {
      viewas?: string,
      filters: { [key: string]: any },
      page: number,
      per_page: number
    } = {
      ...prepareFiltersForRequest(filters),
      page,
      per_page: perPage,
    };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.get("/hiring/intros.json", { params })
    });
  },

  requestIntroWithoutMatch: (candidateUuid: string) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/hiring/candidates/${candidateUuid}/intro_requested_without_match`);
    });
  },

  replyToIntro: (
    /** The database ID of the intro. */
    id: string,
    /** Whether to "accept" or "reject" the intro. */
    acceptOrReject: AcceptOrReject,
    /** Which built-in rejection reason to use. */
    rejectionReason?: string,
    /** Which custom rejection reason to use. */
    rejectionReasonOther?: string,
    /**
     * Additional params to pass in the request, such as `connectionStates`,
     * `scope`, etc. If passing connection states, this value should be the same
     * filter as you used to generate the list in the UI that a user
     * accepted/rejected this intro for, because this action will update that
     * list from the response from the server.
     */
    filters: { [key: string]: any } = {},
    /**
     * Which page number to fetch in the returned intros.  This should be the
     * same as was used to generate the list in the UI that a user
     * accepted/rejected this intro for, because this action will update that
     * list from the response from the server.
     */
    page?: number,
    /**
     * How many items per page to fetch. This should be the same as was used to
     * generate the list in the UI that a user accepted/rejected this intro for,
     * because this action will update that list from the response from the
     * server.
     */
    perPage?: number
  ) => {
    return setCSRFTokenForRequest(() => {
      return axios.put(`/hiring/intros/${id}/reply/${acceptOrReject}.json`, {
        rejection_reason: rejectionReason,
        rejection_reason_other: rejectionReasonOther,
        ...prepareFiltersForRequest(filters),
        page,
        per_page: perPage,
      });
    });
  },

  replyToIntroByHash: ({
    acceptOrReject,
    rejectionReason,
    rejectionReasonOther,
    updateHash,
    userHash,
  }: {
    acceptOrReject: AcceptOrReject;
    /** The update hash of the company intro to check */
    updateHash: string;
    /** The user's email unsub hash (to identify them) */
    userHash: string;
    rejectionReason?: string;
    rejectionReasonOther?: string;
  }) => {
    return setCSRFTokenForRequest(() => {
      return axios.get(`/hiring/intros/${updateHash}/${acceptOrReject}.json`, {
        params: {
          hash: userHash,
          rejection_reason: rejectionReason,
          rejection_reason_other: rejectionReasonOther,
        },
      });
    });
  },

  fetchCandidateMessages: (from?: "all" | "me", status?: string, viewas: string = null) => {
    const params: {
      viewas?: string,
      from?: string,
      status?: string
    } = { from, status };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.get("/hiring/candidate_messages.json", { params });
    });
  },

  fetchSavedCandidates: (viewas = null) => {
    const params: {
      viewas?: string,
      candidate: { tab: string },
      per_page: number
    } = {
      candidate: {
        tab: "saved",
      },
      per_page: 10,
    };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.post("/hiring/candidates/filter", params);
    });
  },

  fetchStats: (viewas = null) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.get("/hiring/stats.json", { params });
    });
  },

  fetchMessageTemplates: (viewas = null) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.get("/hiring/message_templates.json", { params });
    });
  },

  /**
   * Updates an existing message template.
   *
   * @param {number} id - The ID of the message template to update.
   * @param {MessageTemplate} messageTemplate - The message template to save.
   */
  updateMessageTemplate: (id, messageTemplate, viewas = null) => {
    const params: {
      viewas?: string,
      message_template: object
    } = { message_template: messageTemplate };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.put(`/hiring/message_templates/${id}.json`, params)
    });
  },

  /**
   * Creates a new message template.
   *
   * @param {MessageTemplate} messageTemplate - The message template to create.
   */
  createMessageTemplate: (messageTemplate, viewas = null) => {
    const params: {
      viewas?: string,
      message_template: object
    } = { message_template: messageTemplate };
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.post("/hiring/message_templates.json", params);
    });
  },

  /**
   * @param {number} id - The ID of the message template to delete.
   */
  deleteMessageTemplate: (id, viewas = null) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.delete(`/hiring/message_templates/${id}.json`, { params });
    });
  },

  /**
   * Set a message template as the default.
   *
   * @param {number} id - The ID of the message template to set as the default.
   */
  makeMessageTemplateDefault: (id, viewas = null) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;

    return setCSRFTokenForRequest(() => {
      return axios.put(`/hiring/message_templates/${id}/make_default.json`, params);
    });
  },
};

export default hiringAPI;
