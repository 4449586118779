import React from "react";
import { ControlLabel, FormGroup, HelpBlock } from "react-bootstrap";

import styles from "./index.module.scss";

/**
 * @param {object} props
 * @param {string} props.label - The field label.
 * @param {string} [props.error] - An error message about the field, if one
 * exists.
 * @param {boolean} [props.required] - Whether this field is required. Will show
 * an asterisk if set to `true`. Default: `false`.
 */
const FieldWrapper = ({ name, label, error, required = false, children }) => {
  return (
    <FormGroup name={name} validationState={error ? "error" : null}>
      {label && (<ControlLabel className={required ? styles.required : ""}>
          {label}
        </ControlLabel>
      )}
      {children}
      {error && <HelpBlock>{error}</HelpBlock>}
    </FormGroup>
  );
};

export default FieldWrapper;
