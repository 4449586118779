import { track, alias, identify } from "@app/components/common/analytics";
import { createSlice } from "@reduxjs/toolkit";
import API from "../api";

const userData = createSlice({
  name: "userData",
  initialState: {
    user: {},
    loaded: false,
    loggedIn: false,
  },
  reducers: {
    incPoints(state, action) {
      state.user.points += action.payload;
      return state;
    },
    setLoggedIn(state) {
      state.loggedIn = true;
      return state;
    },
    updateOnboardingChecklist(state, action) {
      const newUser = { ...state.user };
      newUser.onboarding_checklist[action.payload.key] = true
      state.user = newUser;
      return state;
    },
    setIsCandidate(state, action) {
      state.user = { ...state.user };
      state.user.is_candidate = true;
      return state;
    },
    setCompanyRatingSubmitted(state, isSubmitted) {
      state.user = { ...state.user };
      state.user.company_rating_submitted = isSubmitted;
      return state;
    },
    setSalarySubmitted(state, isSubmitted) {
      state.user = { ...state.user };
      state.user.salary_submitted = isSubmitted;
      return state;
    },
    updateUser(state, action) {
      state.user = action.payload || {};
      return state;
    },
    userDataLoaded(state, action) {
      state = action.payload;
      state.loaded = true;
      return state;
    },
    createOrUpdateApplication(state, action) {
      state.applications = state.applications || [];
      const application = state.applications.find(
        app => app.position_id === action.payload.position_id
      );
      if (application) {
        Object.assign(application, action.payload);
      } else {
        state.applications.push(action.payload);
      }
    },
  },
});

export const {
  incPoints,
  setLoggedIn,
  setIsCandidate,
  setSalarySubmitted,
  setCompanyRatingSubmitted,
  userDataLoaded,
  updateUser,
  updateOnboardingChecklist,
  createOrUpdateApplication,
} = userData.actions;

export const fetchUserData = () => async dispatch => {
  try {
    const data = await API.fetchUserData();
    return dispatch(userDataLoaded(data));
  } catch (e) {
    // TODO
  }
};

/**
 * Takes care of everything that needs to be done to log a user in.
 */
export const logIn = (user, type) => async dispatch => {
  alias(user);
  identify(user);
  track("User Logged In", { type });
  dispatch(updateUser(user));
  dispatch(setLoggedIn());
};

export default userData.reducer;
