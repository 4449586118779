import React from "react";

const MakePublicBanner = () => (
  <div role="alert" className="public-post-banner alert alert-warning">
    You've selected 'make it public', so this post will be shared publicly for <i>anyone</i>{" "}
    to see, including non-members. Learn more{" "}
    <a href="/faq#public">here</a>.
  </div>
);

export default MakePublicBanner;
