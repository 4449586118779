import axios from "axios";

import { setCSRFTokenForRequest } from "@app/api/utils";

const eventsAPI = {
  /*
   * @param {string} eventSlug - The event slug.
   * @param {string} asUsername - The username that the user wants to connect
   * as. Can be their regular username or one of their anon usernames.
   */
  rsvp: (eventSlug, asUsername) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/events/${eventSlug}/rsvp`, {
        as_username: asUsername,
      });
    });
  },

  /**
   * @param {string} channel - The Agora channel to connect to.
   * @param {string} eventSlug - The event slug.
   * @param {string} asUsername - The username that the user wants to connect
   * as. Can be their regular username or one of their anon usernames.
   */
  connect: (channel, eventSlug, asUsername) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/events/${eventSlug}/connect`, {
        channel,
        as_username: asUsername,
      });
    });
  },

  /**
   * @param {number} userID - The ID of the user to look up.
   * @param {string} eventSlug - The event slug.
   */
  lookUpGuest: (userID, eventSlug) => {
    return setCSRFTokenForRequest(() => {
      return axios.get(`/events/${eventSlug}/guests/${userID}`);
    });
  },

  /**
   * @param {number[]} userIDs - The IDs of the users to invite to this event.
   * @param {string} eventSlug - The event slug.
   */
  inviteUsers: (userIDs, eventSlug) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/events/${eventSlug}/invite_users`, {
        user_ids: userIDs,
      });
    });
  },

  /**
   * @param {string} slug - The slug of the event to start.
   */
  startEvent: slug => {
    return setCSRFTokenForRequest(() => {
      return axios.put(`/events/${slug}/start_event`);
    });
  },

  /**
   * @param {string} slug - The slug of the event to end.
   */
  endEvent: slug => {
    return setCSRFTokenForRequest(() => {
      return axios.put(`/events/${slug}/end_event`);
    });
  },

  updateEventRsvpRole: (slug, userID, role) => {
    return setCSRFTokenForRequest(() => {
      return axios.put(`/events/${slug}/update_role`, {
        user_id: userID,
        role,
      });
    });
  },
};

export default eventsAPI;
