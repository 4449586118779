import axios from "axios";

import { setCSRFTokenForRequest } from "@app/api/utils";

const accountAPI = {
  createAnonId: () => {
    return setCSRFTokenForRequest(() => (
      axios.post("/account/anon_ids")
    ))
  },
  getAnonIds: () => {
    return setCSRFTokenForRequest(() => (
      axios.get("/account/anon_ids")
    ))
  },
  /**
   * @param {object} salaryInfo - The salaryInfo object to save.
   */
  submitSalary: (salaryInfo) => {
    return setCSRFTokenForRequest(() => (
      axios.post("/account/salary", {
        salary_info: salaryInfo,
      })
    ));
  },
  submitCompanyRating: (companyRating) => {
    return setCSRFTokenForRequest(() => (
      axios.put("/account/rating", {
        company_rating: companyRating
      })
    ))
  },
  fetchAccountAlerts: () => {
    return setCSRFTokenForRequest(() => {
      return axios.get("/account/alerts").then(({ data }) => {
        return {
          notificationsCount: data.unread_notifications,
          messagesCount: data.unread_msgs,
        };
      });
    })
  },
  fetchCommunityMemberships: (isLoggedIn) => {
    return setCSRFTokenForRequest(() => {
      const url = isLoggedIn ? "/account/communities" : "/logged_out/communities";
      return axios.get(url)
    })
  },
}
export default accountAPI;
