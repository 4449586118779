import { createSlice } from "@reduxjs/toolkit";

import commentAPI from "./api";
import { showErrorAlert, showErrorAlertFromErr } from "../alert";
import { decTotalComments, incTotalComments } from "../post";

const initialState = {
  isFetchingComments: false,
  isSaving: false,
  comments: {},
  sortedRootIds: [],
  commentsByParentIds: {},
  draftCommentId: undefined,

  // handling writing a root comment
  isRootReplyToOpen: false,
};

const comment = createSlice({
  name: "comment",
  initialState,
  reducers: {
    setIsFetchingComments(state, action) {
      state.isFetchingComments = action.payload;
    },
    setShowCommentTree(state, action) {
      state.comments[action.payload].showCommentTree = true;
      return state;
    },
    setIsSaving(state, action) {
      state.isSaving = action.payload;
      return state;
    },
    setIsSavingComment(state, action) {
      if (state.comments[action.payload.id] && state.comments[action.payload.id].draft) {
        state.comments[action.payload.id].draft.isSaving = action.payload.isSaving;
      }
      return state;
    },
    updateComment(state, action) {
      const comment = state.comments[action.payload];
      comment.text = comment.draft.text;
      comment.picture = comment.draft.picture;
      comment.draft = undefined;
      state.draftCommentId = undefined;
      return state;
    },
    setComments(state, action) {
      state.comments = action.payload;
    },
    setCommentsByParentIds(state, action) {
      state.commentsByParentIds = action.payload;
    },
    setSortedRootIds(state, action) {
      state.sortedRootIds = action.payload;
    },
    deleteComment(state, action) {
      const {
        commentId,
        commentsRemovedCount
      } = action.payload;

      const comment = state.comments[commentId];

      // update comment count for all the comment's parents
      const parentCommentId = comment.parent_comment_id;
      let currParentCommentId = parentCommentId;
      while (currParentCommentId) {
        let currParentComment = state.comments[currParentCommentId];
        state.comments[currParentCommentId].count = currParentComment.count - commentsRemovedCount;
        currParentCommentId = currParentComment.parent_comment_id;
      }

      // if it's a parent comment, hide its children
      delete state.commentsByParentIds[commentId];

      const childrenOfParentComment = state.commentsByParentIds[parentCommentId];
      state.commentsByParentIds[parentCommentId] = (state.commentsByParentIds[parentCommentId] || []).filter((id) => id != commentId);

      state.sortedRootIds = state.sortedRootIds.filter((id) => commentId != id);

      // delete the comment
      delete state.comments[commentId];
      return state;
    },
    createComment(state, action) {
      const createdComment = action.payload;
      state.comments[createdComment.id] = createdComment;

      if (createdComment.parent_comment_id) {
        // has a parent, so add as that parents child
        const siblings = state.commentsByParentIds[createdComment.parent_comment_id] || [];
        siblings.unshift(createdComment.id);
        state.commentsByParentIds[createdComment.parent_comment_id] = siblings;

        let currParentCommentId = createdComment.parent_comment_id;
        while (currParentCommentId) {
          let currParentComment = state.comments[currParentCommentId];
          state.comments[currParentCommentId].count = (state.comments[currParentCommentId].count || 0) + 1;
          currParentCommentId = currParentComment.parent_comment_id;
        }
      } else {
        // a root comment, so add to top of sorted root ids
        state.sortedRootIds.unshift(action.payload.id);
      }
      return state;
    },
    updateReactionsForComment(state, action) {
      state.comments[action.payload.commentId].rxn_summary = action.payload.rxnSummary;
      return state;
    },
    clearReplyToOrEditMode(state, action) {
      if (state.draftCommentId) state.comments[state.draftCommentId].draft = undefined;
      state.draftCommentId = undefined;
      state.isRootReplyToOpen = false;
      return state;
    },
    setReplyToMode(state, action) {
      if (state.draftCommentId) state.comments[state.draftCommentId].draft = undefined;
      state.isRootReplyToOpen = false;
      state.draftCommentId = action.payload.id;
      state.comments[action.payload.id].draft = {
        isAnon: action.payload.isAnon,
        anonAuthor: action.payload.anonAuthor,
        changeType: "reply",
      };
      return state;
    },
    setEditMode(state, action) {
      state.isRootReplyToOpen = false;
      state.draftCommentId = action.payload;
      state.comments[action.payload].draft = {
        slug: state.comments[action.payload].path,
        text: state.comments[action.payload].text,
        picture: state.comments[action.payload].picture,
        isAnon: state.comments[action.payload].anon,
        anonAuthor: (state.comments[action.payload].author || {}).username,
        postPath: state.comments[action.payload].post_path,
        postSlug: state.comments[action.payload].post_slug,
        boost: state.comments[action.payload].boost,
        changeType: "edit"
      };

      return state;
    },
    setFirstCommentOpen(state, action) {
      state.isRootReplyToOpen = true;
      state.comments[null] = {
        id: null,
        post_path: action.payload.postPath,
        post_slug: action.payload.postSlug,
        draft: {
          postPath: action.payload.postPath,
          postSlug: action.payload.postSlug,
          isAnon: action.payload.isAnon,
          anonAuthor: action.payload.anonAuthor,
          changeType: "reply"
        }
      };
      return state;
    },
    updateDraftCommentText(state, action) {
      state.comments[action.payload.id].draft.text = action.payload.text;
      return state;
    },
    updateDraftCommentBoost(state, action) {
      state.comments[action.payload.id].draft.boost = action.payload.boost;
      return state;
    },
    updateDraftCommentPicture(state, action) {
      state.comments[action.payload.id].draft.picture = action.payload.picture;
      return state;
    },
    updateDraftCommentAnonAuthor(state, action) {
      state.comments[action.payload.id].draft.anonAuthor = action.payload.anonAuthor;
      state.comments[action.payload.id].draft.isAnon = !!action.payload.anonAuthor;
      return state;
    },
  },
});

export const {
  setFirstCommentOpen,
  syncCommentWithDraft,
  setComments,
  setShowCommentTree,
  setEditMode,
  setReplyToMode,
  clearReplyToOrEditMode,
  updateDraftCommentText,
  updateDraftCommentBoost,
  updateDraftCommentPicture,
  updateDraftCommentAnonAuthor,
} = comment.actions;


export const fetchBotCommentDraft = (postPath, postSlug, commentPath, commentId) => async (dispatch, getState) => {
  const { setIsSaving } = comment.actions;
  try {
    dispatch(setIsSaving(true));
    const { data } = await commentAPI.fetchBotCommentDraft(postPath, postSlug, commentPath);
    dispatch(setReplyToMode({
      id: commentId,
      isAnon: false,
      anonAuthor: null
    }));
    dispatch(updateDraftCommentText({ id: commentId, text: data.text }));
    dispatch(setIsSaving(false));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
    dispatch(setIsSaving(false));
  }
};

export const unpinComment = (postPath, postSlug, commentPath) => async (dispatch, getState) => {
  const { setIsSaving } = comment.actions;
  try {
    dispatch(setIsSaving(true));
    await commentAPI.unpinComment(postPath, postSlug, commentPath);
    dispatch(setIsSaving(false));
  } catch (e) {
    dispatch(setIsSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const pinComment = (postPath, postSlug, commentPath) => async (dispatch, getState) => {
  const { setIsSaving } = comment.actions;
  try {
    dispatch(setIsSaving(true));
    await commentAPI.pinComment(postPath, postSlug, commentPath);
    dispatch(setIsSaving(false));
  } catch (e) {
    dispatch(setIsSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const deleteComment = (postPath, postSlug, commentId) => async (dispatch, getState) => {
  const { setIsSaving, deleteComment } = comment.actions;
  try {
    dispatch(setIsSaving(true));
    const { commentsByParentIds, comments } = getState().comment;
    const comment = comments[commentId];
    await commentAPI.deleteComment(postPath, postSlug, comment.path);

    // count the number of comments that will be deleted
    let commentsRemovedCount = 1;
    const childComments = (commentsByParentIds[commentId] || []).slice(0);
    while (childComments.length) {
      const nextChild = childComments.pop();
      commentsRemovedCount++;
      childComments.push(...(commentsByParentIds[nextChild.id] || []).slice(0));
    }

    dispatch(decTotalComments(commentsRemovedCount));
    dispatch(deleteComment({
      commentId,
      commentsRemovedCount
    }));

    dispatch(setIsSaving(false));
  } catch (e) {
    dispatch(setIsSaving(true));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const createComment = (commentId) => async (dispatch, getState) => {
  const { setIsSavingComment, createComment } = comment.actions;
  try {
    const comment = getState().comment.comments[commentId];
    const {
      text,
      picture,
      isAnon,
      anonAuthor
    } = comment.draft;
    if (!text && !picture) {
      dispatch(showErrorAlert("Don't forget to write something first or upload a picture"));
      return;
    }
    dispatch(setIsSavingComment({ id: commentId, isSaving: true }));
    // reply drafts are kept in the parent comment, so the comment.path is the parentCommentPath
    const { data } = await commentAPI.createComment({
      postPath: comment.post_path,
      postSlug: comment.post_slug,
      parentCommentPath: comment.path,
      text,
      picture,
      anon: isAnon,
      author: anonAuthor
    });

    dispatch(createComment(data));
    dispatch(clearReplyToOrEditMode());
    dispatch(setIsSavingComment({ id: commentId, isSaving: false }));
    dispatch(incTotalComments());
  } catch (e) {
    dispatch(setIsSavingComment({ id: commentId, isSaving: false }));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const createReaction = (postPath, postSlug, commentId, shortcode, unicode) => async (dispatch, getState) => {
  const { updateReactionsForComment } = comment.actions;
  try {
    const comment = getState().comment.comments[commentId];
    const { data } = await commentAPI.createReaction(
      postPath,
      postSlug,
      comment.path,
      shortcode,
      unicode
    );
    dispatch(updateReactionsForComment({ commentId, rxnSummary: data.rxn_summary }));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export const deleteReaction = (postPath, postSlug, commentId, unicode) => async (dispatch, getState) => {
  const { updateReactionsForComment } = comment.actions;
  try {
    const comment = getState().comment.comments[commentId];
    const { data } = await commentAPI.deleteReaction(
      postPath,
      postSlug,
      comment.path,
      unicode
    );
    dispatch(updateReactionsForComment({ commentId, rxnSummary: data.rxn_summary}));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export const fetchComments = () => async (dispatch, getState) => {
  const { path, slug, owner, anon, author } = getState().post.singlePost;
  const isAnon = anon && owner;
  const { setCommentsByParentIds, setComments, setSortedRootIds, setIsFetchingComments } = comment.actions;
  try {
    dispatch(setIsFetchingComments(true));
    const { data } = await commentAPI.fetchComments(path, slug);
    const { all_comments, root_comment_ids } = data;

    const buildCommentHashById = () => {
      const allCommentsHash = {};
      all_comments.forEach((comment) => allCommentsHash[comment.id] = comment)
      return allCommentsHash;
    };
    dispatch(setComments(buildCommentHashById()));

    const buildCommentsHashByParentIds = () => {
      const allCommentsByParentIdsHash = {};
      all_comments.forEach((comment) => {
        let existingArr = allCommentsByParentIdsHash[comment.parent_comment_id];
        if (existingArr) {
          existingArr.push(comment.id);
        } else {
          existingArr = [comment.id];
        }
        allCommentsByParentIdsHash[comment.parent_comment_id] = existingArr;
      });
      return allCommentsByParentIdsHash;
    };

    dispatch(setCommentsByParentIds(buildCommentsHashByParentIds()));
    dispatch(populateCommentCounts());
    dispatch(populateHighlights());
    dispatch(setSortedRootIds(root_comment_ids));
    dispatch(setIsFetchingComments(false));
    dispatch(setFirstCommentOpen({
      postPath: path,
      postSlug: slug,
      isAnon,
      anonAuthor: isAnon ? (author || {}).username : null,
    }));
  } catch (e) {
    dispatch(setIsFetchingComments(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const populateHighlights = () => async (dispatch, getState) => {
  const highlight = window.location.hash.substr(1);
  const newComments = { ...getState().comment.comments };

  const updateCommentWithHighlight = (commentId) => {
    const newComment = { ...newComments[commentId], isHighlighted: true };
    newComments[commentId] = newComment;
  };
  const updateCommentWithShowChildTree = (commentId) => {
    const newComment = { ...newComments[commentId], showCommentTree: true };
    newComments[commentId] = newComment;
  };

  const getChildren = (parentCommentId) => {
    const childrenIds = getState().comment.commentsByParentIds[parentCommentId] || [];
    return childrenIds.map((id) => getState().comment.comments[id]);
  };

  const isChildHighlighted = (commentId) => {
    const children = getChildren(commentId);
    if (children.length) {
      let showChildCommentTree = false;
      children.forEach((child) => {
        const isHighlighted = highlight === child.path;
        if (isHighlighted) {
          updateCommentWithHighlight(child.id);
        }
        const showThisCommentTree = isHighlighted || isChildHighlighted(child.id);
        if (showThisCommentTree) {
          updateCommentWithShowChildTree(child.id);
        }
        showChildCommentTree ||= showThisCommentTree;
      });
      return showChildCommentTree;
    }
    return false;
  }

  const rootIds = getState().comment.commentsByParentIds[null] || [];
  rootIds.forEach ((rootId) => {
    const root = getState().comment.comments[rootId];
    const showChildComments = isChildHighlighted(rootId);
    if (showChildComments) {
      updateCommentWithShowChildTree(rootId);
    }
    const isHighlighted = highlight === root.path;
    if (isHighlighted) {
      updateCommentWithHighlight(rootId);
    }
  });

  dispatch(setComments(newComments));
};

export const populateCommentCounts = () => async (dispatch, getState) => {
  const { setComments } = comment.actions;
  const { comments } = getState().comment;

  const newComments = { ...getState().comment.comments };
  const updateCommentWithCount = (commentId, count) => {
    const newComment = { ...newComments[commentId], count };
    newComments[commentId] = newComment;
  };

  const getChildren = (parentCommentId) => {
    const childrenIds = getState().comment.commentsByParentIds[parentCommentId] || [];
    return childrenIds.map((id) => comments[id]);
  };

  const calcCommentCount = (parentCommentId) => {
    let total = 0;
    const children = getChildren(parentCommentId);
    if (children.length) {
      children.forEach((child) => {
        const count = calcCommentCount(child.id);
        total = total + count;
        // store result for this comment
        updateCommentWithCount(child.id, count);

      });

      total = total + children.length;
    }
    return total;
  };
  const rootIds = getState().comment.commentsByParentIds[null] || [];
  rootIds.forEach ((rootId) => {
    const count = calcCommentCount(rootId);
    // store result for this comment
    updateCommentWithCount(rootId, count);
  });

  dispatch(setComments(newComments));
};


export const updateComment = (commentId) => async (dispatch, getState) => {
  const { setIsSavingComment, updateComment } = comment.actions;

  try {
    const comment = getState().comment.comments[commentId];
    const {
      postPath,
      postSlug,
      slug,
      text,
      picture
    } = comment.draft;
    dispatch(setIsSavingComment({ id: commentId, isSaving: true }));
    if (!text && !picture) {
      dispatch(showErrorAlert("Please write something before submitting your comment."));
      dispatch(setIsSavingComment({ id: commentId, isSaving: false }));
      return;
    }
    await commentAPI.updateComment(
      postPath,
      postSlug,
      slug,
      text,
      picture
    );
    // isSaving is cleared on comment in this update
    dispatch(updateComment(commentId));
  } catch (e) {
    dispatch(setIsSavingComment({ id: commentId, isSaving: false }));
    dispatch(showErrorAlertFromErr(e));
  }
};
export default comment.reducer;
