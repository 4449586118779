import React from "react";
import {
  faList,
  faEdit,
  faEnvelope,
  faHome,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames"
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navbar, OverlayTrigger, Tooltip } from "react-bootstrap";

import { COMPANY_PAGES } from "@app/helpers/navbarHelper";
import EmployerDropdown from "./EmployerDropdown";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styles from "./index.module.scss";

interface Props {
  active: string;
  user: any;
}

const EmployerNavbar: React.FC<Props> = ({ active, user }) => {
  const isDesktop = !useMediaQuery({ query: '(max-width: 768px)'});

  const renderDesktopButtons = () => {
    return (
      <span>
        <a
          className={classNames(styles.link, {
            [styles.active]: active === "hiring-index"
          })}
          href="/hiring"
        >
          Home
        </a>

        <a
          className={classNames(styles.link, {
            [styles.active]: active === "candidates-index"
          })}
          href="/hiring/candidates"
        >
          Browse candidates
        </a>

        <a
          className={classNames(styles.link, {
            [styles.active]: active === "messages-home"
          })}
          href="/hiring/messages"
        >
          Messages
        </a>

        <a
          className={classNames(styles.link, {
            [styles.active]: active === "jobs-index" || active === "jobs-show" || active === "self_serve_jobs-new" || active === "jobs-new"
          })}
          href="/hiring/jobs"
        >
          Job postings
        </a>

        <a
          className={classNames(styles.link, {
            [styles.active]: COMPANY_PAGES.includes(active)
          })}
          href="/hiring/company"
        >
          Company profile
        </a>
      </span>
    );
  };

  return (
    <Navbar id="private_navbar">
      <Navbar.Header>
        <Navbar.Brand>
          <a href="/">elpha</a>
        </Navbar.Brand>
        <div className="nav-buttons nav-btn pull-right">
          {isDesktop && renderDesktopButtons()}
          <EmployerDropdown user={user} active={active} />
        </div>
      </Navbar.Header>
    </Navbar>
  );
};

export default EmployerNavbar;
