import React, { useEffect } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { isCompaniesPage, isEmployerPage } from "@app/helpers/navbarHelper";
import { toggleMobileSidebar } from "@app/redux/sidebar";

import EmployerNavbar from "./EmployerNavbar";
import InternalNavbar from "./InternalNavbar";
import PublicNavbar from "./PublicNavbar/index";
import CompaniesNavbar from "./CompaniesNavbar";

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userData.user);
  const active = useSelector(state => state.userData.active);
  const community = useSelector(state => state.userData.community);
  const selectedTag = useSelector(state => state.userData.selectedTag);
  const post = useSelector(state => state.userData.post);
  const modal = useSelector(state => state.userData.modal);
  const url = community ? `/c/${community.slug}` : null;

  useEffect(() => {
    $("#tmp_navbar").remove();
  }, []);

  const handleMobileMenuClick = () => {
    dispatch(toggleMobileSidebar());
  };

  if (active === "talent" || active === "companies-talent") {
    return null;
  }

  if (isCompaniesPage(active)) {
    return (
      <CompaniesNavbar
        active={active}
        onMobileClick={handleMobileMenuClick}
      />
    );
  }

  if (isEmployerPage(active)) {
    return <EmployerNavbar active={active} user={user} />;
  }

  if (user) {
    return (
      <InternalNavbar
        user={user}
        onMobileClick={handleMobileMenuClick}
        active={active}
        modal={modal}
      />
    );
  }

  return <PublicNavbar onMobileClick={handleMobileMenuClick} />;
};

export default Header;
