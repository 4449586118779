import React from "react";
import Button from "./Button";
import SideBarSpinner from "@app/components/SideBars/Spinner";

const DesktopButtonGroup = ({ buttons, isLoading, body }) => {
  const renderButtons = () => {
    if (isLoading) {
      return <SideBarSpinner />;
    }
    return buttons.map((button, index) => {
      return <Button {...button} key={`sb-button-${index}`} />;
    });
  };

  return (
    <div className="side-bar-block">
      {renderButtons()}
      {body}
    </div>
  );
};
export default DesktopButtonGroup;
