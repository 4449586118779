import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";

import atsIntegrationAPI from "./api";
import Company from "@app/types/Hiring/AtsIntegration/Company";

export interface State {
  company?: Company;
  isFetchingCompany: boolean;
}

const atsIntegration = createSlice<State, SliceCaseReducers<State>>({
  name: "atsIntegration",
  initialState: {
    company: undefined,
    isFetchingCompany: false,
  },
  reducers: {
    setCompany(state, action: PayloadAction<Company>) {
      state.company = action.payload;
    },
    setIsFetchingCompany(state, action: PayloadAction<boolean>) {
      state.isFetchingCompany = action.payload;
    },
  },
});

export const fetchCompany = () => async dispatch => {
  const { setCompany, setIsFetchingCompany } = atsIntegration.actions;

  dispatch(setIsFetchingCompany(true));

  try {
    const { data } = await atsIntegrationAPI.fetchCompany();
    dispatch(setCompany(data));
  } catch (e) {
    // TODO
  }

  dispatch(setIsFetchingCompany(false));
};

export default atsIntegration.reducer;
