const { createSlice } = require("@reduxjs/toolkit");

import api from "@app/api";
import combineSlices from "@app/helpers/combineSlices";
import eventsAPI from "./api";
import invite from "./invite";
import { showErrorAlertFromErr, showSuccessAlert } from "../alert";

// TODO: Fill in the rest of this object and then pass it as the `S` generic to
// `createSlice` below.
export interface State {
  isAudioAutoplayFailedModalOpen: boolean;
}

// We'll need this object without the event for when we reset the state. (See
// the `disconnect` action below.)
const initialStateWithoutEvent = {
  agoraRTCToken: undefined,
  /** Whether the user is connected as one of their anon user aliases. */
  isAnonUser: undefined,
  isAudioAutoplayFailedModalOpen: false,
  isMuted: true,
  isConnecting: false,
  isConnected: false,
  isCreatingMicrophoneAudioTrack: false,
  isHandRaised: false,
  isMicrophoneAudioTrackCreated: false,
  isPreJoinModalOpen: false,
  isRSVPing: false,
  /**
   * Toggling mute is an async event. Use this property to disable the mute
   * button while toggling is happening (to prevent race conditions).
   */
  isTogglingMute: false,
  connectedEventGuests: [],

  /**
   * Will be `true` while the request is hitting the server to start/end the
   * event.
   */
  isStartingOrEnding: false,
};

const initialState = {
  ...api.fetchUserData().event,
  ...initialStateWithoutEvent,
};

const event = createSlice({
  name: "events/event",
  initialState,
  reducers: {
    closeAudioAutoplayFailedModal(state) {
      state.isAudioAutoplayFailedModalOpen = false;
    },
    /**
     * @param {object} state
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.agora_rtc_token - The Agora RTC token to
     * save to the state.
     */
    setAgoraRTCToken(state, action) {
      state.agoraRTCToken = action.payload;
    },
    setIsMuted(state, action) {
      state.isMuted = action.payload;
    },
    mute(state) {
      state.isMuted = true;
    },
    openAudioAutoplayFailedModal(state) {
      state.isAudioAutoplayFailedModalOpen = true;
    },
    setIsAnonUser(state, action) {
      state.isAnonUser = action.payload;
    },
    setIsConnecting(state, action) {
      state.isConnecting = action.payload;
    },
    setIsConnected(state, action) {
      state.isConnected = action.payload;
    },
    setIsCreatingMicrophoneAudioTrack(state, action) {
      state.isCreatingMicrophoneAudioTrack = action.payload;
    },
    setIsPreJoinModalOpen(state, action) {
      state.isPreJoinModalOpen = action.payload;
    },
    setIsHandRaised(state, action) {
      state.isHandRaised = action.payload;
    },
    setIsMicrophoneAudioTrackCreated(state, action) {
      state.isMicrophoneAudioTrackCreated = action.payload;
    },
    setIsRSVPing(state, action) {
      state.isRSVPing = action.payload;
    },
    setIsTogglingMute(state, action) {
      state.isTogglingMute = action.payload;
    },
    setConnectedEventGuests(state, action) {
      state.connectedEventGuests = action.payload;
    },

    /**
     * Set the role of the current user in the event.
     *
     * @param {object} state
     * @param {object} action
     * @param {ConnectedEventGuestRole} action.payload - What role the current
     * user should become.
     */
    setRole(state, action) {
      state.user_role_in_event = action.payload;
    },

    /**
     * @param {object} state
     * @param {object} action
     * @param {number} action.payload - The user ID of the event guest to
     * remove.
     */
    removeConnectedEventGuest(state, action) {
      state.connectedEventGuests = state.connectedEventGuests.filter(
        connectedEventGuest =>
          connectedEventGuest.eventRsvp.user_id !== action.payload
      );
    },

    /**
     * @param {object} state
     * @param {object} action
     * @param {object} action.payload
     * @param {number} action.payload.id - The user ID of the event guest to
     * set as muted or unmuted.
     * @param {boolean} action.payload.isMuted - Whether or not the event guest
     * is now muted.
     */
    setIsMutedForRemoteConnectedEventGuest(state, action) {
      state.connectedEventGuests = state.connectedEventGuests.map(
        connectedEventGuest => {
          if (connectedEventGuest.eventRsvp.user_id === action.payload.id) {
            return {
              ...connectedEventGuest,
              isMuted: action.payload.isMuted,
            };
          }

          return connectedEventGuest;
        }
      );
    },

    /**
     * @param {object} state
     * @param {object} action
     * @param {object} action.payload
     * @param {number} action.payload.id - The user ID of the event guest.
     * @param {boolean} action.payload.isHandRaised - Whether or not the event
     * guest now has their hand raised.
     */
    setIsHandRaisedForRemoteConnectedEventGuest(state, action) {
      state.connectedEventGuests = state.connectedEventGuests.map(
        connectedEventGuest => {
          if (connectedEventGuest.eventRsvp.user_id === action.payload.id) {
            return {
              ...connectedEventGuest,
              isHandRaised: action.payload.isHandRaised,
            };
          }

          return connectedEventGuest;
        }
      );
    },

    /**
     * @param {object} state
     * @param {object} action
     * @param {object} action.payload
     * @param {number} action.payload.id - The user ID of the event guest to
     * set the role for.
     * @param {ConnectedEventGuestRole} action.payload.role - The guest's new
     * role.
     */
    setRoleForRemoteConnectedEventGuest(state, action) {
      state.connectedEventGuests = state.connectedEventGuests.map(
        connectedEventGuest => {
          if (connectedEventGuest.eventRsvp.user_id === action.payload.id) {
            connectedEventGuest.eventRsvp.user_role_in_event =
              action.payload.role;
          }

          return connectedEventGuest;
        }
      );
    },

    disconnect(state) {
      Object.assign(state, initialStateWithoutEvent);
    },

    setIsStartingOrEnding(state, action) {
      state.isStartingOrEnding = action.payload;
    },

    /**
     * Merge properties into the event object in state.
     *
     * @param {object} state
     * @param {object} action
     * @param {object} action.payload - An object with RSVP-related properties
     * to merge onto the existing state.
     */
    updateEventRsvpProperties(state, action) {
      state.user_has_rsvped_as_user_id =
        action.payload.user_has_rsvped_as_user_id;
      state.user_has_rsvped_as_username =
        action.payload.user_has_rsvped_as_username;
      state.user_has_agreed_to_event_rules =
        action.payload.user_has_agreed_to_event_rules;
    },

    /**
     * Merge properties into the event object in state.
     *
     * @param {object} state
     * @param {object} action
     * @param {object} action.payload - An object with of event properties to
     * merge onto the existing state.
     */
    updateEvent(state, action) {
      // We want to make sure only valid properties get added to state via this
      // action, in case e.g., the server starts adding additional properties to
      // responses and they get merged into the state without us realizing.
      const allowList = [
        "name",
        "description_pretty",
        "path",
        "slug",
        "date_pretty",
        "allow_anon_users",
        "moderators_and_speakers",
        "started_at",
        "ended_at",
        "user_role_in_event",
        "user_has_agreed_to_event_rules",
        "user_has_rsvped_as_user_id",
        "user_has_rsvped_as_username",
        "user_can_invite_users",
        "user_can_join",
        "gid",
      ];

      allowList.forEach(key => {
        if (action.payload.hasOwnProperty(key)) {
          state[key] = action.payload[key];
        }
      });
    },
  },
});

export const {
  closeAudioAutoplayFailedModal,
  disconnect,
  mute,
  openAudioAutoplayFailedModal,
  removeConnectedEventGuest,
  setIsCreatingMicrophoneAudioTrack,
  setIsPreJoinModalOpen,
  setIsHandRaised,
  setIsHandRaisedForRemoteConnectedEventGuest,
  setIsMicrophoneAudioTrackCreated,
  setIsMuted,
  setIsMutedForRemoteConnectedEventGuest,
  setIsTogglingMute,
  setRole,
  setRoleForRemoteConnectedEventGuest,
  updateEvent,
} = event.actions;

/**
 * @param {string} slug - The slug of the event.
 * @param {string} asUsername - The username that the user wants to connect as.
 * Can be their regular username or one of their anon usernames.
 */
export const rsvp = (slug, asUsername) => async dispatch => {
  const { setIsRSVPing, updateEventRsvpProperties } = event.actions;

  dispatch(setIsRSVPing(true));

  try {
    const { data } = await eventsAPI.rsvp(slug, asUsername);
    dispatch(updateEventRsvpProperties(data));
    dispatch(
      showSuccessAlert(
        "You're registered! Come back to this page when the event starts to participate."
      )
    );
  } catch (e) {
    dispatch(
      showErrorAlertFromErr(
        e,
        "Something went wrong RSVPing. Please try again. If you need help, contact support@elpha.com"
      )
    );
  }

  dispatch(setIsRSVPing(false));
};

/**
 * @param {string} channel - The Agora channel to connect to.
 * @param {string} slug - The slug of the event.
 * @param {string} asUsername - The username that the user wants to connect as.
 * Can be their regular username or one of their anon usernames.
 */
export const connect = (channel, slug, asUsername) => async dispatch => {
  const {
    setAgoraRTCToken,
    setIsAnonUser,
    setIsConnected,
    setIsConnecting,
    updateEventRsvpProperties,
  } = event.actions;

  dispatch(setIsConnecting(true));
  dispatch(setIsConnected(false));

  try {
    const { data } = await eventsAPI.connect(channel, slug, asUsername);
    dispatch(setAgoraRTCToken(data.agora_rtc_token));
    dispatch(updateEventRsvpProperties(data));
    dispatch(setIsAnonUser(data.is_anon_user));
    dispatch(setIsConnected(true));
  } catch (e) {
    dispatch(
      showErrorAlertFromErr(
        e,
        "Something went wrong connecting. Please try again. If you need help, contact support@elpha.com"
      )
    );
  }

  dispatch(setIsConnecting(false));
};

export const refreshTokens = (channel, slug, asUsername) => async dispatch => {
  const { setAgoraRTCToken } = event.actions;

  try {
    const { data } = await eventsAPI.connect(channel, slug, asUsername);
    dispatch(setAgoraRTCToken(data.agora_rtc_token));
    return data;
  } catch {
    // no-op
  }
};

/**
 * @param {number} userID - The user ID of the user who just connected.
 * @param {boolean} isMuted - Whether the user who just connected is muted.
 */
export const addConnectedEventGuest = (userID, isMuted) => async (
  dispatch,
  getState
) => {
  const { slug } = getState().events.event;
  const { setConnectedEventGuests } = event.actions;

  const {
    data: { guest },
  } = await eventsAPI.lookUpGuest(userID, slug);

  if (guest) {
    const { connectedEventGuests } = getState().events.event;
    dispatch(
      setConnectedEventGuests([
        ...connectedEventGuests,
        { eventRsvp: guest, isMuted, isHandRaised: false },
      ])
    );
  }
};

/**
 * @param {string} slug - The slug of the event to start.
 */
export const startEvent = slug => async dispatch => {
  const { setIsStartingOrEnding } = event.actions;

  dispatch(setIsStartingOrEnding(true));

  try {
    await eventsAPI.startEvent(slug);
  } catch (e) {
    dispatch(
      showErrorAlertFromErr(e, "There was an error starting the event.")
    );
  }

  dispatch(setIsStartingOrEnding(false));
};

/**
 * @param {string} slug - The slug of the event to end.
 */
export const endEvent = slug => async dispatch => {
  const { setIsStartingOrEnding } = event.actions;

  dispatch(setIsStartingOrEnding(true));

  try {
    await eventsAPI.endEvent(slug);
  } catch (e) {
    dispatch(showErrorAlertFromErr(e, "There was an error ending the event."));
  }

  dispatch(setIsStartingOrEnding(false));
};

export const updateEventRsvpRole = (slug, userID, role) => async dispatch => {
  try {
    await eventsAPI.updateEventRsvpRole(slug, userID, role);
  } catch (e) {
    dispatch(
      showErrorAlertFromErr(
        e,
        "There was a problem updating the user's role. Please try again."
      )
    );
    throw e;
  }
};

export default combineSlices(event.reducer, initialState, { invite });
