import React from "react";
import classNames from "classnames";
import { Modal } from "react-bootstrap";
import styles from "./index.module.scss";

const AnnouncementModal = ({
  onClose,
  show,
  header,
  body,
  overridePrimaryCTA,
  overrideCTA
}) => {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div className={styles.header}>{header}</div>
        <div>
          <p>{body}</p>
        </div>
        <div className={styles.nextAction}>
          {overridePrimaryCTA}
          <button
            className={classNames("btn", {
              "btn-secondary": overridePrimaryCTA,
              [styles.btnSecondary]: overridePrimaryCTA,
              "btn-primary": !overridePrimaryCTA
            })}
            onClick={handleClose}
          >
            {overrideCTA || "Explore Elpha"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AnnouncementModal;
