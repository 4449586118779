import React from "react";
import $ from "jquery";
import { MentionsInput, Mention } from "react-mentions";
import DefaultStyle from "./DefaultStyle";
import DefaultMentionStyle from "./DefaultMentionStyle";

const AsyncMention = props => {
  const getUsernames = (query, callback) => {
    const url = `/members/all.json?username=${query.toLowerCase()}`;
    $.get(url)
      .then(uns =>
        uns.map(un => ({
          display: un.full_name ? `@${un.username} – ${un.full_name}` : `@${un.username}`,
          id: un.username
        }))
      )
      .then(callback);
  };

  const transformDisplay = tag => `@${tag}`;

  const handleChange = (e, val) => {
    const newVal = val.replace(/{{([a-z\d_]+)}}/gi, "@$1");
    return props.onChange(newVal);
  };

  return (
    <MentionsInput
      className="with-mentions form-group has-feedback"
      style={DefaultStyle}
      value={props.value || ""}
      markup="{{__id__}}"
      onChange={handleChange}
      displayTransform={transformDisplay}
      placeholder={props.placeholder || "Mention users with @"}
    >
      <Mention trigger="@" data={getUsernames} style={DefaultMentionStyle} />
    </MentionsInput>
  );
};
export default AsyncMention;
