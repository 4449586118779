import React from "react";
import { useSelector } from "react-redux";
import CommunityWidget from "./CommunityWidget";
import ExpertsWidget from "./ExpertsWidget";
import TagsWidget from "./TagsWidget";
import styles from "./shared.module.scss";

const PremiumCommunitySideBar = ({ isDesktop = true }) => {
  const communitySlug = useSelector(state => (state.userData.community || {}).slug);
  return (
    <>
      <TagsWidget isDesktop={isDesktop} />
      <ExpertsWidget />
      <CommunityWidget
        fetchMembers={async (communitySlug) => communityAPI.fetchLeadMembers(communitySlug)}
        sectionTitle="peer group leads"
      />
      <CommunityWidget
        skipSelf={true}
        fetchMembers={async (communitySlug) => communityAPI.fetchFeaturedMembers(communitySlug)}
        sectionTitle="members"
        primaryCTA={
          <a href={`/c/${communitySlug}/directory`} className={styles.btn}>Browse Members Directory</a>
        }
      />
    </>
  );
};
export default PremiumCommunitySideBar;
