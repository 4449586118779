import React from "react";
import { setActiveModal } from "@app/redux/modals";
import { useSelector, useDispatch } from "react-redux";
import styles from "./index.module.scss";

const AlreadyAMemberButton = () => {
  const dispatch = useDispatch();
  const clickSource = useSelector(state => state.modals.data && state.modals.data.clickSource);
  const handleSignInClick = () => {
    dispatch(setActiveModal({ active: "signin", data: { clickSource }}));
  };
  const userExists = useSelector(
    state => !!(state.userData.user && state.userData.user.id)
  );

  if (userExists) {
    return null;
  }
  return (
    <div className={styles.root}>
      <span className={styles.caption}>Already a member?</span>
      <button className={styles.signInBtn} onClick={handleSignInClick}>
        Log in now.
      </button>
    </div>
  );
};
export default AlreadyAMemberButton;
