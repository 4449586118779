import { DefaultRootState } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";

import AcceptOrReject from "@app/types/AcceptOrReject";
import createReplyMadLibsSlice from "@app/redux/helpers/createReplyMadLibsSlice";
import hiringAPI from "../api";
import { setItems } from ".";


const sendReply = async (
  {
    acceptOrReject,
    identifier,
    filters,
    page,
    perPage,
  }: {
    acceptOrReject: AcceptOrReject;
    identifier: string;
    filters?: { [key: string]: any };
    page?: number;
    perPage?: number;
  },
  dispatch: Dispatch,
  getState: () => DefaultRootState
) => {
  const {
    rejectionReason,
    rejectionReasonOther,
  } = getState().hiring.companyIntros.replyMadLibs;

  const { data } = await hiringAPI.replyToIntro(
    identifier,
    acceptOrReject,
    rejectionReason,
    rejectionReasonOther,
    filters,
    page,
    perPage
  );

  dispatch(setItems(data.intros));

  return { name: data.candidate_name, message: data.message };
};

const {
  slice,
  reply,
} = createReplyMadLibsSlice("hiring/companyIntros/replyMadLibs", sendReply, [
  "skills_mismatch",
  "experience_mismatch",
  "no_bandwidth",
]);

export default slice.reducer;

export const { selectAccept, selectReject, setRejectionReason } = slice.actions;

export { reply };
