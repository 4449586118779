import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Alert from "@app/redux/alert/Alert";
import { showSuccessAlert, showErrorAlertFromErr } from "@app/redux/alert";
import InputField from "@app/components/common/InputField";

export const RESET_PASSWORD_MODALS = ["set-password", "reset-password", "magic-link"];

const SetPasswordModal = props => {
  const dispatch = useDispatch();
  const isActiveModal = useSelector(state => RESET_PASSWORD_MODALS.indexOf(state.modals.active) !== -1);
  const isLoggedIn = useSelector(state => state.userData.user && state.userData.user.id);
  const [password, setPassword] = useState("");
  const [isPwMissing, setIsPwMissing] = useState(false);
  const [show, setShow] = useState(isActiveModal);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setShow(isActiveModal);
  }, [isActiveModal]);

  const handlePwChange = newPassword => {
    setPassword(newPassword);
    setIsPwMissing(false);
  };

  const handleCloseModal = () => setShow(false);

  const handleUpdatePw = () => {
    setSaving(true);
    if (!password) {
      setIsPwMissing(true);
      setSaving(false);
      return false;
    }
    $.ajax({
      url: "/account/password",
      method: "PUT",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        user: { password }
      }),
      success: () => {
        window.location = "/top";
      },
      error: e => {
        setSaving(false);
        dispatch(showErrorAlertFromErr(e));
      }
    });
    return false;
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Body>
        <div className="flex-row">
          <div className="left-col">
            <button type="button" onClick={handleCloseModal} className="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <div className="modal-subject-title">{props.reset ? "Reset" : "Set a"} password</div>
            <div className="modal-subject-text">
              Choose at least 8 characters for your password.
            </div>
          </div>
          <div className="right-col">
            <div className="flex-col-middle">
              <Alert />
              <div className={`relative-div ${isPwMissing ? "invalid-input-text" : ""}`}>
                <InputField
                  id="pw_field"
                  placeholder="Password"
                  value={password || ""}
                  type="password"
                  onChange={handlePwChange}
                />
                {isPwMissing ? (
                  <div className="invalid-input-text-caption">Invalid Password</div>
                ) : null}
              </div>
              <button className="btn btn-primary" disabled={saving} onClick={handleUpdatePw}>
                {props.reset ? "Reset my password" : "Set my password"}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SetPasswordModal;
