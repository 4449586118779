import React from "react";
import Button from "./Button";
import SideBarSpinner from "@app/components/SideBars/Spinner";

const MobileButtonGroup = ({ buttons, isLoading, body }) => {
  const renderButtons = () => {
    if (isLoading) <SideBarSpinner />;
    return buttons.map((button, index) => (
      <Button {...button} key={index} />
    ));
  };

  return (
    <div className="side-bar-block">
      {body}
      {renderButtons()}
    </div>
  );
};
export default MobileButtonGroup;
