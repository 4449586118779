import { Glyphicon } from "react-bootstrap";
import React from "react";
import { ToolbarChildrenProps } from "@draft-js-plugins/static-toolbar/lib/components/Toolbar";

import AddImageField from "./AddImageField";

/**
 * A component to add an image to the DraftJS rich-text editor. Adapted from
 * https://stackoverflow.com/a/50305602/974981
 */
const AddImageButton: React.FC<ToolbarChildrenProps> = ({
  theme,
  onOverrideContent,
}) => (
  <div className={theme.buttonWrapper}>
    <button
      onClick={() => onOverrideContent(AddImageField)}
      className={theme.button}
    >
      <Glyphicon glyph="picture" />
    </button>
  </div>
);

export default AddImageButton;
