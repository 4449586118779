import React, { useRef, useState } from "react";
import { Glyphicon } from "react-bootstrap";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import ReactSelectWrapper from "@app/components/common/ReactSelectWrapper";
import classNames from "classnames";

import { showErrorAlertFromErr, showErrorAlert } from "@app/redux/alert";
import BodyRichTextField from "@app/components/common/BodyRichTextField";
import CustomCheckbox from "@app/components/common/CustomCheckbox";
import ImageUploader from "@app/components/common/ImageUploader";
import InputField from "@app/components/common/InputField";
import AnonField from "../../AnonField";
import AsyncMention from "../../AsyncMention";
import MakePublicBanner from "../../MakePublicBanner";
import styles from "./index.module.scss";

const NewPost = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userData.user) || {};
  const post = useSelector(state => state.userData.post) || {};
  const isEdit = !!post.id;
  const baseCommunitiesOpts = useSelector(state => state.userData.communities) || [];
  const activeCommunity = useSelector(state => state.userData.community) || {};

  const canChooseCommunities = !activeCommunity.is_premium && (user.mod || !isEdit);
  const canChooseTags = activeCommunity.is_premium && (user.mod || !isEdit);

  const [tags, setTags] = useState(post.tags || []);
  const tagOpts = (activeCommunity.tags || []).map((tag) => {
    return { value: tag, label: tag }
  });

  // All posts created since the `body_rich_text` field was added to posts
  // should use that field instead of the `body` field. Thus, if the `body`
  // field is blank, we can assume that this post uses rich text.
  const postUsesRichText = !post.body;

  const communitiesOpts = [...baseCommunitiesOpts];
  if (user.admin) {
    const ids = communitiesOpts.map((c) => c.value);
    if (post.communities) {
      post.communities.forEach(c => {
        if (ids.indexOf(c.id) === -1) {
          communitiesOpts.push(c);
        }
      });
    }
  }

  const bodyRichTextFieldRef = useRef();

  const communityIds = [...(post.community_ids || [])];
  if (activeCommunity.id) {
    communityIds.push(activeCommunity.id);
  }
  const [communities, setCommunities] = useState(communityIds);
  const [makePublic, setMakePublic] = useState(post.id ? post.public_type : false);
  const [saving, setSaving] = useState(false);
  const [adminOverrideUsername, setAdminOverrideUsername] = useState(post.member_name || "");
  const [url, setUrl] = useState(post.url || null);
  const [title, setTitle] = useState(post.title || "");
  const [body, setBody] = useState(post.body || null);
  const [picture, setPicture] = useState(post.picture);
  const [digestOverrideTitle, setDigestOverrideTitle] = useState(post.digest_override_title || "");
  const [summary, setSummary] = useState(post.summary || "");
  const [emoji, setEmoji] = useState(post.emoji || "");
  const [anonAuthor, setAnonAuthor] = useState(post.anon ? post.author.username : null);
  const [boost, setBoost] = useState(post.boost || 0);
  const [onPublicFeed, setOnPublicFeed] = useState(post.on_public_feed || false);
  const [state, setState] = useState("visible");
  const [featured, setFeatured] = useState(post.featured || false);
  const [companyName, setCompanyName] = useState(post.company_name)

  let bodyPlaceholder = "Write here (and mention members with @).";
  if (makePublic) {
    bodyPlaceholder += " Note: This is a public post, which means the post is available for anyone to read. To learn more visit elpha.com/faq#public.";
  }

  const validatePost = (bodyRichText) => {
    if (!body && !bodyRichText && !url && !picture) {
      return "Don't forget to include a URL, picture, or text.";
    }
    if (!title) {
      return "Don't forget a title.";
    }
    if (!communities) {
      return "Choose at least one community for your post.";
    }
    if (activeCommunity.is_premium && !tags) {
      return "Choose at least one tag for your post.";
    }
    return null;
  }

  const handleCommunitiesChange = (opts) => setCommunities(opts.map(opt => opt.value));
  const handleTagChange = (opts) => setTags(opts.map(opt => opt.value));

  const handleAdminOverrideUsername = (opt) => setAdminOverrideUsername(opt.value);
  const handleAdminOverrideUsernameText = (text) => setAdminOverrideUsername(text);
  const handleOnPublicFeedChange = (opt) => setOnPublicFeed(opt.value);
  const handleFeaturedChange = () => setFeatured(!featured);
  const handleStateChange = (opt) => setState(opt.value);

  const handlePictureChange = (val) => setPicture(val);
  const handleCompanyNameChange = (val) => setCompanyName(val);
  const handleAnonChange = (val) => setAnonAuthor(val);
  const handleMakePublicChange = () => setMakePublic(!makePublic);
  const handleBoostChange = (val) => setBoost(val);
  const handleTitleChange = (val) => setTitle(val);
  const handleDigestOverrideTitleChange = val => setDigestOverrideTitle(val);
  const handleSummaryChange = val => setSummary(val);
  const handleEmojiChange = val => setEmoji(val);
  const handleUrlChange = (val) => setUrl(val);

  const trimAll = () => {
    let newUrl = (url || "").trim();
    if (newUrl) {
      setUrl(newUrl.indexOf("://") === -1 ? `https://${newUrl}` : newUrl);
    }

    setTitle((title || "").trim());
  };


  const handleSave = e => {
    setSaving(true);
    trimAll();
    const bodyRichText = postUsesRichText ? bodyRichTextFieldRef.current.getRichText() : undefined;
    const err = validatePost(bodyRichText);
    if (err) {
      setSaving(false);
      dispatch(showErrorAlert(err));
      return false;
    }
    const data = {
      admin_override_username: adminOverrideUsername,
      title,
      body,
      body_rich_text: bodyRichText,
      url,
      picture: picture,
      anon: !!anonAuthor,
      author: anonAuthor ? anonAuthor : null,
      public_state: makePublic ? "global" : "community"
    };

    if (user.mod || !isEdit) {
      data.group_ids = communities || [];
    }

    if (canChooseTags) {
      data.tags = tags;
    }

    if (user.content_admin) {
      data.boost = boost;
      data.on_public_feed = onPublicFeed;
      data.state = state;
      data.featured = featured;
    }
    if (user.partnership_admin) {
      data.company_name = companyName;
    }
    if (user.digest_admin) {
      data.digest_override_title = digestOverrideTitle;
      data.summary = summary;
      data.emoji = emoji;
    }

    $.ajax({
      url: isEdit ? post.full_url : "/posts",
      method: isEdit ? "PUT" : "POST",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({ post: data }),
      success: (data) => {
        window.location = data.post.full_url;
      },
      error: e => {
        setSaving(false);
        dispatch(showErrorAlertFromErr(e));
      }
    });
  };

  const renderSubmitButton = () => {
    return (
      <button
        type="submit"
        className={classNames("btn", "btn-primary", styles.btnPrimary)}
        disabled={saving}
        onClick={handleSave}
      >
        {saving
          ? isEdit
            ? "Saving..."
            : "Posting..."
          : isEdit
          ? "Save"
          : "Post"}
      </button>
    );
  }

  const renderMakePublicField = () => {
    if (isEdit && !makePublic) {
      return null;
    }
    return (
      <div className="public-checkbox">
        <CustomCheckbox
          checked={makePublic}
          onChange={handleMakePublicChange}
          label={
            <>
              Make it public for anyone to see{" "}
              <a target="_blank" href="/faq#public" className="public-help">
                <Glyphicon glyph="question-sign" />
              </a>
            </>
          }
        />
      </div>
    );
  };


  const renderManagerFields = () => {
    if (user.mod && !user.content_admin) {
      const opts = [
        { label: "bookworm", value: "bookworm" },
        { label: "bzzybee", value: "bzzybee" },
        { label: "curiouscat", value: "curiouscat" }
      ];
      return (
        <div>
          <br />
          <b>Manager Tools</b>
          <br />
          <br />
          <ReactSelectWrapper
            value={opts.find(opt => opt.value === adminOverrideUsername) || ""}
            placeholder="Username override"
            onChange={handleAdminOverrideUsername}
            options={opts}
          />
        </div>
      );
    }
    return null;
  };

  const renderDigestAdminFields = () => {
    if (user.digest_admin) {
      return (
        <>
          <br />
          <b>Digest Tools</b>
          <br />
          <br />
          <InputField
            value={digestOverrideTitle || ""}
            placeholder="Digest Title"
            type="text"
            onChange={handleDigestOverrideTitleChange}
          />
          <InputField
            key="summary"
            value={summary || ""}
            label="Summary for SEO & Digest"
            componentClass="textarea"
            onChange={handleSummaryChange}
          />
          <InputField
            key="emoji"
            value={emoji || ""}
            label="Emoji descriptor"
            onChange={handleEmojiChange}
          />
        </>
      );
    }
    return null;
  };

  const renderPartnerFields = () => {
    if (user.partnership_admin) {
      const opts = [{ label: "ElphaCompanies", value: "ElphaCompanies" }];
      return (
        <>
          <br />
          <b>Partnership Tools</b>
          <br />
          <br />
          <InputField
            value={companyName || ""}
            placeholder="Company Name"
            type="text"
            onChange={handleCompanyNameChange}
          />
          <ReactSelectWrapper
            value={opts.find(opt => opt.value === adminOverrideUsername) || ""}
            placeholder="Username override"
            onChange={handleAdminOverrideUsername}
            options={opts}
          />
        </>
      );
    }
    return null;
  };

  const renderAdminFields = () => {
    if (user.content_admin) {
      const opts = [
        { label: "Yes", value: true },
        { label: "No", value: false }
      ];
      const featuredOpts = [
        { label: "Featured", value: true },
        { label: "Not featured", value: false }
      ];
      const postStateOpts = [
        { label: "draft", value: "pending" },
        { label: "published", value: "visible" }
      ];
      return (
        <>
          <br />
          <b>Admin Tools</b>
          <br />
          <br />
          <InputField
            value={adminOverrideUsername || ""}
            placeholder="Admin Override Username"
            type="text"
            onChange={handleAdminOverrideUsernameText}
          />
          <InputField
            value={boost || ""}
            placeholder="Boost"
            type="number"
            onChange={handleBoostChange}
          />
          {!user.admin ? null : (
            <ReactSelectWrapper
              placeholder="On public sample feed"
              value={opts.find(opt => opt.value === onPublicFeed) || ""}
              onChange={handleOnPublicFeedChange}
              options={opts}
            />
          )}
          <label>Featured?</label>
          <ReactSelectWrapper
            placeholder="Featured"
            value={featuredOpts.find(opt => opt.value === featured) || ""}
            onChange={handleFeaturedChange}
            options={featuredOpts}
          />
          <label>Publish now or save as a draft?</label>
          <ReactSelectWrapper
            placeholder="Post state"
            value={postStateOpts.find(opt => opt.value === state) || ""}
            onChange={handleStateChange}
            options={postStateOpts}
          />
        </>
      );
    }
    return null;
  }

  let headerText = "Write a post";
  if (isEdit) headerText = "Edit your post";
  if (activeCommunity.is_premium) headerText = `${headerText} in ${activeCommunity.name}`;

  return (
    <div className={styles.root}>
      {makePublic && <MakePublicBanner />}
      <div>
        <h3 className="text-center">{headerText}</h3>
        <br />
      </div>
      <div>
        {canChooseCommunities && (
          <div className={styles.selectGroup}>
            <div className={styles.fieldLabel}>Choose communities in which to share your post</div>
            <ReactSelectWrapper
              placeholder="Choose communities"
              value={(communities || []).map(element => (
                communitiesOpts.find(({ value }) => value === element)
              ))}
              isMulti={true}
              onChange={handleCommunitiesChange}
              options={communitiesOpts}
            />
          </div>
        )}
        {canChooseTags && (
          <div className={styles.selectGroup}>
            <div className={styles.fieldLabel}>Choose tags that describe your post</div>
            <ReactSelectWrapper
              isMulti={true}
              options={tagOpts}
              onChange={handleTagChange}
              value={tags.map(element => (
                tagOpts.find(({ value }) => value === element)
              ))}
              placeholder="Choose tags"
            />
          </div>
        )}
        <InputField
          value={title || ""}
          placeholder="*Title"
          onChange={handleTitleChange}
        />
        <InputField
          value={url || ""}
          placeholder="https://some-website.com"
          onChange={handleUrlChange}
        />
        {postUsesRichText && <BodyRichTextField value={post.body_rich_text} ref={bodyRichTextFieldRef} placeholder={bodyPlaceholder} />}
        {!postUsesRichText && (
          <AsyncMention
            value={body || ""}
            placeholder={bodyPlaceholder}
            onChange={setBody}
          />
        )}
        {!activeCommunity.is_premium && renderMakePublicField()}
        {!isEdit && (
          <div className={styles.anonField}>
            <AnonField
              anonAuthor={anonAuthor}
              onChange={handleAnonChange}
            />
          </div>
        )}
        {renderManagerFields()}
        {renderDigestAdminFields()}
        {renderAdminFields()}
        {!activeCommunity.is_premium && renderPartnerFields()}
        <div className={styles.newPostButtons}>
          {renderSubmitButton()}
          <ImageUploader
            buttonOnly
            onChange={handlePictureChange}
            picture={picture}
          />
        </div>
      </div>
    </div>
  );
}
export default NewPost;
