const internalPagesWithTwoSideBars = [
  "posts-active",
  "posts-recent",
  "posts-all",
  "posts-best_of",
  "communities-show",
  "communities-show_top",
  "communities-show_new",
];

const onboardingPages = [
  "onboardings-step1",
  "onboardings-step2",
  "onboardings-step2_salary_database",
  "onboardings-step3",
  "onboardings-step4",
  "onboardings-step5",
  "onboardings-step6",
  "onboardings-step7",
  "onboardings-step8",
  "registers-linkedin_success",
];

const publicPagesWithOneSideBar = [
  "communities-show",
  "logged-out-home",
  "posts-active",
  "posts-recent",
];

const ssrPages = [
  "posts-show",
  "companies-show"
];

export const isPageWithHiringSideBar = ({ user, active }) => {
  return user && (user.talent_team_member || user.partnership_admin) && hiringPagesWithSideBar.includes(active);
};

export const isPageWithSSR = (active) => {
  return ssrPages.includes(active);
};

export const isPageWithTwoSideBars = ({ user, active }) => {
  const isLoggedIn = user && user.id;
  return isLoggedIn && internalPagesWithTwoSideBars.includes(active);
};

export const isPageWithOneSideBar = ({ user, active }) => {
  const isLoggedIn = user && user.id;
  return !isLoggedIn && publicPagesWithOneSideBar.includes(active);
};

export const isOnboardingPage = active => {
  return onboardingPages.indexOf(active) !== -1;
};
