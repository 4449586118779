import React, { useMemo, useState } from "react";

import createImagePlugin from '@draft-js-plugins/image';


const useImagePlugin = () => {
  const imagePlugin = useMemo(() => createImagePlugin(), []);
  return {
    imagePlugin
  };
};
export default useImagePlugin;
