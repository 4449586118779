import React from "react";

export const ModalTitle = ({ text }) => (
  <div className="modal-subject-title">{text}</div>
);

export const ModalBody = ({ text }) => (
  <div className="modal-subject-text">{text}</div>
);

export const ModalCloseXOut = ({ onClick }) => (
  <button type="button" onClick={onClick} className="close" aria-label="Close">
    <span aria-hidden="true">×</span>
  </button>
);
