/**
 *
 * We embed the JoinBox in many other components. Sometimes we want to autofill an email by passing a parameter of email=some-email.
 *
 *
 */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";
import useSimpleSignup from "@app/hooks/useSimpleSignup";
import useTestChecker from "@app/hooks/useTestChecker";
import useParams from "@app/hooks/useParams";
import { dompurify, parseError } from "@app/components/common/utils";
import Alert from "@app/redux/alert/Alert";
import { setActiveModal } from "@app/redux/modals";
import SimpleInputField from "@app/components/common/SimpleInputField";
import LinkedInButton from "../../LinkedInButton";
import styles from "./index.module.scss";

const JoinBoxStep1 = ({ ctaOverride = null }) => {

  const loginEmail = useSelector(state => state.userData.loginEmail);
  const dispatch = useDispatch();

  const { getQueryParam } = useParams();
  const defaultEmail = getQueryParam("email");

  const isSupporter = useSelector(state => !!(state.modals.data || {}).isSupporter);
  const clickSource = useSelector(state => (state.modals.data || {}).clickSource);
  const community = useSelector(state => (state.modals.data || {}).community || {});

  const [showJoinWithEmail, setShowJoinWithEmail] = useState(!!defaultEmail);

  const {
    handleEmailChange,
    handleSubmit,
    isEmailMissing,
    saving,
    setErrorMessage,
  } = useSimpleSignup({
    isSupporter,
    defaultEmail,
    additionalDataForSignup: {
      group: community.slug,
      signup_source: clickSource,
      final_url: window.location.href,
    },
  });

  useEffect(() => {
    if (isSupporter) {
      setShowJoinWithEmail(true);
    }
  }, [isSupporter]);

  const handleOnSignInClick = () => {
    dispatch(setActiveModal({ active: "signin", data: { clickSource }}));
  };

  const handleJoinEmailClick = () => {
    setShowJoinWithEmail(true);
  };

  const renderJoinWithEmailFields = () => {
    return (
      <form
        onSubmit={handleSubmit}
        className={classNames("flex-col-middle", styles.form)}
      >
        <hr />
        <div
          className={classNames("text-input-field", {
            "invalid-input-text": isEmailMissing
          }, styles.inputField)}
        >
          <label htmlFor="email" style={{ display: "none" }}>
            email
          </label>
          <SimpleInputField
            focus
            id="email"
            name="email"
            autocomplete="email"
            placeholder="Email*"
            defaultValue={defaultEmail || ""}
            onChange={handleEmailChange}
            required
          />
          {isEmailMissing ? (
            <div className="invalid-input-text-caption">Invalid Email</div>
          ) : null}
        </div>
        <div className={styles.choicesBox}>
          <div className={styles.tos}>
            By clicking {isSupporter ? "Become a supporter" : "Become a member"}, you agree to our{" "}
            <a target="_blank" href="/privacy" tabIndex="-1" className={styles.tosA}>
              Privacy Policy
            </a>
            ,{" "}
            <a href="/terms" target="_blank" tabIndex="-1" className={styles.tosA}>
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="/guidelines" target="_blank" tabIndex="-1" className={styles.tosA}>
              Guidelines
            </a>
            .
          </div>
          <button type="submit" className="btn btn-primary" disabled={saving}>
            {saving ? "Creating your application..." : (isSupporter ? "Become a supporter" : "Become a member")}
          </button>
          <button
            onClick={handleOnSignInClick}
            className={classNames(styles.buttonTextOnly, styles.buttonDefault, styles.buttonSignInInstead)}
          >
            Sign in
          </button>
        </div>
      </form>
    );
  };

  return (
    <div>
      <div className={styles.step1}>
        <Alert />
        <LinkedInButton
          type="join"
          text={
            ctaOverride ? `${ctaOverride} with LinkedIn` : "Join with LinkedIn"
          }
        />
        {showJoinWithEmail ||
        clickSource === "email-join-banner" ? (
          renderJoinWithEmailFields()
        ) : (
          <button
            className={classNames("btn", "btn-full", styles.joinWithEmail)}
            disabled={saving}
            onClick={handleJoinEmailClick}
          >
            {saving
              ? "Creating application..."
              : ctaOverride
              ? `${ctaOverride} with Email`
              : "Join with email"}
          </button>
        )}
      </div>
    </div>
  );
};
export default JoinBoxStep1;
