import React from "react";
import AnnouncementModal from "./AnnouncementModal";
import { useSelector } from "react-redux";

const PendingReminderModal = props => {
  const user = useSelector(state => state.userData.user) || {};

  const handleClick = () => {
    props.onClick && props.onClick();
  };

  if (user.talent_team_member && user.supporter) {
    return (
      <AnnouncementModal
        header="So glad to see you here!"
        body="You're logged into Elpha with an employer account. That means you have limited access to posts and other resources. If you have any questions, send us a note at support@elpha.com."
        show={props.show}
        onClose={handleClick}
      />
    );
  }
  if (user.supporter) {
    return (
      <AnnouncementModal
        header="So glad to see you here!"
        body="You're logged into Elpha as someone who supports women at work (thank you!), so your access to posts and other resources is somewhat limited. If you have any questions, send us a note at support@elpha.com."
        show={props.show}
        onClose={handleClick}
      />
    );
  }
  return (
    <AnnouncementModal
      header="Your account is under review"
      body="To make sure Elpha is a comfortable place for our members, we have a short review process for everyone who wants to join the network. You'll have full access to the network once your membership is approved. It usually takes less than 24 hours."
      show={props.show}
      onClose={handleClick}
    />
  );
};
export default PendingReminderModal;
