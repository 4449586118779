import React from "react";
import { InputGroup } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./index.module.scss";

import { ONE_LINER, getErrorMessageFor, ratingOpts } from "./utils";
import {
  jobStatusOpts,
  activeEmployeeOpts
} from "./utils";
import {
  deptsOpts,
} from "@app/components/pages/Companies/utils";
import FieldWrapper from "../FieldWrapper";
import BooleanSelect from "../CandidateModal/BooleanSelect";
import SingleSelectButtonGroup from "@app/components/common/SingleSelectButtonGroup";
import Step from "./Step";

const schema = yup.object().shape({
  company: yup.string().required("Required"),
  activeEmployee: yup.string().required("Required"),
  lastYearEmployed: yup
    .number()
    .nullable()
    .typeError("Must be a number")
    .min(1950, "Choose a valid start date")
    .max(2025, "Choose a valid end date")
    .when("activeEmployee", {
      is: "Current employee ",
      then: yup.number().nullable().required("Required")
    }),
  rating: yup.number().nullable().required("Required")
});

const Step1 = ({ companyRating, onSubmit }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: companyRating || {},
    resolver: yupResolver(schema),
  });

  const company = useWatch({
    control,
    name: "company",
  });

  const activeEmployee = useWatch({
    control,
    name: "activeEmployee",
  });

  const rating = useWatch({
    control,
    name: "rating",
  });

  const companyFiller = company || "the company you're rating";
  const isCurrent = activeEmployee !== "Former employee";
  const wasOrIs = isCurrent ? "is" : "was";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Step
        title="Rate Your Company Anonymously"
        subtitle={ONE_LINER}
      >
        <FieldWrapper
          label="What is the name of the company you're rating?"
          error={getErrorMessageFor("company", errors)}
          required>
          <input
            type="text"
            className="form-control"
            {...register("company")}
          />
        </FieldWrapper>
        <FieldWrapper
          label={`Do you currently work at ${companyFiller}?`}
          error={getErrorMessageFor("activeEmployee", errors)}
          required
        >
          <select className="form-control" {...register("activeEmployee")}>
            <option />
            {activeEmployeeOpts.map(({ label, value }) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </FieldWrapper>

        <FieldWrapper
          label={`What ${wasOrIs} your employment status at ${companyFiller}?`}
          error={getErrorMessageFor("employmentStatus", errors)}
          required
        >
          <select className="form-control" {...register("employmentStatus")}>
            <option />
            {jobStatusOpts.map(({ label, value }) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </FieldWrapper>

        {!isCurrent && (
          <FieldWrapper
            label={`When was the last year you worked for ${companyFiller}?`}
            error={getErrorMessageFor("lastYearEmployed", errors)}
            required
          >
            <input
              type="number"
              className="form-control"
              {...register("lastYearEmployed")}
            />
            <small className="text-muted">A year in the format YYYY (e.g. 2022)</small>
          </FieldWrapper>)
        }
        <hr />
        <FieldWrapper
          label={`How likely are you to recommend ${companyFiller} as a women-friendly workplace?`}
          error={getErrorMessageFor("rating", errors)}
          required
        >
          <p style={{ fontStyle: "italic" }}>On a scale from 0 (Not likely at all) to 10 (Extremely likely)</p>
          <Controller
            name="rating"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SingleSelectButtonGroup
                selectedValue={value}
                onClick={val => onChange(val)}
                elements={ratingOpts}
                overrideStyles={styles}
              />
            )}
          />
        </FieldWrapper>
        {rating !== undefined && (
          <FieldWrapper
            label={`Why did you give ${companyFiller} a rating of ${rating}?`}
            error={getErrorMessageFor("review", errors)}
          >
            <textarea className="form-control" {...register("review")} />
          </FieldWrapper>
        )}

        <hr/>
        <FieldWrapper
          label={`Which department ${isCurrent ? "do" : "did"} you work in at ${companyFiller}?`}
          error={getErrorMessageFor("department", errors)}
        >
          <Controller
            name="department"
            control={control}
            render={({ field: { onChange, value } }) => (
              <select className="form-control" {...register("department")}>
                <option />
                {deptsOpts.map(({ label, value }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </select>
            )}
          />
        </FieldWrapper>

        <FieldWrapper
          label={`What ${wasOrIs} your job title at ${companyFiller}`}
          error={getErrorMessageFor("title", errors)}
        >
          <input
            type="text"
            className="form-control"
            {...register("title")}
          />
        </FieldWrapper>
        <FieldWrapper
          label={`${isCurrent ? "Are" : "Were"} you an executive at ${companyFiller}?`}
          error={getErrorMessageFor("executive", errors)}
          required>
          <Controller
            name="executive"
            control={control}
            render={({ field: { onChange, value } }) => (
              <BooleanSelect value={value} onChange={onChange} allowNull />
            )}
          />
        </FieldWrapper>
      </Step>
    </form>
  );
};

export default Step1;
