import React, { Suspense } from "react";
import { Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import useParams from "@app/hooks/useParams";


import { lazyLoadFallbackUI } from "@app/helpers/lazyLoadHelper";

// This is a rather large component tree, so we'll only load it if the modal
// should be shown.
const Form = React.lazy(() => import("./Form"));

/**
 * @param {object} props
 */
const CandidateModal = () => {
  const { removeQueryParams } = useParams();
  const show = useSelector(state => state.modals.active === "quiz");
  const handleHide = () => removeQueryParams(["modal", "step", "source"]);

  return (
    <Modal show={show} onHide={handleHide}>
      <Suspense fallback={lazyLoadFallbackUI}>
        <Form />
      </Suspense>
    </Modal>
  );
};

export default CandidateModal;
