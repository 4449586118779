import React from "react";
import { useSelector } from "react-redux";
import LatestResourcesWidget from "./LatestResourcesWidget";
import NeedingEngagementWidget from "./NeedingEngagementWidget";
import FeaturedMemberOffersWidget from "./FeaturedMemberOffersWidget";
import FeaturedMemberFavorsWidget from "./FeaturedMemberFavorsWidget";
import styles from "./index.module.scss";

const RightSideBar = () => {
  return (
    <div className={styles.root}>
      <div className="side-bar-container">
        <div className="side-bar-body-container">
          <NeedingEngagementWidget />
          <LatestResourcesWidget />
          <FeaturedMemberOffersWidget />
          <FeaturedMemberFavorsWidget />
        </div>
      </div>
    </div>
  );
};

export default RightSideBar;
