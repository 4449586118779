import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { DefaultRootState } from "react-redux";

import AcceptOrReject from "@app/types/AcceptOrReject";
import parseError from "@app/helpers/parseError";

interface RejectionReasonBothUndefined {
  rejectionReason?: undefined;
  rejectionReasonOther?: undefined;
}

interface RejectionReasonBuiltIn {
  rejectionReason: string;
  rejectionReasonOther?: undefined;
}

interface RejectionReasonOther {
  rejectionReason?: undefined;
  rejectionReasonOther: string;
}

type RejectionReasonParams =
  | RejectionReasonBothUndefined
  | RejectionReasonBuiltIn
  | RejectionReasonOther;

export type State = {
  acceptOrReject?: AcceptOrReject;
  alertTimeoutID?: number;
  errorMessage?: string;
  name?: string;
  hasFinishedReplyRequest: boolean;
  isReplying: boolean;
  lastSavedValues?: {
    acceptOrReject: AcceptOrReject;
    rejectionReason?: string;
    rejectionReasonOther?: string;
  };
  successMessage?: string;
} & RejectionReasonParams;

interface SendReplyParams {
  acceptOrReject: AcceptOrReject;
  rejectionReason?: string;
  rejectionReasonOther?: string;
  identifier: string;
  /** Additional params to pass to the API function */
  [key: string]: any;
}

type SendReply = (
  params: SendReplyParams,
  dispatch?: Dispatch,
  getState?: () => DefaultRootState
) => Promise<{ name: string; message: string }>;

/**
 * A helper that creates a Redux slice for a mad-libs-style reply form. Since
 * the company intro and candidate message reply functionality is so similar,
 * this can be used for both.
 */
const createReplyMadLibsSlice = (
  sliceName: string,
  /**
   * A function that calls the API to send a reply, and returns a promise with
   * the name of the company/person you're replying to, along with a success
   * message.
   */
  sendReply: SendReply,
  builtInRejectionReasons: string[]
) => {
  const initialState: State = {
    acceptOrReject: undefined,
    alertTimeoutID: undefined,
    errorMessage: undefined,
    name: undefined,
    hasFinishedReplyRequest: false,
    isReplying: false,
    lastSavedValues: undefined,
    rejectionReason: undefined,
    rejectionReasonOther: undefined,
    successMessage: undefined,
  };

  const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      clearAlerts(state: State, action: PayloadAction<number>) {
        if (state.alertTimeoutID === action.payload) {
          state.errorMessage = undefined;
          state.successMessage = undefined;
        }
      },
      replyFailed(state: State, action: PayloadAction<string>) {
        state.errorMessage = action.payload;
        state.hasFinishedReplyRequest = true;
        state.isReplying = false;
      },
      replySucceeded(
        state: State,
        action: PayloadAction<{ message: string; name: string }>
      ) {
        state.hasFinishedReplyRequest = true;
        state.isReplying = false;
        state.successMessage = action.payload.message;
        state.name = action.payload.name;
        state.lastSavedValues = {
          acceptOrReject: state.acceptOrReject,
          rejectionReason: state.rejectionReason,
          rejectionReasonOther: state.rejectionReasonOther,
        };
      },
      selectAccept(state: State) {
        state.acceptOrReject = "accept";
        state.rejectionReason = undefined;
        state.rejectionReasonOther = undefined;
      },
      selectReject(state: State) {
        state.acceptOrReject = "reject";
      },
      setAlertTimeoutID(state: State, action: PayloadAction<number>) {
        state.alertTimeoutID = action.payload;
      },
      setRejectionReason(state: State, action: PayloadAction<string>) {
        if (builtInRejectionReasons.includes(action.payload)) {
          state.rejectionReason = action.payload;
          state.rejectionReasonOther = undefined;
        } else {
          state.rejectionReason = undefined;
          state.rejectionReasonOther = action.payload;
        }
      },
      startReply(state: State) {
        state.isReplying = true;
        state.errorMessage = undefined;
        state.successMessage = undefined;
      },
    },
  });

  const reply = ({
    acceptOrReject,
    rejectionReason,
    rejectionReasonOther,
    identifier,
    ...additionalParams
  }: {
    acceptOrReject: AcceptOrReject;
    rejectionReason?: string;
    rejectionReasonOther?: string;
    identifier: string;
    /** Additional params to pass to the API function */
    [key: string]: any;
  }) => async (dispatch: Dispatch, getState: () => DefaultRootState) => {
    const {
      clearAlerts,
      setAlertTimeoutID,
      startReply,
      replySucceeded,
      replyFailed,
    } = slice.actions;

    dispatch(startReply());

    try {
      const data = await sendReply(
        {
          acceptOrReject,
          identifier,
          rejectionReason,
          rejectionReasonOther,
          ...additionalParams,
        },
        dispatch,
        getState
      );
      dispatch(replySucceeded(data));
    } catch (e) {
      dispatch(
        replyFailed(parseError(e, "There was an error sending your reply."))
      );
    }

    const timeoutID = window.setTimeout(
      () => dispatch(clearAlerts(timeoutID)),
      7000
    );
    dispatch(setAlertTimeoutID(timeoutID));
  };

  return { slice, reply };
};

export default createReplyMadLibsSlice;
