import React from "react";
import { useSelector } from "react-redux";
import { oneLiner, whatYouGet } from "@app/constants/text";
import styles from "./index.module.scss";

const SocialShare = props => {
  const { socialTitle, socialText, socialUrl } = props;
  const userData = useSelector(state => state.userData);
  const user = userData.user || {};
  const twitterText = socialText || `I am loving @elpha, ${oneLiner.toLowerCase()}. Become a member to access ${whatYouGet} Check it out -  `;

  const url = socialUrl || `https://elpha.com/top?modal=invite&code=from-${user.username || 'a-friend'}`;
  const title = socialTitle || "Join Elpha";

  return (
    <div className={styles.socialButtons}>
      <a
        target="_blank"
        href={`https://twitter.com/intent/tweet?text=${twitterText} ${url}`}
      >
        <img className={styles.img} src="https://d2i0px8cen0nqy.cloudfront.net/twitter-circle-lg.png" />
      </a>
      <a
        target="_blank"
        href={`https://www.linkedin.com/sharing/share-offsite/?mini=true&url=${url}`}
      >
        <img className={styles.img} src="https://d2i0px8cen0nqy.cloudfront.net/linkedin-circle-lg.png" />
      </a>
    </div>
  );
};
export default SocialShare;
