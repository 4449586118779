import React from "react";
import { useLocation } from "react-router";
import useParams from "@app/hooks/useParams";
import { DONE_STEP, TOTAL_STEPS } from "./utils";
import { setCompanyRating } from "@app/redux/companyRatings";
import { setActiveModal } from "@app/redux/modals";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "@app/redux/salaryInfo";

const useModalActions = () => {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const queryParamStep = queryParams.get("step");
  const { removeQueryParams, mergeQueryParams } = useParams();
  const goToStep = step => mergeQueryParams({ step });

  const isFirstStep = () => getStep() === 1;
  const isLastStep = () => getStep() === TOTAL_STEPS;
  const isDone = () => queryParamStep === DONE_STEP;

  const getStep = () => (
    isDone() ? DONE_STEP : parseInt(queryParamStep, 10) || 1
  );

  const goToNextStep = () => {
    const step = getStep();
    return goToStep(isDone() || isLastStep() ?
      DONE_STEP : (step + 1));
  };

  const openModal = () => mergeQueryParams({ modal: "rate-company" });

  const closeModal = () => {
    dispatch(setErrorMessage(undefined));
    removeQueryParams(["step", "modal"]);
  };

  const resetModalToLastCompletedStep = (companyRating = {}) => {
    let lastPossibleStep = queryParamStep;
    const step1Required = [
      companyRating.company,
      companyRating.activeEmployee,
      companyRating.rating,
      companyRating.why,
    ];
    if (!companyRating.activeEmployee) {
      step1Required.push(companyRating.lastYearEmployed);
    }

    if (queryParamStep != lastPossibleStep) {
      mergeQueryParams({ step: lastPossibleStep });
    }
  };

  return {
    getStep,
    goToStep,
    closeModal,
    openModal,
    goToNextStep,
    isLastStep,
    isFirstStep,
    isDone,
    resetModalToLastCompletedStep,
  };
};

export default useModalActions;
