import React, { useState, useEffect } from "react";
import { Glyphicon } from "react-bootstrap";
import ReactSelectWrapper from "@app/components/common/ReactSelectWrapper";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { showErrorAlertFromErr } from "@app/redux/alert";
import accountAPI from "@app/redux/account/api";
import classNames from "classnames";

import CustomCheckbox from "@app/components/common/CustomCheckbox";
import Spinner from "@app/components/common/Spinner";
import styles from "./index.module.scss";
import { useMediaQuery } from "react-responsive";

const AnonModal = ({ loading, onHide, show, onClick }) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Body>
      <div className={styles.title}>Would you like to create a new anonymous alias?</div>
      {loading && <Spinner />}
      {!loading && (<>
          <button disabled={loading} onClick={onClick} className={styles.buttonPrimary}>Yes, create one now</button>
          <a className={styles.publicHelp} target="_blank" href="/members/anonymous">
            <Glyphicon glyph="question-sign" />
          </a>
        </>
      )}
    </Modal.Body>
    <Modal.Footer>
      <button className={styles.buttonDefault} onClick={onHide}>Close</button>
    </Modal.Footer>
  </Modal>
);

const AnonField = ({ onChange, anonAuthor, caption }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false);
  const [anonId, setAnonId] = useState(undefined);
  const [anonIds, setAnonIds] = useState([]);
  const [errMsg, setErrMsg] = useState(undefined);
  const [showAnonIds, setShowAnonIds] = useState(!!anonAuthor);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)'});

  const fetchAnonIds = async () => {
    try {
      const { data } = await accountAPI.getAnonIds();
      const { anon_ids } = data;
      const newAnonIds = JSON.parse(JSON.stringify(anon_ids || []));
      newAnonIds.push({ label: "Create a new anon alias", value: "new" });
      const newAnonId = (newAnonIds.filter(newAnonId => newAnonId.label === anonAuthor)[0] || {}).value;
      setAnonIds(newAnonIds);
      setAnonId(newAnonId);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      dispatch(showErrorAlertFromErr(e));
    }
  };

  useEffect(() => {
    fetchAnonIds();
  }, []);

  const handleCreateNewAlterEgo = async () => {
    setLoading(true);
    try {
      const { data } = await accountAPI.createAnonId();
      const newAnonIds = JSON.parse(JSON.stringify(anonIds || []));
      newAnonIds.unshift(data);
      setAnonIds(newAnonIds);
      setAnonId(data.value);
      onChange(data.label);
      setLoading(false);
      setShowModal(false);
    } catch (e) {
      setLoading(false);
      dispatch(showErrorAlertFromErr(e));
    }
  };

  const handleAnonIdChange = (opt) => {
    const anonId = opt.value;
    if (anonId === "new") {
      setShowModal(true);
    } else {
      setAnonId(anonId);
    }
    const selected = anonIds.find(anonEle => anonEle.value === anonId) || {};
    onChange(selected.label);
  };

  const handleAnonChange = () => {
    if (showAnonIds) {
      setAnonId(undefined);
      onChange(undefined);
    } else if (anonIds.length) {
      setAnonId(anonIds[0]);
      onChange(anonIds[0].label);
    }
    setShowAnonIds(!showAnonIds);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className={styles.root}>
      <AnonModal onHide={handleCloseModal} show={showModal} onClick={handleCreateNewAlterEgo} />
      {caption && <div>{caption}</div>}
      <CustomCheckbox
        checked={showAnonIds}
        onChange={handleAnonChange}
        label={
          <span className={classNames(styles.anonBlock, styles.smallText)}>
            <span className={styles.anonText}>{isMobile ? "Anon" : "Anonymous"}</span>
            <a target="_blank" href="/members/anonymous" className={classNames(styles.publicHelp)}>
              <Glyphicon glyph="question-sign" />
            </a>
          </span>
        }
      />
      {showAnonIds && (
        <>
          <span className={styles.annotation}>as{" "}</span>
          <ReactSelectWrapper
            value={anonIds.find((opt) => opt.value === anonId)}
            onChange={handleAnonIdChange}
            options={anonIds}
            isClearable={false}
          />
        </>
      )}
    </div>
  );
}

export default AnonField;
