import React, { useState } from "react"
import $ from "jquery"
import classNames from "classnames";
import { dompurify } from "../utils"
import styles from "./index.module.scss";

const Thumbnail = ({ imgUrl, clazz, alt, children }) => {
  const [imgDisplay, setImgDisplay] = useState(undefined);

  const onImgLoad = ({ target: img }) => {
    const $img = $(img);
    if ($img.width() > $img.height()) {
      setImgDisplay(styles.landscape);
    } else if ($img.width() < $img.height()) {
      setImgDisplay(styles.portrait);
    } else {
      setImgDisplay(styles.square);
    }
  };

  return (
    <div className={classNames(clazz, styles.root)}>
      <img
        src={dompurify.sanitize(imgUrl) || ""}
        alt={`${alt || "thumbnail"}`}
        onLoad={onImgLoad}
        className={classNames(styles.img, imgDisplay)}
      />
      {children}
    </div>
  );
};

export default Thumbnail;
