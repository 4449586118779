import React, { useMemo, useState } from "react";
import createLinkPlugin from "@draft-js-plugins/anchor";
import "@draft-js-plugins/anchor/lib/plugin.css";
import styles from "./index.module.scss";

const useLinkPlugin = () => {
  const linkPlugin = useMemo(() => createLinkPlugin({ theme: styles }), []);
  const { LinkButton } = linkPlugin;
  return {
    linkPlugin,
    LinkButton
  };
};
export default useLinkPlugin;
