/**
 * The `candidate/form` Redux slice handles state specific to the form for
 * saving/updating a candidate. It does not handle state for fetching the
 * candidate itself.
 */
import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";

import Candidate from "@app/types/Candidate";
import candidatesAPI from "./api";
import combineSlices from "@app/helpers/combineSlices";
import { setIsCandidate, logIn } from "../userData";
import parseError from "@app/helpers/parseError";
import { setCandidate } from ".";
import step7 from "./step7";

export interface State {
  errorMessage?: string;
  isSaving: boolean;
}

const initialState: State = {
  errorMessage: undefined,
  isSaving: false,
};

const form = createSlice<State, SliceCaseReducers<State>>({
  name: "candidate/form",
  initialState,
  reducers: {
    setErrorMessage(state, action: PayloadAction<string | undefined>) {
      state.errorMessage = action.payload;
    },
    setIsSaving(state, action: PayloadAction<boolean>) {
      state.isSaving = action.payload;
    },
  },
});

export const { setErrorMessage } = form.actions;

/**
 * @param {object} candidate - The candidate object to save. If it has an `id`
 * property, it will be updated in the database; otherwise, it will be created.
 * @param onSuccess - A callback to call when saving succeeds.
 * @param {boolean} [isFinalStep] - Whether this is the final step of the form. If
 * `true`, an extra param will be sent to the backend to fire off job alerts to
 * the user.
 */
export const saveCandidate = (
  candidate: Candidate,
  onSuccess: Function,
  onError: Function,
  isFinalStep: boolean = false
) => async dispatch => {
  const { setErrorMessage, setIsSaving } = form.actions;
  dispatch(setIsSaving(true));
  dispatch(setErrorMessage(undefined));
  try {
    const { data } = await candidatesAPI.saveCandidate(candidate, isFinalStep);
    dispatch(setCandidate(data.candidate));
    if (data.user) dispatch(logIn(data.user, "candidate"));
    dispatch(setIsCandidate());
    onSuccess(data.filters, data.pos_match_count);
  } catch (e) {
    const message = parseError(e, "There was an error saving your information. Please try again.");
    dispatch(setErrorMessage(message));
    onError(message);
  }
  dispatch(setIsSaving(false));
};

export default combineSlices(form.reducer, initialState, {
  step7,
});
