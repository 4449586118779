const { createSlice } = require("@reduxjs/toolkit");

import combineSlices from "@app/helpers/combineSlices";
import form from "./form";
import hiringAPI from "../api";
import { showErrorAlertFromErr } from "@app/redux/alert";

const initialState = {
  items: [],
  isFetching: false,
  hasFetched: false,
};

const messageTemplates = createSlice({
  name: "hiring/messageTemplates",
  initialState,
  reducers: {
    setItems(state, action) {
      state.items = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setHasFetched(state, action) {
      state.hasFetched = action.payload;
    },
  },
});

export const fetchMessageTemplates = () => async dispatch => {
  const { setItems, setIsFetching, setHasFetched } = messageTemplates.actions;

  dispatch(setIsFetching(true));

  try {
    const { data } = await hiringAPI.fetchMessageTemplates();
    dispatch(setItems(data));
    dispatch(setHasFetched(true));
  } catch (e) {
    dispatch(
      showErrorAlertFromErr(
        e,
        "There was an error loading your message templates."
      )
    );
  }

  dispatch(setIsFetching(false));
};

/**
 * @param {number} id - The ID of the message template to delete.
 */
export const deleteMessageTemplate = id => async (dispatch, getState) => {
  const { setItems, setIsFetching, setHasFetched } = messageTemplates.actions;

  dispatch(setIsFetching(true));

  try {
    await hiringAPI.deleteMessageTemplate(id);
    const filteredMessageTemplates = getState().hiring.messageTemplates.items.filter(
      messageTemplate => messageTemplate.id !== id
    );
    dispatch(setItems(filteredMessageTemplates));
    dispatch(setHasFetched(true));
  } catch (e) {
    dispatch(
      showErrorAlertFromErr(
        e,
        "There was an error deleting that message template."
      )
    );
  }

  dispatch(setIsFetching(false));
};

/**
 * Set a message template as the default.
 *
 * @param {number} id - The ID of the message template to set as the default.
 */
export const makeMessageTemplateDefault = id => async dispatch => {
  const { setItems, setIsFetching, setHasFetched } = messageTemplates.actions;

  dispatch(setIsFetching(true));

  try {
    await hiringAPI.makeMessageTemplateDefault(id);
    const { data } = await hiringAPI.fetchMessageTemplates();
    dispatch(setItems(data));
    dispatch(setHasFetched(true));
  } catch (e) {
    dispatch(
      showErrorAlertFromErr(
        e,
        "There was an error making that message template the default."
      )
    );
  }

  dispatch(setIsFetching(false));
};

export default combineSlices(messageTemplates.reducer, initialState, { form });
