import { createSlice } from "@reduxjs/toolkit";

import postAPI from "./api";
import { showErrorAlertFromErr } from "../alert";

const initialState = {
  singlePost: {
    path: undefined,
    slug: undefined,
    total_comments: 0,
    rxn_summary: {},
  },
  isPostLoaded: false,
  isSaving: false,
};

const post = createSlice({
  name: "post",
  initialState,
  reducers: {
    setPost(state, action) {
      state.singlePost = action.payload;
      state.isPostLoaded = true;
    },
    setIsSaving(state, action) {
      state.isSaving = action.payload;
    },
    incTotalComments(state, action) {
      state.singlePost.total_comments++;
      return state;
    },
    decTotalComments(state, action) {
      state.singlePost.total_comments = state.singlePost.total_comments - action.payload;
      return state;
    }
  },
});

export const {
  decTotalComments,
  incTotalComments,
  setPost,
} = post.actions;

export const deletePost = () => async (dispatch, getState) => {
  const { path, slug } = getState().post.singlePost;
  const { setIsSaving } = post.actions;
  try {
    dispatch(setIsSaving(true));
    await postAPI.deletePost(path, slug);
    dispatch(setIsSaving(false));
  } catch (e) {
    dispatch(setIsSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const markSpam = () => async (dispatch, getState) => {
  const { path, slug } = getState().post.singlePost;
  const { setIsSaving } = post.actions;
  try {
    dispatch(setIsSaving(true));
    await postAPI.markSpam(path, slug);
    const newPost = { ...getState().post.singlePost };
    newPost.sp = true;
    dispatch(setPost(newPost));
    dispatch(setIsSaving(false));
  } catch (e) {
    dispatch(setIsSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const createReaction = (unicode, shortcode) => async (dispatch, getState) => {
  const { path, slug } = getState().post.singlePost;
  const { setPost } = post.actions;
  try {
    const { data } = await postAPI.createReaction(path, slug, unicode, shortcode);
    const newPost = { ...getState().post.singlePost };
    newPost.rxn_summary = data.rxn_summary;
    dispatch(setPost(newPost));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export const deleteReaction = (unicode) => async (dispatch, getState) => {
  const { path, slug } = getState().post.singlePost;
  const { setPost } = post.actions;
  try {
    const { data } = await postAPI.deleteReaction(path, slug, unicode);
    const newPost = { ...getState().post.singlePost };
    newPost.rxn_summary = data.rxn_summary;
    dispatch(setPost(newPost));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export default post.reducer;
