/**
 * This slice is specific to step 7 of the multistep candidate modal form.
 */
import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";

import Candidate from "@app/types/Candidate";
import candidatesAPI from "./api";
import parseError from "@app/helpers/parseError";
import { setErrorMessage } from "./form";

type EntryMethod = "manual" | "uploadResume" | "linkedIn";

// https://www.rahulsivalenka.dev/typescript-deep-partial/
type DeepPartial<T> = {
  [propertyKey in keyof T]?: DeepPartial<T[propertyKey]>;
};

export interface State {
  entryMethod?: EntryMethod;
  /**
   * Used when the user uploads their resume for parsing.
   */
  isParsingResume: boolean;
  parsedResume?: DeepPartial<Candidate>;
}

const step7 = createSlice<State, SliceCaseReducers<State>>({
  name: "candidate/form/step7",
  initialState: {
    entryMethod: undefined,
    isParsingResume: false,
    parsedResume: undefined,
  },
  reducers: {
    setEntryMethod(state, action: PayloadAction<EntryMethod | undefined>) {
      state.entryMethod = action.payload;
    },
    setIsParsingResume(state, action: PayloadAction<boolean>) {
      state.isParsingResume = action.payload;
    },
    setParsedResume(state, action: PayloadAction<DeepPartial<Candidate>>) {
      state.parsedResume = action.payload;
    },
  },
});

export const { setEntryMethod, setParsedResume } = step7.actions;

export const parseResume = (
  fileName: string,
  resumeBase64: string,
  lastModified: Date
) => async dispatch => {
  const { setIsParsingResume, setParsedResume } = step7.actions;

  dispatch(setIsParsingResume(true));
  dispatch(setErrorMessage(undefined));

  try {
    const { data } = await candidatesAPI.parseResume(
      fileName,
      resumeBase64,
      lastModified
    );
    dispatch(setParsedResume(data));
  } catch (e) {
    const message = parseError(
      e,
      "There was a problem parsing your resume. Please try again or email support@elpha.com for help."
    );
    dispatch(setErrorMessage(message));
  }

  dispatch(setIsParsingResume(false));
};

export default step7.reducer;
