import React from "react";
import classNames from "classnames"; import { Glyphicon } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleMobileSidebar } from "@app/redux/sidebar";
import { togglePendingReminderModal } from "@app/redux/modals";
import Thumbnail from "@app/components/common/Thumbnail";
import { dompurify } from "@app/components/common/utils";
import { isCompaniesPage } from "@app/helpers/navbarHelper";
import SuccessStoriesWidget from "../../SuccessStoriesWidget";
import MobileButtonGroup from "../../MobileButtonGroup";
import styles from "./index.module.scss";

const MobileSideBar = ({ communitiesData, actionsData, loading }) => {
  const dispatch = useDispatch();
  const active = useSelector(state => state.userData.active);
  const activeSidebar = useSelector(state => state.sidebar.active);

  const showMobileLeftSideBar = activeSidebar === "mobile";

  const handleClick = link => window.location = link;

  const handleGatedClick = link => {
    if (approved) {
      handleClick(link);
    } else {
      dispatch(togglePendingReminderModal());
    }
  };

  const firstData = [
    {
      index: "rate",
      glyph: "star",
      caption: "Company Ratings Database",
      active: active === "logged_out-top_companies_database",
      onClick: () => handleClick("/top-companies-database")
    },
    {
      index: "salary",
      glyph: "usd",
      caption: "Salary Database",
      active: active === "logged_out-salary_database",
      onClick: () => handleClick("/salary-database")
    },
    {
      index: "jobs",
      glyph: "flash",
      caption: "Explore jobs",
      active: active === "positions-index",
      onClick: () => handleClick("/jobs")
    },
    {
      index: "for-companies",
      glyph: "briefcase",
      caption: "For employers",
      active: active === "companies-partnerships",
      onClick: () => handleClick("/companies/partnerships")
    },
  ];
  const lastData = [
    {
      index: "faq",
      caption: "FAQ",
      glyph: "question-sign",
      active: active === "faq",
      onClick: () => handleClick("/faq")
    }
  ];
  return (
    <div className={classNames(styles.root, {
        [styles.slide]: showMobileLeftSideBar
      })}
    >
      <div className={styles.mobileSideBarSubheader}>
        <span className={styles.close} onClick={() => dispatch(toggleMobileSidebar())}>
          <Glyphicon glyph="remove" />
        </span>
      </div>
      <div className={styles.mobileBody}>
        <MobileButtonGroup buttons={firstData} loading={false} />
        {actionsData && <MobileButtonGroup buttons={actionsData} loading={false} />}
        <SuccessStoriesWidget mobile={true} />
        {communitiesData && <div className={styles.mobileSideBarSubheader}>Your communities</div>}
        {communitiesData && <MobileButtonGroup buttons={communitiesData} loading={loading} />}
        {!!lastData.length && <div className={styles.mobileSideBarSubheader}>More</div>}
        {!!lastData.length && <MobileButtonGroup buttons={lastData} loading={false} />}
      </div>
    </div>
  );
};
export default MobileSideBar;
