import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router";

import { clearActiveModal, setActiveModal } from "@app/redux/modals";

/**
 * A component with one job: to listen for changes to the `modal` query param,
 * and to show/hide modals accordingly.
 *
 * To use query param functionality in your modals, set the `modal` query param
 * to the name of the modal you want to open, or remove the `modal` query param
 * to close the modal. Then use the `state.modals.active` property to check
 * whether your modal should be open.
 *
 * @example
 * const MyModal = () => {
 *   const show = useSelector(state => state.modals.active === "my-modal");
 *   const location = useLocation();
 *   const history = useHistory();
 *
 *   const queryParams = new URLSearchParams(location.search);
 *
 *   const handleHide = () => {
 *     if (queryParams.has("modal")) {
 *       queryParams.delete("modal");
 *       history.push({ search: queryParams.toString() });
 *     }
 *   };
 *
 *   return (
 *     <Modal show={show} onHide={handleHide}>
 *       ...
 *     </Modal>
 *   );
 * };
 */
const ModalQueryParamHandler = () => {
  const dispatch = useDispatch();
  const activeModal = useSelector(state => state.modals.active);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const modalQueryParam = queryParams.get("modal");

  useEffect(() => {
    if (modalQueryParam && modalQueryParam !== activeModal) {
      dispatch(setActiveModal({ active: modalQueryParam }));
    } else if (activeModal && !modalQueryParam) {
      dispatch(clearActiveModal());
    }
  }, [modalQueryParam]);

  return null;
};

export default ModalQueryParamHandler;
