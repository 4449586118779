import React, { useContext } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import FeaturedCompaniesWrapper from "@app/components/common/FeaturedCompaniesWrapper";
import useCandidateModal from "@app/hooks/useCandidateModal";
import FeaturedCompany from "./FeaturedCompany";
import styles from "./index.module.scss";

const FeaturedCompaniesList = ({ companies, header }) => {
  const { openCandidateModal } = useCandidateModal();
  const isCandidate = useSelector(state => (state.userData.user || {}).is_candidate);

  const handleClick = () => {
    openCandidateModal({
      source: "featured-companies-list",
    });
  };
  return (
    <>
      {header && <div className={styles.sideBarSubheader}>elpha companies</div>}
      {
        companies.map((co, index) => (
          <FeaturedCompany
            key={`fc-${index}`}
            company={co}
          />
        ))
      }
      {!isCandidate && <a href="#" className={classNames(styles.buttonPrimary, styles.button)} onClick={handleClick}>Join the Talent Pool</a>}
      {isCandidate && <a href="/companies" className={classNames(styles.buttonPrimary, styles.button)}>Browse companies</a>}
    </>
  );
};

const FeaturedCompanies = ({ divider, first, header }) => {
  return (
    <div>
      {divider && !first && <hr className={styles.hr} />}
      <FeaturedCompaniesWrapper>
        <FeaturedCompaniesList header={header} />
      </FeaturedCompaniesWrapper>
      {divider && first && <hr className={styles.bottom} />}
    </div>
  );
};
export default FeaturedCompanies;
