/**
 * PortalHandler is a component that is loaded simply to scan the page on every
 * pageload and detect any DOM nodes with a `data-react-portal-component-name`
 * attribute.  If any such nodes exist, it asynchronously loads a component
 * that has the same name as the value of the
 * `data-react-portal-component-name` attribute, and then uses a React portal
 * (https://reactjs.org/docs/portals.html) to insert the component into the
 * given node.  If the nodes also have a `data-react-portal-component-props`
 * attribute, it parses the value of that attribute as JSON, then passes the
 * JSON as props to the loaded component.
 *
 * To create additional components to be used in portals, add them to the
 * `app/javascript/new_app/components/common/portals` directory, then register
 * them in `app/javascript/new_app/components/common/portals/components.js`.
 */
import { createPortal } from "react-dom";
import React, { Suspense, useState, useEffect, useMemo } from "react";

import COMPONENTS from "./portals/components";
import { lazyLoadFallbackUI } from "../../helpers/lazyLoadHelper";

const PortalHandler = () => {
  const [domNodes, setDomNodes] = useState([]);
  useEffect(() => {
    const DOM_NODES = Array.from(
      document.querySelectorAll("[data-react-portal-component-name]")
    );
    setDomNodes(DOM_NODES);
  }, []);

  const DOM_NODES_AND_COMPONENTS = useMemo(() => domNodes.map(node => {
    return {
      Component: COMPONENTS[node.dataset.reactPortalComponentName],
      props: node.dataset.reactPortalComponentProps
        ? JSON.parse(node.dataset.reactPortalComponentProps)
        : {},
      node,
    }
  }).filter(item => !!item.Component), [domNodes]);

  return (
    <>
    {DOM_NODES_AND_COMPONENTS.map(({ Component, props, node }, index) => {
      return (
        <SinglePortal domNode={node} key={index}>
          <Suspense fallback={lazyLoadFallbackUI}>
            <Component {...props} />
          </Suspense>
        </SinglePortal>
      )
    })}
  </>
  );
};

const SinglePortal = ({ children, domNode }) => {
  return createPortal(children, domNode);
};

export default PortalHandler;
