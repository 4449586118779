import React, { useEffect } from "react";
import $ from "jquery";
import ShareTopCompaniesDatabase from "@app/components/common/ShareTopCompaniesDatabase";
import { Button } from "react-bootstrap";
import useModalActions from "@app/components/common/AddToTopCompaniesDatabase/useModalActions";
import { useDispatch } from "react-redux";
import { clearActiveModal } from "@app/redux/modals";
import { setCompanyRating } from "@app/redux/companyRatings";
import { setErrorMessage } from "@app/redux/companyRatings/form";

const StepThankYou = () => {

  const { closeModal } = useModalActions();
  const dispatch = useDispatch();

  useEffect(() => {
    $(".modal-open .modal").animate({ scrollTop: 0 }, "fast");
  }, []);

  const handleClose = e => {
    dispatch(setCompanyRating({}));
    dispatch(setErrorMessage(""));
    dispatch(clearActiveModal());
    closeModal();
  }

  return (
    <div>
      <ShareTopCompaniesDatabase/>
      <Button className="pull-right btn btn-secondary" onClick={handleClose}>Close</Button>
    </div>
  )
};

export default StepThankYou;
