/**
 * Use this to parse error objects from API responses. Optionally provide a
 * default message to show if no message can be parsed.
 *
 * @param {object} error - The error you caught when calling the API inside a
 * try/catch.
 * @param {string} [defaultMessage] - The message to display if no message can
 * be parsed from the error object.
 */
const parseError = (
  error,
  defaultMessage = "Something went wrong! Please contact support@elpha.com for help."
) => {
  if (error && error.responseJSON && error.responseJSON.errors && error.responseJSON.errors.length > 0) {
    return error.responseJSON.errors.join(". ");
  }
  if (error && error.response && error.response.body && error.response.body.errors && error.response.body.errors.length > 0) {
    return error.response.body.errors;
  }
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errors &&
    error.response.data.errors.length > 0
  ) {
    return error.response.data.errors.join(" ");
  }
  if (error && error.errorMsg) {
    return error.errorMsg;
  }
  return defaultMessage;
};

export default parseError;
