import React from "react";
import { useSelector } from "react-redux";
import communityAPI from "@app/redux/community/api";
import PremiumCommunitySideBar from "@app/components/SideBars/LeftSideBar/LoggedIn/PremiumCommunity";
import TodoChecklistWidget from "../../TodoChecklistWidget";
import FeaturedCompanies from "../../FeaturedCompanies";
import SuccessStoriesWidget from "../../SuccessStoriesWidget";
import CommunityButtons from "./../CommunityButtons";
import CommunityActions from "./../CommunityActions";
import styles from "./index.module.scss";

const StandardCommunitySideBar = () => (
  <>
    <FeaturedCompanies first divider header />
    <SuccessStoriesWidget mobile={false} />
    <TodoChecklistWidget />
    <CommunityActions isDesktop={true} />
    <CommunityButtons isDesktop={true} />
  </>
);

const DesktopSideBar = ({ actionsData }) => {
  const isPremiumCommunity = useSelector(state => !!(state.userData.community || {}).is_premium);
  return (
    <div className={styles.root}>
      <div className="side-bar-container">
        <div className="side-bar-body-container">
          {!isPremiumCommunity && <StandardCommunitySideBar />}
          {isPremiumCommunity && <PremiumCommunitySideBar />}
        </div>
      </div>
    </div>
  );
};
export default DesktopSideBar;
