import React, { useMemo, useState } from "react";

import createMentionPlugin from "@draft-js-plugins/mention";

const useMentionPlugin = () => {
  const mentionPlugin = useMemo(() => createMentionPlugin(), []);
  const { MentionSuggestions } = mentionPlugin;
  return {
    mentionPlugin,
    MentionSuggestions
  };
};
export default useMentionPlugin;
