import React, { useState } from "react";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { showErrorAlertFromErr } from "@app/redux/alert";

const Contact = ({ onInvite, contact }) => {
  const dispatch = useDispatch();
  const names = (contact.names || []).map((n) => n.given_name && n.family_name ? [n.given_name, n.family_name].join(" ") : n.display_name);
  const emails = (contact.emails || []).map((e) => e.value);
  const [invite, setInvite] = useState(false);

  const handleInviteClick = (e) => {
    e && e.preventDefault();
    onInvite && onInvite(emails[0]);
    setInvite(!invite);
  };

  const renderAction = () => {
    if (contact.member) {
      return <a className="invite-contact-btn pull-right btn btn-text-only" href={`/members/${contact.member}`}>Connect</a>;
    }
    return <button onClick={(e) => handleInviteClick(e)} className="invite-contact-btn pull-right btn btn-text-only">{invite ? "✅" : "Invite"}</button>;
  };

  return <div className="bordered-container google-contact">{names[0] || emails[0]} {renderAction()}</div>;
}

export default Contact;
