import { Alert, Button, Modal } from "react-bootstrap";
import React from "react";

import useModalActions from "./useModalActions";
import styles from "../CandidateModal/Step.module.scss";
import { useSelector } from "react-redux";

/**
 * A wrapper component to be used for each of the salary database modal form steps.
 * Takes care of the modal header and footer.
 *
 * @param {object} props
 * @param {string} props.title - The title of this step.
 * @param {string} props.subtitle - The subtitle of this step.
 */
const Step = ({ subtitle, title, children }) => {
  const {
    isDone,
    closeModal
  } = useModalActions();
  const errorMessage = useSelector(state => state.companyRatings.form.errorMessage);
  const isSaving = useSelector(state => state.companyRatings.form.isSaving);

  return (
    <>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title componentClass="h3" className={styles.title}>
          {title}
          <div className={styles.subtitle}>{subtitle}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        {errorMessage && <Alert bsStyle="danger">{errorMessage}</Alert>}

        {children}
      </Modal.Body>
      <Modal.Footer>
        {isDone() ? (
          <Button bsStyle="primary" onClick={closeModal}>
            Done
          </Button>
        ) : (
          <Button bsStyle="primary" type="submit" disabled={isSaving}>
            {isSaving ? "Saving..." : "Submit!"}
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

export default Step;
