import React from "react"

export const raceEthnicityOptions = [
  {
    value: "indigenous",
    label: "Indigenous Peoples, American Indian or Alaska Native",
  },
  { value: "asian", label: "Asian" },
  { value: "black", label: "Black or African American" },
  { value: "latinx", label: "Hispanic or Latinx" },
  { value: "middle_eastern", label: "Middle Eastern or North African" },
  {
    value: "pacific_islander",
    label: "Native Hawaiian or Other Pacific Islander",
  },
  { value: "white", label: "White / Caucasian" },
  {
    value: "not_listed",
    label: "I identify with a race or ethnicity that is not listed",
  },
  {
    value: "prefer_no_answer",
    label: "I prefer not to answer",
  },
];

export const sexOptions = [
  { value: "asexual", label: "Asexual" },
  { value: "bisexual", label: "Bisexual" },
  { value: "gay", label: "Gay" },
  { value: "straight", label: "Straight" },
  { value: "pansexual", label: "Pansexual" },
  { value: "not_listed", label: "An identity not listed" },
  { value: "prefer_no_answer", label: "I prefer not to answer" },
]

export const educationOptions = [
  { value: "no_school", label: "No schooling completed" },
  { value: "8_grade", label: "8th grade" },
  { value: "some_highschool", label: "Some high school" },
  { value: "highschool", label: "High school graduate, diploma or equivalent (for example: GED)" },
  { value: "some_college", label: "Some college" },
  { value: "vocational", label: "Trade / technical / vocational training" },
  { value: "associate", label: "Associate degree" },
  { value: "bachelor", label: "Bachelor's degree" },
  { value: "master", label: "Master's degree" },
  { value: "professional", label: "Professional degree" },
  { value: "doctorate", label: "Doctorate degree" },
]

export const genderOptions = [
  { value: "cis_woman", label: "Cis woman" },
  { value: "trans_woman", label: "Trans woman" },
  { value: "nonbinary", label: "Nonbinary" },
  { value: "genderqueer", label: "Genderqueer" },
  { value: "agender", label: "Agender" },
  { value: "cis_man", label: "Cis man" },
  { value: "trans_man", label: "Trans man" },
  { value: "not_listed", label: "An identity not listed" },
  { value: "prefer_no_answer", label: "I prefer not to answer" },
];

export const currentEmploymentStatusOpts = [
  { value: "employed_fulltime", label: "Employed, full time" },
  { value: "employed_parttime", label: "Employed, part time" },
  { value: "self_employed", label: "Self-employed / Freelancer" },
  { value: "actively_looking", label: "Out of work and actively applying for work" },
  { value: "not_looking", label: "Out of work but not currently looking for work" },
  { value: "student", label: "Student" },
  { value: "military", label: "Military" },
  { value: "retired", label: "Retired" },
]

export const jobStatusSummaryOpts = [
  { value: "actively_looking", label: "Actively looking for a job" },
  { value: "passively_looking", label: "Open to opportunities, but not actively looking" },
  { value: "not_looking", label: "Not looking for a job" },
];

export const jobStatusWithoutNotOpts = [
  { value: "part_time", label: "Looking for a part time job" },
  { value: "actively_looking", label: "Actively looking for a job" },
  { value: "passively_looking", label: "Casually exploring my options" },
  { value: "dream", label: "Happy but open to dream job" },
  { value: "freelance", label: "Looking for freelance / contract work" },
  { value: "internship", label: "Looking for an internship / apprenticeship" },
];

export const jobStatusWithNotOpts = jobStatusWithoutNotOpts.concat([{ value: "not_looking", label: "I'm not looking" }]);

export const yesNoOpts = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
]
export const benefitList = [
  "Reproductive Autonomy Benefits",
  "Flexible work schedule",
  "Unlimited vacation",
  "Relocation assistance",
  "100% healthcare coverage",
  "Healthcare coverage for dependents",
  "Parental leave",
  "Maternity and paternity leave",
  "Offer 12+ weeks of maternity leave",
  "Childcare benefits",
  "Lactation room at the office",
  "Fertility benefits",
  "US visa support",
  "Employee code of conduct",
  "Fully remote team",
  "401(k) with matching",
  "Learning and development stipend",
  "Mental health support services"
];
export const benefitOpts = benefitList.map(value => {
  return { value: value, label: value };
});
export const careerDevList = [
  "Encourage professional development ",
  "Ensure managers conduct regular 1 on 1s with their reports",
  "Offer internal mobility between teams",
  "Emphasize promoting from within",
  "Provide clear requirements for promotion",
  "Conduct annual performance reviews",
  "Provide clear path for career growth",
  "Opportunities to give and receive mentorship",
];
export const careerDevOpts = careerDevList.map(value => {
  return { value: value, label: value };
});
export const managementList = [
  "Ensure managers receive management training",
  "Committed to culture of feedback / radical candor",
  "Ensure managers receive 360 reviews every year",
  "Committed to a culture of transparency",
  "Dedicated HR team",
];
export const managementOpts = managementList.map(value => {
  return { value: value, label: value };
});
export const deiList = [
  "Team is diverse",
  "CEO prioritizes DEI",
  "Team puts budget toward DEI initiatives",
  "Company has ERGs",
  "C suite is diverse",
];
export const deiOpts = deiList.map(value => {
  return { value: value, label: value };
});
export const workLifeList = [
  "Rarely work weekends",
  "Encourage team to take vacations",
  "Rarely work much later than 6pm",
  "Allow team members to set their schedules",
];
export const workLifeOpts = workLifeList.map(value => {
  return { value: value, label: value };
});
export const workStyleList = [
  "Emphasize cross team communication and collaboration",
  "Encourage everyone to voice their opinions",
  "Prioritize ensuring everyone feels valued and respected",
  "Prioritize psychological safety",
  "Reward risk taking and learning from mistakes",
  "Encourage autonomy",
  "Value a flat team structure",
  "Driven by the mission",
  "Emphasize data driven decision making",
  "Emphasize building fast; prioritize speed over process",
  "Emphasize delivering a high quality product; Don’t ship it until it really works",
  "Focus on profitability and building a sustainable business",
];
export const workStyleOpts = workStyleList.map(value => {
  return { value: value, label: value };
});
export const compList = [
  "Have a stated compensation philosophy",
  "Includes salary range in job descriptions",
  "Review compensation based on performance",
  "Review compensation on company wide cycles only",
];
export const compOpts = compList.map(value => {
  return { value: value, label: value };
});
export const engList = [
  "Focusing on hard technical problems",
  "Emphasis on product focused engineering",
  "Building software for scale",
  "Emphasis on high quality code base",
  "Builds with modern languages & frameworks",
  "At least 2x no meetings days per week",
  "Invests in automated testing and continuous delivery",
  "Offers engineering mentorship",
];
export const engOpts = engList.map(value => {
  return { value: value, label: value };
});
export const deptToIdentities = (department) => {
  if (department === "software_engineering") {
    return ["software_engineer"];
  } else if (department === "operations") {
    return ["operations"];
  } else if (department === "data") {
    return ["data_scientist"];
  } else if (department === "design") {
    return ["designer"];
  } else if (department === "marketing") {
    return ["marketer"];
  } else if (department === "sales_and_account_management") {
    return ["in_sales"];
  } else if (department === "people_and_talent") {
    return ["people_ops", "in_hr"];
  } else if (department === "product") {
    return ["in_product"];
  }
  return [];
};

const specialties = {
  "DATA" : ["Data Analysis", "Data Engineering", "Data Science", "Research", "Business Analysis"],
  "DESIGN" : ["Product Design", "Graphic Design", "UX/UI Design", "Visual Design", "Brand Design", "UX / UI Research", "Research", "UI Engineering", "Design Generalist"],
  "MARKETING" : ["Growth", "Social Media", "Content", "Product Marketing", "PR / Comms", "Marketing Generalist", "Brand Marketing"],
  "OPERATIONS" : ["Product Operations", "Sales Operations", "Operations Generalist", "Chief of Staff", "Exec Admin", "Legal", "Finance", "Customer Support", "Program / Project Management", "General Manager", "Office Management", "Community Management"],
  "PEOPLE_AND_TALENT" : ["Business Recruiting", "Technical Recruiting", "Sourcing", "Human Resources", "People Ops", "Recruiting Generalist"],
  "PRODUCT" : ["Product Management", "UX / UI Research", "Product Marketing", "Product Analysis"],
  "SALES_AND_ACCOUNT_MANAGEMENT" : ["Sales Development Representative", "Sales Management", "Account Executive", "Account Management", "Customer Success", "Customer Support", "Sales Engineering", "Solutions Engineering", "Business Development", "Partnerships", "Sales Enablement", "Sales Generalist", "Sales Operations"],
  "SOFTWARE_ENGINEERING" : ["Full Stack Engineering", "Frontend Engineering", "Backend Engineering", "Engineering Management", "QA", "DevOps", "Developer Advocate", "Mobile Engineering", "Security Engineering", "Support Engineering", "Sales Engineering", "Machine Learning Engineering", "Systems Engineering", "Solutions Engineering", "Infrastructure Engineering", "Data Engineering", "System Administration / IT"],
  "MORE" : ["Hardware Engineering", "Mechanical Engineering", "Education", "Healthcare", "Community Management", "Something else"]
};

export const specialtiesOpts = department => {
  if (department) {
    return specialties[department.toUpperCase()].map(speciality => {
      return { value: speciality, label: speciality };
    });
  }
  return null;
};

export const deptsOpts = [
  { value: "data", label: "Data" },
  { value: "design", label: "Design" },
  { value: "marketing", label: "Marketing" },
  { value: "operations", label: "Operations" },
  { value: "people_and_talent", label: "People & Talent" },
  { value: "product", label: "Product" },
  { value: "sales_and_account_management", label: "Sales & Account Management" },
  { value: "software_engineering", label: "Software Engineering" },
  { value: "more", label: "Something else" },
]

export const groupedSpecialtiesOpts = (
  deptsOpts.map(opt => {
    const deptSpecialties = specialtiesOpts(opt.value);
    return {
      label: opt.label,
      options: deptSpecialties
    }
  })
);

export const allSpecialtiesOpts = () => {
  const allSpecialtiesOpts = groupedSpecialtiesOpts.map(group => group.options).flat();
  return [...new Map(allSpecialtiesOpts.map(item => [item.value, item])).values()];
};

export const seniorityOpts = [
  { value: "Just starting out", label: "Just starting out" },
  { value: "Early career", label: "Early career" },
  { value: "Mid career", label: "Mid career" },
  { value: "Senior career", label: "Senior career" },
  { value: "Executive", label: "Executive" },
]
export const techPreferencesOpts = [
  { value: "Python", label: "Python" },
  { value: "React", label: "React" },
  { value: "NodeJS", label: "NodeJS" },
  { value: "Typescript", label: "Typescript" },
  { value: "Golang", label: "Golang" },
  { value: "Java", label: "Java" },
  { value: "C/C++", label: "C/C++" },
  { value: "Ruby/Rails", label: "Ruby/Rails" },
  { value: "Kotlin", label: "Kotlin" },
  { value: "Swift", label: "Swift" },
  { value: "C#", label: "C#" },
  { value: "Perl", label: "Perl" },
  { value: "PHP", label: "PHP" },
  { value: "Rust", label: "Rust" },
]
export const experienceOpts = [
  { value: "early", label: "Starting out" },
  { value: "_1_3", label: "1-3 years" },
  { value: "_4_9", label: "4-9 years" },
  { value: "_10_15", label: "10-15 years" },
  { value: "_16_plus", label: "16+ years" },
]

export const roundsOpts = [
  { value: "seed", label: "Seed" },
  { value: "series_a", label: "Series A" },
  { value: "series_b", label: "Series B" },
  { value: "series_c", label: "Series C" },
  { value: "series_d", label: "Series D" },
  { value: "series_e", label: "Series E" },
  { value: "other", label: "Other" },
]

export const companyTypeOpts = [
  { value: "early_stage", label: "An early-stage startup (Seed or earlier)" },
  { value: "growth_stage", label: "Growth-stage startup (Series A or later)" },
  { value: "publicly_traded", label: "Publicly traded" },
  { value: "private", label: "A company that is not venture-backed" },
  { value: "consultancy", label: "A consultancy" },
  { value: "partnership", label: "A partnership" },
  { value: "not_employed", label: "Not currently employed" },
  { value: "other", label: "Other" },
];

const companySizesList = [
  "No preference / open to all",
  "1-10",
  "11-50",
  "51-200",
  "201-500",
  "501-1000",
  "1001 plus",
]
export const companySizesOpts = companySizesList.map(companySize => {
  return { value: companySize, label: companySize }
})
export const companySizesOptsV2 = [
  { label: "1-10", value: "_1_10" },
  { label: "11-50", value: "_11_50" },
  { label: "51-200", value: "_51_200" },
  { label: "201-500", value: "_201_500" },
  { label: "501-1000", value: "_501_1000" },
  { label: "1001+", value: "_1001_plus" },
]
const levelList = [
  "Not sure",
  "Individual contributor",
  "Team lead",
  "People manager",
  "VP / Exec level",
]
export const levelOpts = levelList.map(level => {
  return { value: level, label: level }
})
const locationList = [
  "San Francisco Bay Area",
  "New York City",
  "Seattle",
  "Anywhere in the US",
  "Other (please list below)",
]
export const locationOpts = locationList.map(loc => {
  return { value: loc, label: loc }
})

export const isResetableFilter = filter => {
  return filter.length;
}

export const isResetableRangeFilter = filter => {
  return filter.length === 2 && filter[0] !== "0" && filter[1] !== "0"
}

export const isResetableFilters = filters => {
  return Object.keys(filters).filter(key => isResetableFilter(filters[key]) || isResetableRangeFilter(filters[key])).length > 0;
}

export const checkedCount = filter => {
  return filter.length
}

export const checkedCountHtml = filter => {
  const count = checkedCount(filter)
  if (count) {
    return <span>({count})</span>
  }
  return null
}

export const getNormalizedFilters = (params = {}) => ({
  cultures: params.cultures || [],
  sectors: params.sectors || [],
  team_sizes: params.team_sizes || [],
  funding: params.funding || [],
  locations: params.locations || [],
  women_in_leadership: params.women_in_leadership || [],
  search_string: params.search_string || "",
  specialties: params.specialties || [],
  departments: params.departments || [],
  seniorities: params.seniorities || [],
  sort: params.sort || "newest"
});

export const resetFilters = (filterNameToReset, filters) => {
  const defaultFilters = getNormalizedFilters();

  if (filterNameToReset) {
    return {
      ...filters,
      [filterNameToReset]: defaultFilters[filterNameToReset],
    };
  }

  return defaultFilters;
};

export const filterParams = (filters) => {
  const keysToKeep = Object.keys(filters).filter(key => {
    const isFalsey = !filters[key];
    const isEmptyArrayOrStringFilter = (Array.isArray(filters[key]) || typeof filters[key] === "string") && !filters[key].length;

    return !(isFalsey || isEmptyArrayOrStringFilter);
  });

  const newFilters = {}
  keysToKeep.forEach(key => {
    newFilters[key] = filters[key]
  });
  return newFilters;
};
