import React, { useState, useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import $ from "jquery";
import { fetchUserData } from "@app/redux/userData";
import { initializeCommunity } from "@app/redux/community";
import CandidateModal from "@app/components/common/CandidateModal";
import useParams from "@app/hooks/useParams";
import TopCompaniesDatabaseModal from "@app/components/common/AddToTopCompaniesDatabase/Modal"
import Navbar from "./Navbar";
import Content from "./Content/Content";
import Alert from "../redux/alert/Alert";
import {
  setActiveModal,
  togglePendingReminderModal,
} from "@app/redux/modals";
import { showErrorAlert, showSuccessAlert, showWarningAlert, showInfoAlert } from "@app/redux/alert";
import { isOnboardingPage } from "../helpers/sidebarHelper";
import { setupAjaxCSRF } from "../helpers/ajaxHelper";
import { lazyLoadFallbackUI } from "../helpers/lazyLoadHelper";
import Spinner from "./common/Spinner";
import AcceptInviteModal from "./Register/AcceptInviteModal";
import PendingReminderModal from "./common/PendingReminderModal";
import InvitationModal from "@app/components/pages/Invitations/Modal";

import SignInModal from "./Register/SignInModal";
import JoinModal from "./Register/JoinModal/index";
import SpecialSignUpModal from "./Register/SpecialSignUpModal";
import ModalQueryParamHandler from "./common/ModalQueryParamHandler";
import PortalHandler from "./common/PortalHandler";
import SetPasswordModal from "@app/components/common/SetPasswordModal";

/** react-router context is set here, so useHistory can only be used in sub components, not here **/


const App = () => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.userData);
  const user = useSelector(state => state.userData.user);
  const { code, active, infoAlert, successAlert, errorAlert, warningAlert } = userData;

  const activeModal = useSelector(state => state.modals.active);
  const modalData = useSelector(state => state.modals.data);
  const activeSidebar = useSelector(state => state.sidebar.active);
  const errorAlertHandler = (text) => dispatch(showErrorAlert(text));
  const successAlertHandler = (text) => dispatch(showSuccessAlert(text));
  const warningAlertHandler = (text) => dispatch(showWarningAlert(text));
  const infoAlertHandler = (text) => dispatch(showInfoAlert(text));
  const showModalHandler = (modalName, modalData) => dispatch(setActiveModal({ active: modalName, data: modalData }));

  console.log("App");
  useEffect(() => {
    setupAjaxCSRF();
    dispatch(fetchUserData());
    dispatch(initializeCommunity());
    $('.pre-render').remove();
  }, [dispatch]);

  useEffect(() => {
    if (errorAlert) {
      errorAlertHandler(errorAlert);
    }
    if (infoAlert) {
      infoAlertHandler(successAlert);
    }
    if (successAlert) {
      successAlertHandler(successAlert);
    }
    if (warningAlert) {
      warningAlertHandler(warningAlert);
    }
  }, [warningAlert, errorAlert, successAlert, infoAlert]);

  const showSignupModal = (pageData) => {
    showModalHandler("signup", pageData)
  };

  const showSigninModal = (pageData) => {
    showModalHandler("signin", pageData)
  };

  if (userData.loaded && (isOnboardingPage(active) || active === "sessions-linkedin_success")) {
    return (
      <Router>
        <CandidateModal />
        <TopCompaniesDatabaseModal />
        <ModalQueryParamHandler />
        {user && !user.application_completed && <JoinModal />}
        <SetPasswordModal />
        <Alert />
        <InvitationModal />
        <PendingReminderModal
          show={activeModal === "pending-reminder-modal"}
          onClick={() => dispatch(togglePendingReminderModal())}
        />
        <Content
          userData={userData}
          handleError={errorAlertHandler}
          handleSuccess={successAlertHandler}
          handleSignInClick={showSigninModal}
          handleJoinClick={showSignupModal}
        />
      </Router>
    );
  }
  if (userData.loaded) {
    return (
      <div id="app_container" className={`page-${active}`}>
        <Router>
          <PortalHandler />
          <CandidateModal />
          <TopCompaniesDatabaseModal />
          <Suspense fallback={lazyLoadFallbackUI}>
            <SetPasswordModal />
            {!(user && user.id) &&
              <>
                <SignInModal />
                <JoinModal />
              </>
            }
            <SpecialSignUpModal />
            {user && !user.application_completed && <JoinModal />}
            <InvitationModal />
          </Suspense>
          <ModalQueryParamHandler />
          <Navbar />
          <div className="container">
            <AcceptInviteModal />
            <PendingReminderModal
              show={activeModal === "pending-reminder-modal"}
              onClick={() => dispatch(togglePendingReminderModal())}
            />
            <Alert />
            <Content
              userData={userData}
              handleError={errorAlertHandler}
              handleSuccess={successAlertHandler}
              handleSignInClick={showSigninModal}
              handleJoinClick={showSignupModal}
            />
          </div>
        </Router>
      </div>
    );
  } else {
    return <></>;
  }
};

export default App;
