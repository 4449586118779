import React from "react"
import { memberCount, oneLiner, whatYouGet } from "@app/constants/text";

const JOB_SOURCES_ARRAY = [
  "top-of-job-board",
  "job-hunting",
  "jobs-search",
  "join-talent-pool",
  "featured-companies-list",
  "talent-splash-page",
  "talent-profile-logged-out-modal-cta",
  "top-of-job-dashboard"
];
const APPLY_TO_JOBS_SOURCES_ARRAY = [
  "apply-button",
  "positions-index"
];
/** click source **/
const TOP_WORKPLACES_SOURCE = "top-workplaces";
const SALARY_DATABASE_SOURCE = "salary-database";
const GET_INTRO_SOURCE = "request-intro";
const ANON_POST_SOURCE = "post-anonymously";
const COMMUNITY_SOURCE = "community";


/** custom review text on step 2 of creating a new account **/

const DEFAULT_REVIEW_TEXT = "We have a quick review process that usually takes 24 hours or less. A real human reviews every new member to make sure Elpha remains a high quality space. We're excited to learn more about you.";

const RATE_COMPANY_REVIEW_TEXT = "Before you rate your workplace, tell us a bit more about you. We have a quick review process for everyone who joins the network to make sure Elpha remains a high quality space. Thank you for taking the time!";


/** custom signup CTA text depending on click source **/


const PREFIX = `Elpha is ${oneLiner.toLowerCase()}. Join (for free!) to`;
const POSTFIX = `access ${whatYouGet} Check it out!`;
const DEFAULT_TEXT = `${PREFIX} ${POSTFIX}`;

export const CUSTOM_TEXT = (clickSource) => {
  const text = CUSTOM_WHAT_YOU_GET[clickSource];
  if (text) {
    return `${PREFIX} ${text}, ${POSTFIX}`;
  }
  return null;
};

const COMMUNITY_TEXT = `To join this community, create your free Elpha account. Elpha is ${oneLiner.toLowerCase()}.`;
const ANON_POST_TEXT = `${PREFIX} anonymously get advice from ${memberCount} women in tech.`;

const SALARY_DATABASE_TEXT = "Access the Elpha Salary Database. Join Elpha (for free!) and anonymously add your compensation to see thousands of real salaries from women in tech.";

const TOP_WORKPLACES_TEXT = `Our top workplaces ratings are from real, vetted women in tech. To anonymously rate your company alongside ${memberCount} women in tech, join Elpha (for free!)`;

const SUPPORTER_TEXT = `Elpha is ${oneLiner.toLowerCase()}. If you'd like to support our community, complete a quick application to let us know. And thank you in advance!`;

const GET_INTRO_TEXT = (
  <>Request intros to companies that are a fit for your skills <i>and</i> values. To get started, join the Elpha network of {memberCount} women working in tech. It's free!</>
);

const JOBS_TEXT = (
  <>Get matched with jobs that are a fit for your skills <i>and</i> values. To get started, join the Elpha network of {memberCount} women working in tech. It's free!</>
);

const APPLY_TO_JOBS_TEXT = (
  <>To get started applying for jobs that are a fit for your skills <i>and</i> values, join the Elpha network of {memberCount} women working in tech. It's free!</>
);

/** add a custom what you get offer to the signup CTA depending on the click source **/
const CUSTOM_WHAT_YOU_GET = {
  "message-member": "connect 1-1 with other women",
  "new-post-click": "write a post",
  "emoji-click": "interact with the network",
  "comment-reply": "reply to a post",
  "members-only": "read this conversation",
  "profile-members-only": "see member profiles",
  "rate-companies": "rate your company",
  "full-directory": `see the full directory`,
  "join-directory": `add your name to the directory`,
  "companies-index": "get help in your job search",
  "job-matches": "get hand-picked job recommendations",
  "companies-positions": "get help in your job search",
  "positions-index": "get help in your job search",
};

export default {
  JOB_SOURCES_ARRAY,
  APPLY_TO_JOBS_SOURCES_ARRAY,
  TOP_WORKPLACES_SOURCE,
  SALARY_DATABASE_SOURCE,
  GET_INTRO_SOURCE,
  ANON_POST_SOURCE,
  DEFAULT_REVIEW_TEXT,
  RATE_COMPANY_REVIEW_TEXT,
  DEFAULT_TEXT,
  ANON_POST_TEXT,
  SALARY_DATABASE_TEXT,
  TOP_WORKPLACES_TEXT,
  SUPPORTER_TEXT,
  GET_INTRO_TEXT,
  JOBS_TEXT,
  APPLY_TO_JOBS_TEXT,
  CUSTOM_TEXT,
  COMMUNITY_SOURCE,
  COMMUNITY_TEXT,
};
