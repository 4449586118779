import React from "react";
import { setActiveModal } from "@app/redux/modals";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import styles from "./index.module.scss";

const SupporterButton = ({ leftAlign }) => {
  const dispatch = useDispatch();
  const clickSource = useSelector(state => state.modals.data && state.modals.data.clickSource);

  const handleSupporterClick = () => {
    dispatch(setActiveModal({ active: "signup", data: {
      step: 1,
      isSupporter: true,
      clickSource
    }}));
  };
  const userExists = useSelector(
    state => !!(state.userData.user && state.userData.user.id)
  );

  const isSupporter = useSelector(state => !!(state.modals.data || {}).isSupporter);

  if (userExists || isSupporter) {
    return null;
  }
  return (
    <div className={classNames(styles.root, {
      [styles.leftAlign]: leftAlign === true
    })}>
      <span className={styles.caption}>Don't identify as a woman?  </span>
      <button className={styles.supporterBtn} onClick={handleSupporterClick}>
        Be a supporter.
      </button>
    </div>
  );
};
export default SupporterButton;
