import React from "react";

const COMPONENTS = {
  InArticleSignupCTA: React.lazy(() => import("./InArticleSignupCTA")),
  JoinCandidateDB: React.lazy(() => import("./JoinCandidateDB")),
  SocialShareCTA: React.lazy(() => import("./SocialShareCTA")),
  ClickToDownloadCTA: React.lazy(() => import("./SignUpToDownload/ClickToDownloadCTA")),
  InArticleDownloadCTA: React.lazy(() => import("./SignUpToDownload/InArticleDownloadCTA")),
  Outline: React.lazy(() => import("./Outline")),
  FeaturedCompaniesCTA: React.lazy(() => import("./FeaturedCompaniesCTA")),
  CandidateButtonCTA: React.lazy(() => import("./CandidateButtonCTA")),
  TrackEvent: React.lazy(() => import("./TrackEvent")),
  JoinElphaSalaryDatabaseCTA: React.lazy(() => import("./JoinElphaSalaryDatabaseCTA")),
  JoinElphaTopCompaniesDatabaseCTA: React.lazy(() => import("./JoinElphaTopCompaniesDatabaseCTA")),
  ShareSalaryDatabaseCTA: React.lazy(() => import("./ShareSalaryDatabaseCTA")),
  ShareTopCompaniesDatabaseCTA: React.lazy(() => import("./ShareTopCompaniesDatabaseCTA")),
  SalaryPreviewPane: React.lazy(() => import("./SalaryPreviewPane")),
  TopCompaniesPreviewPane: React.lazy(() => import("./TopCompaniesPreviewPane")),
  SalaryResources: React.lazy(() => import("./SalaryResources")),
  TopCompaniesResources: React.lazy(() => import("./TopCompaniesResources")),
  SalaryFullPane: React.lazy(() => import("./SalaryFullPane")),
  TopCompaniesDatabaseFullPane: React.lazy(() => import("./TopCompaniesDatabaseFullPane")),
  BookmarkPostWrapper: React.lazy(() => import("./BookmarkPostWrapper")),
  RightSideBarWrapper: React.lazy(() => import("./RightSideBarWrapper")),
  PostActionsWrapper: React.lazy(() => import("./PostActionsWrapper")),
  PostCommentCountWrapper: React.lazy(() => import("./PostCommentCountWrapper")),
  PostCommentsWrapper: React.lazy(() => import("./PostCommentsWrapper")),
  PostLoader: React.lazy(() => import("./PostLoader")),
  PostBylineActionsWrapper: React.lazy(() => import("./PostBylineActionsWrapper")),
  TalentPoolWidgetWrapper: React.lazy(() => import("./TalentPoolWidgetWrapper")),
  PositionItemWrapper: React.lazy(() => import("./PositionItemWrapper")),
  RequestIntroFooterWrapper: React.lazy(() => import("./RequestIntroFooterWrapper")),
  CultureQualitiesWrapper: React.lazy(() => import("./CultureQualitiesWrapper")),
  JoinElphaCTA: React.lazy(() => import("./JoinElphaCTA")),
  JoinElphaCTABig: React.lazy(() => import("./JoinElphaCTABig")),
  AddOrShareSalaryDatabaseCTA: React.lazy(() => import("./AddOrShareSalaryDatabaseCTA")),
  AddOrShareTopCompaniesDatabaseCTA: React.lazy(() => import("./AddOrShareTopCompaniesDatabaseCTA")),
  RateCompanyWrapper: React.lazy(() => import("./RateCompanyWrapper")),
  JoinButton: React.lazy(() => import("./JoinButton")),
  SignInButton: React.lazy(() => import("./SignInButton")),
};
export default COMPONENTS;
