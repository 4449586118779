import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { setActiveModal } from "@app/redux/modals";

import { sections } from "@app/components/pages/Home/utils";
import DesktopSideBar from "./DesktopSideBar";
import MobileSideBar from "./MobileSideBar";
import styles from "./index.module.scss";

const LoggedOutSideBar = () => {
  const dispatch = useDispatch();
  const isActive = useSelector(state => state.userData.active === "posts-active");
  const queryParams = new URLSearchParams(location.search);
  const selectedTag = queryParams.get("tag");
  const isMobile = useMediaQuery({ query: '(max-width: 994px)'});

  const handleJoinClick = () => {
    dispatch(setActiveModal({ active: "signup", data: { clickSource: "members-only" }}));
  };

  const handleClick = (url) => {
    if (url) {
      window.location = url;
    } else {
      handleJoinClick();
    }
  };

  const data = sections.map((section, index) => (
    {
      index: `side-s-${index}`,
      caption: section.title,
      thumbnail: `https://d27zilrr3yp6cg.cloudfront.net/icons/${section.label}.png`,
      active: section.label === selectedTag || (section.title === "Latest" && isActive),
      onClick: () => handleClick(section.url)
    }
  ));

  return (
    <div className={styles.root}>
      {isMobile ? <MobileSideBar /> : <DesktopSideBar clazz={styles.desktop} data={data} />}
    </div>
  );
};
export default LoggedOutSideBar;
