import React from "react"
import { Col, Row } from "react-bootstrap";

const TwoColumnWrapper = ({ offset, children }) => {
  return (
    <Row>
      <Col sm={4} md={3}>{children[0]}</Col>
      <Col sm={8} smOffset={offset} md={9} mdOffset={0} >{children[1]}</Col>
    </Row>
  );
};
export default TwoColumnWrapper;
