import React, { useEffect } from "react";

const SimpleInputField = props => {
  const inputRef = props.focus ? React.createRef() : null;

  /* stylelint-disable */
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = e => {
    props.onChange(e.target.value);
  };

  return (
    <div className="form-group">
      <input
        id={props.id}
        value={props.value}
        defaultValue={props.defaultValue}
        className="form-control"
        ref={inputRef}
        disabled={props.disabled}
        type={props.type || "text"}
        autoComplete={props.autocomplete}
        name={props.name}
        placeholder={props.placeholder}
        onChange={handleChange}
        required={props.required}
      />
    </div>
  );
};
export default SimpleInputField;
