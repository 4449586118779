import { createSlice } from "@reduxjs/toolkit";

import { track } from "@app/components/common/analytics";
import { addMessage } from "../candidates";
import AcceptOrReject from "@app/types/AcceptOrReject";
import hiringAPI from "../api";
import parseError from "@app/helpers/parseError";

export interface State {
  items: any[];
  isFetching: boolean;
  isReplying: boolean;
  errorMessage?: string;
  hasData: boolean;
  replyingToMatchID?: number;
  successMessage?: string;
  [key: string]: any;
}

const initialState: State = {
  items: [],
  isFetching: false,
  isReplying: false,
  errorMessage: undefined,
  hasData: false,
  replyingToMatchID: undefined,
  successMessage: undefined,
};

const matches = createSlice({
  name: "hiring/candidates/matches",
  initialState,
  reducers: {
    setItems(state, action) {
      state.items = action.payload;
      state.hasData = true;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setIsReplying(state, action) {
      state.isReplying = action.payload;
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    setReplyingToMatchID(state, action) {
      state.replyingToMatchID = action.payload;
    },
    setSuccessMessage(state, action) {
      state.successMessage = action.payload;
    },
  },
});

export const fetchMatches = () => async dispatch => {
  const { setErrorMessage, setIsFetching, setItems } = matches.actions;
  dispatch(setIsFetching(true));

  try {
    const { data } = await hiringAPI.fetchHiringMatchesForCompany();
    dispatch(setItems(data));
  } catch (e) {
    const errorMessage = parseError(
      e,
      "There was an error loading the matches."
    );
    dispatch(setErrorMessage(errorMessage));
  }

  dispatch(setIsFetching(false));
};

export const requestIntroWithoutMatch = (
  candidateUuid: string
) => async dispatch => {
  const { setSuccessMessage, setErrorMessage, setIsReplying } = matches.actions;

  dispatch(setIsReplying(true));

  try {
    const { data } = await hiringAPI.requestIntroWithoutMatch(candidateUuid);
    dispatch(addMessage(data.new_message));
    dispatch(setSuccessMessage(data.success_message));
    setTimeout(() => dispatch(setSuccessMessage(undefined)), 5000);
  } catch (e) {
    const errorMessage = parseError(e, "There was an error requesting an intro");
    dispatch(setErrorMessage(errorMessage));
  }

  dispatch(setIsReplying(false));
};

export const replyToMatch = (
  acceptOrReject: AcceptOrReject,
  uuid: string
) => async dispatch => {
  const { setSuccessMessage, setErrorMessage, setIsReplying } = matches.actions;

  dispatch(setIsReplying(true));
  dispatch(setSuccessMessage(undefined));
  dispatch(setErrorMessage(undefined));

  try {
    const { data } = await hiringAPI.replyToHiringMatchByUuid(
      acceptOrReject,
      uuid
    );
    dispatch(setSuccessMessage(data.message));
    setTimeout(() => dispatch(setSuccessMessage(undefined)), 5000);
    track("Company requested intro", { hiringMatchUUID: uuid, acceptOrReject });
  } catch (e) {
    const errorMessage = parseError(
      e,
      `There was an error ${
        acceptOrReject === "accept" ? "accepting" : "rejecting"
      } this match.`
    );
    dispatch(setErrorMessage(errorMessage));
  }

  dispatch(setIsReplying(false));
};

export default matches.reducer;
