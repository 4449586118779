import axios from "axios";

import { setCSRFTokenForRequest } from "@app/api/utils";

const resourcesAPI = {
  fetchLatestResources: () => {
    return setCSRFTokenForRequest(() => (
      axios.get("/resources/latest")
    ))
  },
};
export default resourcesAPI;
