import React from "react";
import $ from "jquery";
import styles from "./index.module.scss";

const ShareTopCompaniesDatabase = () => {
  const handleShareOnLinkedInClick = () => {
    const url = `https://www.linkedin.com/sharing/share-offsite/?url=https://elpha.com/top-companies-database`;
    window.open(
      url,
      "linkedinShareWindow",
      `height=450, width=550, top=${$(window).height() / 2 - 275}, left=${$(window).width() / 2 -
        225}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`
    );
    return false;
  };

  return (
    <div className={styles.root}>
      <p>🎉 Thank you for anonymously rating your employer</p>
      <p>Encourage your network to support women at work by anonymously rating their employers, too. Together let's figure out which are the top workplaces for women!</p>
      <div className={styles.linkedInShareContainer}>
        <span
          onClick={handleShareOnLinkedInClick}
          className={styles.linkedInShare}
        >
          <img src="https://d2i0px8cen0nqy.cloudfront.net/linkedin-circle-lg.png" />
        </span>
      </div>
    </div>
  );
};
export default ShareTopCompaniesDatabase;
