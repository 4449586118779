import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { track } from "@app/components/common/analytics";
import SideBarSpinner from "@app/components/SideBars/Spinner";
import ThumbnailWrapper from "../ThumbnailWrapper";
import styles from "./index.module.scss";
import useLatestResourcesLoader from "./useLatestResourcesLoader";

const Resources = ({ resources }) => {
  const handleClick = (resource) => {
    track("Latest Resource Clicked", {
      resourceTitle: resource.title,
    });
  };

  return (
    <>
    {resources.map(((resource, index) => {
      return (
        <a
          href={`/resources/${resource.slug}`}
          key={index}
          className={classNames(styles.thumbnailContainerNoPadding, styles.url)}
          onClick={() => handleClick(resource)}
          target="_blank"
        >
          <ThumbnailWrapper user={resource.author} />
          <div className={styles.thumbnailTextContainer}>
            <div className={styles.thumbnailTitle}>{resource.title}</div>
            <div className={styles.thumbnailNameCaption}>{resource.author.username}</div>
          </div>
        </a>
      );
    }))}
    </>
  );
};

const LatestResourcesWidget = () => {
  const { isLoading, resources } = useLatestResourcesLoader();

  return (
    <div className={styles.root}>
      <div className={styles.sideBarSubheader}>
        <span className={styles.text}>📚  latest resources</span>
        <a target="_blank" href="/resources" className={styles.subtext}>see all</a>
      </div>
      {isLoading ? <SideBarSpinner /> : <Resources resources={resources} /> }
      {!isLoading && resources.length === 0 && <div className={styles.none}>No resources found</div>}
    </div>
  );
};
export default LatestResourcesWidget;
