import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import communityAPI from "@app/redux/community/api";
import TodoChecklistWidget from "../../TodoChecklistWidget";
import MobileFirstButtons from "./MobileFirstButtons";
import MobileLastButtons from "./MobileLastButtons";
import CloseMobileSideBar from "./CloseMobileSideBar";
import CommunityWidget from "../PremiumCommunity/CommunityWidget";
import CommunityButtons from "../CommunityButtons";
import CommunityActions from "../CommunityActions";
import PremiumCommunitySideBar from "@app/components/SideBars/LeftSideBar/LoggedIn/PremiumCommunity";
import LatestResourcesWidget from "@app/components/SideBars/RightSideBar/LatestResourcesWidget";
import NeedingEngagementWidget from "@app/components/SideBars/RightSideBar/NeedingEngagementWidget";
import styles from "./index.module.scss";

const StandardCommunitySideBar = () => (
  <>
    <TodoChecklistWidget />
    <NeedingEngagementWidget />
    <LatestResourcesWidget />
    <CommunityButtons isDesktop={false} />
    <div className={styles.mobileSideBarSubheader}>More</div>
  </>
);
const MobileSideBar = () => {
  const isActiveLeftSideBar = useSelector(state => state.sidebar.active === "mobile");
  const isPremiumCommunity = useSelector(state => !!(state.userData.community || {}).is_premium);

  return (
    <div className={classNames(styles.root, {
        [styles.slide]: isActiveLeftSideBar
      })}
    >
      <CloseMobileSideBar />
      <div className={styles.mobileBody}>
        <MobileFirstButtons />
        <CommunityActions isDesktop={false} />
        {!isPremiumCommunity && <StandardCommunitySideBar />}
        {isPremiumCommunity && <PremiumCommunitySideBar isDesktop={false} />}
        <MobileLastButtons />
      </div>
    </div>
  );
};
export default MobileSideBar;
