const { createSlice } = require("@reduxjs/toolkit");

import hiringAPI from "../api";
import { showErrorAlertFromErr, showSuccessAlert } from "@app/redux/alert";

const form = createSlice({
  name: "hiring/messageTemplates/form",
  initialState: {
    isSaving: false,
  },
  reducers: {
    setIsSaving(state, action) {
      state.isSaving = action.payload;
    },
  },
});

/**
 * Updates an existing message template.
 *
 * @param {number} id - The ID of the message template to update.
 * @param {MessageTemplate} messageTemplate - The message template to save.
 */
export const updateMessageTemplate = (
  id,
  messageTemplate
) => async dispatch => {
  const { setIsSaving } = form.actions;

  dispatch(setIsSaving(true));

  try {
    await hiringAPI.updateMessageTemplate(id, messageTemplate);
    dispatch(showSuccessAlert("Your message template was saved."));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }

  dispatch(setIsSaving(false));
};

/**
 * Creates a new message template.
 *
 * @param {MessageTemplate} messageTemplate - The message template to create.
 */
export const createMessageTemplate = messageTemplate => async dispatch => {
  const { setIsSaving } = form.actions;

  dispatch(setIsSaving(true));

  try {
    await hiringAPI.createMessageTemplate(messageTemplate);
    dispatch(showSuccessAlert("Your message template was created."));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }

  dispatch(setIsSaving(false));
};

export default form.reducer;
