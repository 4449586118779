import React from "react";
import { useSelector } from "react-redux";
import MobileButtonGroup from "@app/components/SideBars/LeftSideBar/MobileButtonGroup";

const MobileLastButtons = () => {
  const active = useSelector(state => state.userData.active);
  const handleClick = link => window.location = link;

  const buttons = [
    {
      index: "signout",
      glyph: "",
      caption: "Sign out",
      onClick: () => handleClick("/signout")
    },
    {
      index: "faq",
      caption: "FAQ",
      active: active === "faq",
      onClick: () => handleClick("/faq")
    }
  ];

  return <MobileButtonGroup buttons={buttons} isLoading={false} />;
};
export default MobileLastButtons;
