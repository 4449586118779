import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import resourcesAPI from "@app/redux/resources/api";
import { showErrorAlertFromErr } from "@app/redux/alert";

const useLatestResourcesLoader = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [resources, setResources] = useState([]);

  const fetch = async () => {
    try {
      const { data } = await resourcesAPI.fetchLatestResources();
      setResources(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      /**noop since widget**/
      /**dispatch(showErrorAlertFromErr(e))**/
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    isLoading,
    resources
  };
};

export default useLatestResourcesLoader;
