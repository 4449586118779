import { createSlice } from "@reduxjs/toolkit";

import parseError from "@app/helpers/parseError";

const alert = createSlice({
  name: "alert",
  initialState: {
    text: undefined,
    type: undefined
  },
  reducers: {
    setBadgeNotification(state, action) {
      state.badge = action.payload;
      return state;
    },
    setWarningAlert(state, action) {
      state = {
        text: action.payload,
        type: "warning",
      };
      return state;
    },
    setErrorAlert(state, action) {
      state = {
        text: action.payload,
        type: "danger",
      };
      return state;
    },
    setSuccessAlert(state, action) {
      state = {
        text: action.payload,
        type: "success",
      };
      return state;
    },
    setInfoAlert(state, action) {
      state = {
        text: action.payload,
        type: "info",
      };
      return state;
    },
    clearAlert(state) {
      state = {
        text: undefined,
        type: undefined,
      };
      return state;
    },
  },
});

export const {
  setWarningAlert,
  setInfoAlert,
  setErrorAlert,
  setSuccessAlert,
  clearAlert,
} = alert.actions;

export const showErrorAlert = (alertText, dispatch) => async dispatch => {
  dispatch(setErrorAlert(alertText));
  setTimeout(() => dispatch(clearAlert()), 7000);
};

/**
 * Automatically parse the error for an error message and show that in an alert.
 *
 * @param {object} err - The error to parse.
 * @param {string} [defaultMessage] - The default message to show if no error
 * can be parsed. Optional.
 */
export const showErrorAlertFromErr = (
  err,
  defaultMessage = undefined
) => async dispatch => {
  dispatch(showErrorAlert(parseError(err, defaultMessage)));
};

export const showSuccessAlert = alertText => async dispatch => {
  dispatch(setSuccessAlert(alertText));
  setTimeout(() => dispatch(clearAlert()), 7000);
};

export const showInfoAlert = alertText => async dispatch => {
  dispatch(setInfoAlert(alertText));
  setTimeout(() => dispatch(clearAlert()), 7000);
};

export const showWarningAlert = alertText => async dispatch => {
  dispatch(setWarningAlert(alertText));
  setTimeout(() => dispatch(clearAlert()), 7000);
};

export default alert.reducer;
