import AcceptOrReject from "@app/types/AcceptOrReject";
import createReplyMadLibsSlice from "@app/redux/helpers/createReplyMadLibsSlice";
import hiringAPI from "../api";

const sendReply = async ({
  acceptOrReject,
  identifier,
  rejectionReason,
  rejectionReasonOther,
  userHash,
}: {
  acceptOrReject: AcceptOrReject;
  identifier: string;
  rejectionReason?: string;
  rejectionReasonOther?: string;
  userHash: string;
}) => {
  const { data } = await hiringAPI.replyToIntroByHash({
    acceptOrReject,
    updateHash: identifier,
    rejectionReason,
    rejectionReasonOther,
    userHash,
  });
  return { name: data.candidate_name, message: data.message };
};

const {
  slice,
  reply,
} = createReplyMadLibsSlice(
  "hiring/companyIntros/replyMadLibsByHash",
  sendReply,
  ["skills_mismatch", "experience_mismatch", "no_bandwidth"]
);

export default slice.reducer;

export const { selectAccept, selectReject, setRejectionReason } = slice.actions;

export { reply };
