import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showErrorAlertFromErr } from "@app/redux/alert";
import communityAPI from "@app/redux/community/api";
import SideBarSpinner from "@app/components/SideBars/Spinner";
import Expert from "./Expert";
import styles from "./index.module.scss";

const ExpertsWidget = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [expertSessions, setExpertSessions] = useState([]);
  const communitySlug = useSelector(state => (state.userData.community || {}).slug);

  const fetchExpertsWrapper = async () => {
    try {
      const { data } = await communityAPI.fetchExperts(communitySlug, true);
      setExpertSessions(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch(showErrorAlertFromErr(e));
    }
  };

  useEffect(() => {
    fetchExpertsWrapper();
  }, []);

  if (!isLoading && expertSessions.length === 0) {
    return null;
  }
  return (
    <div className={styles.widgetRoot}>
      <div className={styles.header}>sessions</div>
      {isLoading && <SideBarSpinner />}
      {
        expertSessions.map((expertSession, index) => (
          <Expert key={index} expertSession={expertSession} />
        ))
      }
      <a href={`/c/${communitySlug}/experts`} className={styles.btn}>Browse All Sessions</a>
    </div>
  );
};
export default ExpertsWidget;
