import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useParams from "@app/hooks/useParams";

import Step1 from "./Step1";
import StepThankYou from "./StepThankYou";
import useModalActions from "./useModalActions";
import { saveCompanyRating } from "@app/redux/companyRatings/form";
import $ from "jquery";

const Form = () => {
  const dispatch = useDispatch();
  const { mergeQueryParams } = useParams();
  const {
    resetModalToLastCompletedStep,
    getStep,
    goToNextStep
  } = useModalActions();
  const state = useSelector(state => state);
  const companyRating = { ...useSelector(state => state.companyRatings.companyRating) };
  const user = useSelector(state => state.userData.user) || {};

  /** include user info in initial companyRating **/
  if (!companyRating.company) companyRating.company = user.company;
  if (!companyRating.department) companyRating.department = user.department;
  if (!companyRating.experience) companyRating.experience = user.experience;
  if (!companyRating.title) companyRating.title = user.title;
  if (!companyRating.address) companyRating.location = user.address;

  const [submitted, setSubmitted] = useState(user.company_rating_submitted);
  const step = getStep();

  resetModalToLastCompletedStep(companyRating);

  const handleSubmitStep = updatedCompanyRating => {
    dispatch(
      saveCompanyRating(
        updatedCompanyRating,
        () => {
          goToNextStep();
        },
        () => {
          $(".modal-open .modal").animate({ scrollTop: 0 }, "fast");
        },
      )
    );
  };

  if (step === "thank-you") {
    return <StepThankYou />;
  }

  if (step === 1) return <Step1 companyRating={companyRating} onSubmit={handleSubmitStep} />;

  return null;
};

export default Form;
