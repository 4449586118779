import React from "react";

/**
 * A select box to pass to react-hook-form's `<Controller />` for a boolean
 * field. Takes care of parsing yes/no dropdown options into booleans, and
 * includes an empty option if `allowUndefined` is `true`.
 *
 * @example
 * <Controller
 *   name="comp_pref_flex"
 *   control={control}
 *   render={({ field: { onChange, value } }) => (
 *     <BooleanSelect value={value} onChange={onChange} allowUndefined />
 *   )}
 * />
 *
 * @param {object} props
 * @param {boolean} props.value - The current value
 * @param {boolean} [props.allowNull] - Whether to include an empty option to
 * leave this field as null.
 * @param {string} [props.trueLabel] - A custom label to use for the `true`
 * option. (Default: "Yes").
 * @param {string} [props.falseLabel] - A custom label to use for the `false`
 * option. (Default: "No").
 * @param props.onChange - The onChange callback to call when the field has
 * changed.
 */
const BooleanSelect = ({
  value,
  allowNull = false,
  trueLabel = "Yes",
  falseLabel = "No",
  onChange,
}) => {
  return (
    <select
      className="form-control"
      onChange={e => {
        if (allowNull && e.target.value === "") onChange(null);
        else if (e.target.value === "true") onChange(true);
        else onChange(false);
      }}
      value={allowNull && (value === null || value === undefined) ? "" : value}
    >
      {allowNull && <option />}
      <option value={true}>{trueLabel}</option>
      <option value={false}>{falseLabel}</option>
    </select>
  );
};

export default BooleanSelect;
