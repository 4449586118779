import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import useTestChecker from "@app/hooks/useTestChecker";
import $ from "jquery";
import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
import { clearActiveModal, setActiveModal } from "@app/redux/modals";
import { setLoggedIn, updateUser } from "@app/redux/userData";
import Alert from "@app/redux/alert/Alert";
import { showErrorAlertFromErr } from "@app/redux/alert";
import useParams from "@app/hooks/useParams";
import SimpleInputField from "@app/components/common/SimpleInputField";
import { dompurify, parseError } from "@app/components/common/utils";

import { identify, track } from "@app/components/common/analytics";
import styles from "./index.module.scss";
import MagicLinkModal from "../MagicLinkModal";
import LinkedInButton from "../LinkedInButton";

const SignInModal = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userData.user);
  const active = useSelector(state => state.userData.active);
  const inviterName = useSelector(state => state.userData.inviterName);
  const modalData = useSelector(state => state.modals.data);
  const activeModal = useSelector(state => state.modals.active);
  const showModal = activeModal === "signin" || activeModal === "login";
  const { getQueryParam, removeQueryParams } = useParams();
  const defaultEmail = getQueryParam("email");

  const [password, setPassword] = useState("");
  const [isPasswordMissing, setIsPasswordMissing] = useState("");
  const [isEmailMissing, setIsEmailMissing] = useState(false);
  const [email, setEmail] = useState(defaultEmail || "");
  const [saving, setSaving] = useState(false);
  const [modal, setModal] = useState(activeModal);

  const { community = {} } = modalData;

  useEffect(() => {
    setModal(activeModal);
  }, [activeModal]);

  const handleJoinClick = () => {
    dispatch(setActiveModal({ active: "signup", data: { clickSource: modalData.clickSource }}));
  };

  const handleMagicLinkClick = () => setModal("magic-link");
  const handleResetPasswordClick = () => setModal("reset-password-email");
  const handleSignInLinkClick = () => setModal("sign-in");

  const handleCloseModal = () => {
    setEmail("");
    setSaving(false);
    setPassword("");
    setIsPasswordMissing(false);
    setIsEmailMissing(false);
    removeQueryParams(["modal"]);
    dispatch(clearActiveModal());
  };

  const handlePasswordChange = val => {
    setPassword(val);
    setIsPasswordMissing(false);
    setSaving(false);
  };

  const handleEmailChange = val => {
    setEmail(val);
    setIsEmailMissing(false);
    setSaving(false);
  };

  const handleSignInClick = e => {
    e.preventDefault();
    setSaving(true);
    setIsEmailMissing(false);
    setIsPasswordMissing(false);
    if (!email) {
      setIsEmailMissing(true);
      setSaving(false);
      return false;
    }
    if (!password) {
      setIsPasswordMissing(true);
      setSaving(true);
      return false;
    }
    $.ajax({
      url: "/authenticate_v2",
      method: "POST",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        email,
        password,
        group: community.slug,
        final_url: window.location.href
      }),
      success: (data) => {
        identify(data.user);
        track("User Logged In", { type: "Password" });
        if (active === "matches-index" || active === "top-companies-rate") {
          dispatch(updateUser(data.user));
          dispatch(setLoggedIn());
          dispatch(clearActiveModal());
        } else if (data.url) {
          window.location = data.url;
        } else {
          location.reload();
        }
      },
      error: (e) => {
        setSaving(false);
        dispatch(showErrorAlertFromErr(e));
      }
    });
  };

  const modals = ["magic-link", "reset-password-email"];
  if (modals.indexOf(modal) !== -1 && !(user && user.id)) {
    return (
      <MagicLinkModal
        onAfterClose={() => setModal(null)}
        onSignInClick={handleSignInLinkClick}
        reset={modal === "reset-password-email"}
      />
    );
  }
  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      className={classNames(styles.root, styles.modal)}
    >
      <Modal.Body className={classNames(styles.modalBody)}>
        <div className={styles.flexRow}>
          <div className={styles.leftCol}>
            <button
              type="button"
              onClick={handleCloseModal}
              className={styles.close}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className={styles.title}>Welcome back</div>
            <div className={styles.text}>
              We’re glad to see you! Sign in to catch up on the latest happenings on Elpha.
              <br />
              <br />
              Not a member yet?
              <span onClick={handleJoinClick} className={styles.swap}>
                Create your account
              </span>
            </div>
          </div>
          <div className={styles.rightCol}>
            <form
              onSubmit={e => handleSignInClick(e)}
              className={styles.flexColMiddle}
            >
              <Alert />
              <LinkedInButton
                text="Sign in with LinkedIn"
                type="login"
              />
              <hr />
              <div
                className={classNames(styles.relative, {
                  ["invalid-input-text"]: isEmailMissing
                })}
              >
                <label style={{ display: "none" }} htmlFor="email">
                  Email
                </label>
                <SimpleInputField
                  focus
                  autocomplete="email"
                  id="email"
                  placeholder="Email"
                  name="email"
                  value={email || ""}
                  onChange={handleEmailChange}
                />
                {isEmailMissing ? (
                  <div className="invalid-input-text-caption">Invalid Email</div>
                ) : null}
              </div>
              <div
                className={classNames(styles.relative, {
                  ["invalid-input-text"]: isPasswordMissing,
                  ["overlay-input-text"]: !isPasswordMissing,
                })}
              >
                <label style={{ display: "none" }} htmlFor="password">
                  Password
                </label>
                <SimpleInputField
                  id="password"
                  autocomplete="current-password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password || ""}
                  onChange={handlePasswordChange}
                />
                {isPasswordMissing ? (
                  <div className="invalid-input-text-caption">Invalid Password</div>
                ) : (
                  <div className="input-overlay-caption" onClick={handleResetPasswordClick}>
                    Forgot?
                  </div>
                )}
              </div>
              <button className={styles.buttonPrimary} type="submit" disabled={saving}>
                Sign in
              </button>
              <div onClick={handleMagicLinkClick} className={styles.sendMagicLink}>
                Use magic link instead
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default SignInModal;
