import AcceptOrReject from "@app/types/AcceptOrReject";
import createReplyMadLibsSlice from "../helpers/createReplyMadLibsSlice";
import candidatesAPI from "./api";

const sendReply = async (params: {
  acceptOrReject: AcceptOrReject;
  identifier: string;
  rejectionReason?: string;
  rejectionReasonOther?: string;
}) => {
  const { data } = await candidatesAPI.replyToCandidateMessageByUUID(params);
  return { name: data.company_name, message: data.message };
};

const { slice, reply } = createReplyMadLibsSlice(
  "candidate/replyMadLibs",
  sendReply,
  ["role_mismatch", "mission_mismatch", "benefits_mismatch", "not_looking"]
);

export default slice.reducer;

export const { selectAccept, selectReject, setRejectionReason } = slice.actions;

export { reply };
