import React, { useState, useEffect } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { showErrorAlertFromErr } from "@app/redux/alert";
import { track } from "../common/analytics";

const FeaturedCompaniesWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    loadCompanies();
  }, []);

  const handleClick = () => {
    track("Featured Company List Page");
  };

  const handleError = (e) => {
    dispatch(showErrorAlertFromErr(e));
  };

  const loadCompanies = () => {
    $.ajax({
      url: "/companies/featured",
      method: "get",
      dataType: "json",
      contentType: "application/json",
      success: data => {
        setCompanies(JSON.parse(JSON.stringify(data.companies || [])));
        setLoading(false);
      },
      error: e => {
        handleError(e);
        setLoading(false);
      }
    });
  };
  if (loading || !companies.length) {
    return <></>;
  }
  return (
    <>
      {React.cloneElement(children, { companies, onError: handleError })}
    </>
  );
};

export default FeaturedCompaniesWrapper;

