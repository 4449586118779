import React, { useCallback, useState } from "react";
import $ from "jquery";
import { MentionData } from "@draft-js-plugins/mention";

import MentionEntry from "./MentionEntry";

const useMentions = () => {
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({ value }) => {
    const url = `/members/all.json?username=${value.toLowerCase()}`;
    $.get(url)
      .then(users =>
        // TODO: This data structure gets saved into the rich-text JSON object
        // as a DraftJS entity. We should fix this, because we don't really want
        // the avatar/full name to be saved in the database (as that would be
        // de-normalized). Fortunately, we're only using the `name` for now
        // anyway when rendering the rich text, but this is something to look
        // into in the future. (2021-06-25)
        users.map(user => ({
          name: `@${user.username}`,
          avatar: user.thumbnail_url,
          fullName: user.full_name,
        }))
      )
      .then(setSuggestions);
  }, []);

  return {
    onOpenChange,
    onSearchChange,
    suggestions,
    open,
    MentionEntry
  };
};

export default useMentions;
