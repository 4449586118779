import React from "react";
import Thumbnail from "@app/components/common/Thumbnail";
import styles from "./index.module.scss";

const Expert = ({ expertSession }) => {
  const { expert } = expertSession;
  return (
    <a className={styles.memberBox} href={`/members/${expert.username}`}>
      <Thumbnail imgUrl={expert.picture_url} />
      <div className={styles.memberInfo}>
        <div className={styles.memberInfoName}>{expertSession.headline}</div>
        <div className={styles.memberInfoSubtitle}>{expert.full_name}, {expert.title_line}</div>
      </div>
    </a>
  );
};
export default Expert;
