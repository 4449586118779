import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import accountAPI from "@app/redux/account/api";
import { showErrorAlertFromErr } from "@app/redux/alert";

const useCommunityLoader = () => {
  const dispatch = useDispatch();
  const [communities, setCommunities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isLoggedIn = useSelector(state => !!(state.userData.user || {}).id);
  const activeCommunitySlug = useSelector(state => (state.userData.community || {}).slug);

  const handleClick = url => window.location = url;

  const fetchCommunities = async () => {
    try {
      const { data } = await accountAPI.fetchCommunityMemberships(isLoggedIn) || []
      setCommunities(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch(showErrorAlertFromErr(e))
    }
  };

  const communityButtons = communities.map((com, index) => ({
    index,
    caption: com.name,
    thumbnail: com.thumbnail_url,
    active: activeCommunitySlug === com.slug,
    onClick: () => handleClick(`/c/${com.slug}`)
  }));

  useEffect(() => {
    fetchCommunities();
  }, []);

  return {
    isLoading,
    communityButtons
  };
};

export default useCommunityLoader;
