import React from "react";
import classNames from "classnames";

const CustomCheckbox = ({ disabled, onChange, checked, label, className = "" }) => {
  // Click handler is on the `<div />`, so clicks on the checkbox itself should
  // be no-ops.
  const noop = e => e.preventDefault();
  const handleChange = () => {
    if (disabled) return;
    onChange && onChange();
  };

  return (
    <div
      onClick={handleChange}
      className={classNames("check-container", "custom-filter-menu-item", className, {
        ["disabled"]: disabled
      })}
    >
      <input type="checkbox" disabled={disabled} onChange={noop} checked={!!checked} />
      <span className="checkmark-label">{label}</span>
      <span className="checkmark" />
    </div>
  );
};
export default CustomCheckbox;
