import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import styles from "./index.module.scss";

const Title = () => {
  const hasCommunity = useSelector(state => !!state.userData.community);
  const communitySlug = hasCommunity &&
    useSelector(state => state.userData.community.slug);
  const communityName = hasCommunity &&
    useSelector(state => state.userData.community.name);
  const isPremiumCommunity = hasCommunity &&
    useSelector(state => state.userData.community.is_premium);
  const access = hasCommunity &&
    useSelector(state => state.userData.access);

  if (hasCommunity) {
    return (
      <span className={classNames("overflow-scroll-container", styles.root)}>
        <span className={styles.divider} />
        <a href={`/c/${communitySlug}`} className="nav-bar-title">
        {
          (access === "secret" && !isPremiumCommunity ? (
            <>
              <span className={styles.titleCaption}>secret</span>
              <span className={styles.titleName}>{communityName}</span>
            </>
          ) : <><span className={styles.titleName}>{communityName}</span>{" "}</>)
        }
        </a>
      </span>
    );
  }
  return <span />;
};

export default Title;
