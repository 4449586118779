import React from "react";
import ButtonGroup from "./ButtonGroup";

const SingleSelectButtonGroup = ({ onClick, elements, selectedValue, overrideStyles }) => {
  return (
    <ButtonGroup
      onClick={onClick}
      elements={elements}
      selectedValues={selectedValue ? [selectedValue] : []}
      overrideStyles={overrideStyles}
      singleSelect={true}
    />
  );
};
export default SingleSelectButtonGroup;
