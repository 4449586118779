import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import postAPI from "@app/redux/post/api";
import { showErrorAlertFromErr } from "@app/redux/alert";

const usePostsNeedingEngagementLoader = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  const fetch= async () => {
    try {
      const { data } = await postAPI.fetchPostsNeedingEngagement();
      setPosts(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch(showErrorAlertFromErr(e))
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    isLoading,
    posts
  };
};

export default usePostsNeedingEngagementLoader;
