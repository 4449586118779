import React from "react";
import { useSelector, useDispatch } from "react-redux";
import useParams from "@app/hooks/useParams";
import { setActiveModal } from "@app/redux/modals";
import { track } from "@app/components/common/analytics";

const useCandidateModal = () => {
  const dispatch = useDispatch();
  const { setQueryParams } = useParams();
  const isLoggedIn = useSelector(state => state.userData.user && state.userData.user.id);
  const isCandidate = useSelector(state => state.userData.user && state.userData.user.is_candidate);

  const openCandidateModal = ({
    logInRequired = true,
    pos = undefined,
    step = undefined,
    source = undefined
  }) => {
    track("Viewed Create Candidate", {
      source,
      step,
      isCandidate
    });
    if (isLoggedIn || !logInRequired) {
      setQueryParams({ modal: "quiz", step, source, pos });
    } else {
      dispatch(setActiveModal({
        active: "signup",
        data: { clickSource: source || "join-talent-pool" }
      }));
    }
  };

  return {
    openCandidateModal
  };
};

export default useCandidateModal;
