import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { setActiveModal } from "@app/redux/modals";

import useParams from "@app/hooks/useParams";
import Form from "./Form";
import useModalActions from "./useModalActions";

const TopCompaniesDatabaseModal = () => {
  const { mergeQueryParams } = useParams();
  const { closeModal } = useModalActions();
  const showModal =
    useSelector(state => state.modals.active === "rate-company");
  const isLoggedIn =
    useSelector(state => state.userData.user && state.userData.user.id);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoggedIn) {
      mergeQueryParams({ modal: undefined });
    }
  }, [isLoggedIn]);

  return (
    <Modal
      show={showModal}
      onHide={closeModal}>
      <Modal.Body>
        <Form />
      </Modal.Body>
    </Modal>
  );
};

export default TopCompaniesDatabaseModal;
