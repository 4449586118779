const { createSlice } = require("@reduxjs/toolkit");

import eventsAPI from "./api";
import {
  showErrorAlert,
  showErrorAlertFromErr,
  showSuccessAlert,
} from "../alert";
import { clearActiveModal } from "../modals";

const invite = createSlice({
  name: "events/event/invite",
  initialState: {
    // An array of user IDs that are selected by the user to invite.
    selectedUserIDs: [],

    // Whether a request to the server is currently running to invite users.
    isInvitingUsers: false,
  },
  reducers: {
    setIsInvitingUsers(state, action) {
      state.isInvitingUsers = action.payload;
    },

    /**
     * @param {object} state
     * @param {object} action
     * @param {number} action.payload - The user ID to mark as selected.
     */
    addSelectedUserID(state, action) {
      state.selectedUserIDs.push(action.payload);
    },

    /**
     * @param {object} state
     * @param {object} action
     * @param {number} action.payload - The user ID to remove as selected.
     */
    removeSelectedUserID(state, action) {
      state.selectedUserIDs = state.selectedUserIDs.filter(
        id => id !== action.payload
      );
    },

    resetSelectedUserIDs(state) {
      state.selectedUserIDs = [];
    },
  },
});

export const { addSelectedUserID, removeSelectedUserID } = invite.actions;

export const inviteUsers = () => async (dispatch, getState) => {
  const { setIsInvitingUsers, resetSelectedUserIDs } = invite.actions;
  const {
    slug,
    path,
    invite: { selectedUserIDs },
  } = getState().events.event;

  if (!selectedUserIDs) {
    dispatch(showErrorAlert("You must select some users to invite."));
    return;
  }

  dispatch(setIsInvitingUsers(true));

  try {
    await eventsAPI.inviteUsers(selectedUserIDs, slug, path);
    dispatch(clearActiveModal());
    dispatch(showSuccessAlert("Your invites have been sent!"));
    dispatch(resetSelectedUserIDs());
  } catch (e) {
    dispatch(clearActiveModal());
    dispatch(showErrorAlertFromErr(e));
  }

  dispatch(setIsInvitingUsers(false));
};

export default invite.reducer;
