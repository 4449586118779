import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import CompanyRating from "@app/types/CompanyRating";
import form from "./form";
import combineSlices from "@app/helpers/combineSlices";

export interface State {
  companyRating?: CompanyRating;
}

const initialState: State = {
  companyRating: undefined,
};

const companyRating = createSlice<State, SliceCaseReducers<State>>({
  name: "companyRating",
  initialState,
  reducers: {
    setCompanyRating(state, action: PayloadAction<CompanyRating | undefined>) {
      state.companyRating = action.payload;
    },
  },
});

export const { setCompanyRating } = companyRating.actions;

export default combineSlices(companyRating.reducer, initialState, {
  form,
});
