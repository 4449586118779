import $ from "jquery";
import { getCsrfToken } from "../api/utils";

export const setupAjaxCSRF = () => {
  // TEMPORARY while we have old components called from the new app that aren't using axois
  $.ajaxSetup({
    headers: {
      "X-CSRF-Token": getCsrfToken(),
      Vary: "X-Requested-With"
    },
    cache: false
  });
};
