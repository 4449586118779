import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { setActiveModal } from "@app/redux/modals";
import useTestChecker from "@app/hooks/useTestChecker";
import CommunityTitle from "../CommunityTitle";
import MobileAccountMenu from "../MobileAccountMenu";
import LoginOverlay from "@app/components/Register/LoginOverlay";
import styles from "./index.module.scss";

const PublicNavbar = ({
  onMobileClick,
  extraButtons,
}) => {
  const dispatch = useDispatch();
  const { showText, isPostPage, isResourcePage } = useTestChecker();

  const template = useSelector(state => state.userData.template);
  const user = useSelector(state => state.userData.user) || {};
  const active = useSelector(state => state.userData.active);
  const modal = useSelector(state => state.userData.modal);
  const isShadowPost = useSelector(state => state.userData.isShadowPost || false);

  const loginDelay = (isResourcePage || isPostPage) && !isShadowPost;

  const handleSignInClick = () => dispatch(setActiveModal({ active: "signin" }));
  const handleJoinClick = () => dispatch(setActiveModal({ active: "signup" }));

  return (
    <Navbar id={styles.publicNavbar} >
      <LoginOverlay
        delayed={loginDelay}
        closable={!isShadowPost}
      />
      <Navbar.Header id={styles.navbarHeader}>
        <a href="/" className='navbar-brand' id={styles.navbarBrand}>elpha</a>
        {extraButtons}
        <CommunityTitle />
        <span className={`nav-buttons nav-btn ${styles.navBtns}`}>
          <button onClick={handleSignInClick} className={`btn-default btn ${styles.loginBtn}`}>
            Log in
          </button>
          <button onClick={() => handleJoinClick(active)} className={`btn-primary btn ${styles.signupBtn}`}>
            Sign up
          </button>
          <MobileAccountMenu onClick={onMobileClick} active={active} />
        </span>
      </Navbar.Header>
    </Navbar>
  );
};

export default PublicNavbar;
