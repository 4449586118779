import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { updateOnboardingChecklist } from "@app/redux/userData";
import { clearActiveModal } from "@app/redux/modals";
import { showSuccessAlert, showErrorAlertFromErr } from "@app/redux/alert";
import useParams from "@app/hooks/useParams";
import ModalContent from "./Content";

const InvitationModal = () => {
  const dispatch = useDispatch();
  const modals = useSelector(state => state.modals);
  const { data, active } = modals;
  const { autoNag, text } = data;
  const showModal = active === "invite-friends";
  const isLoggedIn = useSelector(state => state.userData.user && state.userData.user.id);
  const [saving, setSaving] = useState(false);
  const [emails, setEmails] = useState(undefined);
  const { removeQueryParams } = useParams();

  useEffect(() => {
    if (showModal && autoNag) {
      ackInviteNag();
    }
  }, [showModal, autoNag]);

  const handleCloseModal = () => {
    removeQueryParams(["modal"]);
    dispatch(clearActiveModal());
    setSaving(false);
  };

  return (
    <Modal className="invite-modal" show={showModal} onHide={handleCloseModal}>
      <Modal.Body>
        <button type="button" onClick={handleCloseModal} className="close" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        {text ? (
          <div>{text}</div>
          ) : (
          <div>
            Do you know women who could use the support and expertise of the Elpha community, or who have knowledge to share? Invite them to join us!
            <br/><br/>
          </div>
        )}
        <p><b>Invite them by:</b></p>
        <ModalContent onCloseModal={handleCloseModal} />
        <br />
      </Modal.Body>
    </Modal>
  );
};
export default InvitationModal;
