import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "@app/redux/userData";
import useParams from "@app/hooks/useParams";

import registerAPI from "@app/redux/register/api";
import { clearActiveModal } from "@app/redux/modals";
import Alert from "@app/redux/alert/Alert";
import classNames from "classnames";
import styles from "./shared.module.scss";
import { showErrorAlertFromErr } from "@app/redux/alert";

import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FieldWrapper from "@app/components/common/FieldWrapper";
import { track } from "@app/components/common/analytics";

const schema = yup.object().shape({
  first_name: yup
    .string()
    .nullable()
    .required("First name is required"),
  last_name: yup
    .string()
    .nullable()
    .required("Last name is required"),
  url: yup
    .string()
    .nullable()
    .required("A URL is required. If you don't have LinkedIn, try Twitter or your website.")
});


const JoinBoxStep2 = () => {
  const dispatch = useDispatch();
  const { mergeQueryParams } = useParams();
  const user = useSelector(state => state.userData.user || {});

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: user,
    resolver: yupResolver(schema),
  });

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const active = useSelector(state => state.userData.active);
  const isRateCompany = user && user.signup_source === "top-workplaces";
  const isPostJob = user && user.signup_source === "post-job";
  const getErrorMessageFor = field => errors[field] && errors[field].message;

  const onSubmit = async (updatedUser) => {
    try {
      setIsSaving(true);
      const { data } = await registerAPI.submitStep2({
        firstName: updatedUser.first_name,
        lastName: updatedUser.last_name,
        applyUrl: updatedUser.url
      });
      const { url, user } = data;
      track("Application Submitted");

      const skipOnboarding = ["rate-company", "post-job"];
      if (skipOnboarding.includes(user.signup_source)) {
        dispatch(logIn(user, active));
        dispatch(clearActiveModal());
        let modal = undefined;
        if (isRateCompany) modal = "rate-company";
        mergeQueryParams({ modal });
      } else if (
        active === "directories-index" ||
        active === "directories-show"
      ) {
        dispatch(clearActiveModal());
      } else {
        window.location = url || "/onboarding/step1";
      }
    } catch (e) {
      setIsSaving(false);
      dispatch(showErrorAlertFromErr(e));
    }
  };

  return (
    <div>
      <div className={styles.step2}>
        <Alert />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classNames("flex-col-middle", styles.form)}
        >
          {errorMessage && <Alert bsStyle="danger">{errorMessage}</Alert>}
          <Row>
            <Col xs={12}>
              <FieldWrapper
                label="First name"
                error={getErrorMessageFor("first_name")}
                required
              >
                <input
                  type="text"
                  className="form-control"
                  {...register("first_name")}
                />
              </FieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FieldWrapper
                label="Last name"
                error={getErrorMessageFor("last_name")}
                required
              >
                <input
                  type="text"
                  className="form-control"
                  {...register("last_name")}
                />
              </FieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FieldWrapper
                label="LinkedIn URL"
                error={getErrorMessageFor("url")}
                required
              >
                <input className="form-control" {...register("url")} />
              </FieldWrapper>
            </Col>
          </Row>
          <button type="submit" className="btn btn-primary" disabled={isSaving}>
            {isSaving ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};
export default JoinBoxStep2;
