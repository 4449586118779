import React from "react";
import { useMediaQuery } from "react-responsive";
import MobileSideBar from "./MobileSideBar";
import DesktopSideBar from "./DesktopSideBar";

const LoggedInSideBar = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)'});
  if (isMobile) return <MobileSideBar />;
  return <DesktopSideBar />;
};
export default LoggedInSideBar;
