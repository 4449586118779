import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isCompaniesPage } from "@app/helpers/navbarHelper";
import MobileButtonGroup from "@app/components/SideBars/LeftSideBar/MobileButtonGroup";


const MobileFirstButtons = () => {
  const active = useSelector(state => state.userData.active);
  const isLoggedIn = useSelector(state => !!(state.userData.user || {}).id);
  const handleClick = link => window.location = link;

  let buttons = [
    {
      index: "jobs",
      glyph: "briefcase",
      caption: "Explore jobs",
      active: ["companies-index", "companies-jobs"].indexOf(active) !== -1,
      onClick: () => handleClick("/jobs")
    },
    {
      index: "rate",
      glyph: "star",
      caption: "Company Ratings Database",
      active: active === "logged_out-top_companies_database",
      onClick: () => handleClick("/top-companies-database")
    }
  ];
  if (isLoggedIn) {
    buttons.push({
      index: "invite",
      glyph: "envelope",
      caption: "Refer a friend ✨",
      active: active === "invitations",
      onClick: () => handleClick("/invite")
    });
    buttons.push({
      index: "your-profile",
      caption: "Your profile",
      glyph: "user",
      active: active === "accounts-show",
      onClick: () => handleClick('/account')
    });
    buttons.push({
      index: "email",
      glyph: "cog",
      caption: "Email settings",
      active: active === "email-settings-edit",
      onClick: () => handleClick("/account/email_settings/edit")
    });
  }
  if (isCompaniesPage(active)) {
    buttons = [
      {
        index: "for-companies",
        glyph: "briefcase",
        caption: "For employers",
        active: active === "companies-partnerships",
        onClick: () => handleClick("/companies/partnerships")
      },
    ];
  }
  return <MobileButtonGroup buttons={buttons} isLoading={false} />;
};
export default MobileFirstButtons;
