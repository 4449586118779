import React, { useEffect, Suspense } from "react"
import { setActiveModal } from "@app/redux/modals";
import { useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom"
import { lazyLoadFallbackUI } from "@app/helpers/lazyLoadHelper"
import { isPageWithSSR, isPageWithTwoSideBars, isPageWithOneSideBar, isPageWithHiringSideBar } from "@app/helpers/sidebarHelper";
import useParams from "@app/hooks/useParams";
import NewPostPage from "../pages/Posts/NewPost";
import LeftSideBar from "../SideBars/LeftSideBar";
import RightSideBar from "../SideBars/RightSideBar";

import TwoColumnWrapper from "./TwoColumnWrapper";
import ThreeColumnWrapper from "./ThreeColumnWrapper";

const EventsShowPage = React.lazy(() => import("../pages/Events/Show"));
const TopCompaniesShowPage = React.lazy(() => import("../pages/Resources/TopCompanies/Show"));
const MembersShowPage = React.lazy(() => import("../pages/Members/Show"));
const EditAccountPage = React.lazy(() => import("../pages/Account/EditAccountPage"));
const ResetPasswordPage = React.lazy(() => import("../pages/Sessions/ResetPassword"));
const PostsFeed = React.lazy(() => import("../pages/Posts/Feed"));
const DirectoriesIndexPage = React.lazy(() => import("../pages/Directories"));
const DirectoriesShowPage = React.lazy(() => import("../pages/Directories/Show"));
const CompaniesIndexPage = React.lazy(() => import("../pages/Companies"));
const PositionsShowPage = React.lazy(() => import("../pages/Companies/Positions/Show"));
const PositionsIndexPage = React.lazy(() => import("../pages/Companies/Positions"));
const JobDashboardPage = React.lazy(() => import("../pages/JobDashboard/index"))
const InvitationsIndexPage = React.lazy(() => import("../pages/Invitations"));
const EmailSettingsEditPage = React.lazy(() => import("../pages/EmailSettings"));
const OnboardingStepOne = React.lazy(() => import("../pages/Onboarding/StepOne"));
const OnboardingStepTwo = React.lazy(() => import("../pages/Onboarding/StepTwo"));
const OnboardingStepTwoSalaryDatabase = React.lazy(() => import("../pages/Onboarding/StepTwoSalaryDatabase"));
const OnboardingStepThree = React.lazy(() => import("../pages/Onboarding/StepThree"));
const OnboardingStepFour = React.lazy(() => import("../pages/Onboarding/StepFour"));
const OnboardingStepFive = React.lazy(() => import("../pages/Onboarding/StepFive"));
const OnboardingStepSix = React.lazy(() => import("../pages/Onboarding/StepSix"));
const OnboardingStepSeven = React.lazy(() => import("../pages/Onboarding/StepSeven"));
const OnboardingStepEight = React.lazy(() => import("../pages/Onboarding/StepEight"));
const SearchPage = React.lazy(() => import("../pages/Search"));
const NotificationsPage = React.lazy(() => import("../pages/Notifications"));
const MessagesIndexPage = React.lazy(() => import("../pages/Messages"));
const MatchesPage = React.lazy(() => import("../pages/Matches"));

const CommunitiesIndexPage = React.lazy(() => import("../pages/Communities"));
const CommunitiesShowPage = React.lazy(() => import("../pages/Communities/Show"));
const CommunitiesDirectoryShowPage = React.lazy(() => import("../pages/Communities/Directory"));
const CommunitiesExpertShowPage = React.lazy(() => import("../pages/Communities/Experts"));

const MessagesDashAdminPage = React.lazy(() => import("../pages/Admin/MessagesDash"));
const UsersDashAdminPage = React.lazy(() => import("../pages/Admin/UsersDash"));
const PostsDashAdminPage = React.lazy(() => import("../pages/Admin/PostsDash"));
const LinkedInLoginSuccess = React.lazy(() => import("@app/components/Register/LinkedInLoginSuccess"));

const CopyrightDisputePolicy = React.lazy(() => import("../pages/CopyrightDisputePolicy"));
const Guidelines = React.lazy(() => import("../pages/Guidelines"));
const TermsOfService = React.lazy(() => import("../pages/TermsOfService"));
const PrivacyPolicy = React.lazy(() => import("../pages/PrivacyPolicy"));

const HomeModalOverlay = React.lazy(() => import("@app/components/Register/HomeModalOverlay"));
const HomeIndexPage = React.lazy(() => import("../pages/Home"));

const HiringIndexPage = React.lazy(() => import("../pages/Hiring/Home"));
const HiringTeamPage = React.lazy(() => import("../pages/Hiring/Team"));
const HiringCompanyPage = React.lazy(() => import("../pages/Hiring/Company"));
const HiringCompanyProfilePage = React.lazy(() => import("../pages/Hiring/Company/DescriptionPage"));

const HiringIntrosReplyByHashPage = React.lazy(() => import("../pages/Hiring/Intros/ReplyByHashPage"));
const HiringMessagesPage = React.lazy(() => import("../pages/Hiring/Messages"));
const HiringBillingPage = React.lazy(() => import("../pages/Hiring/Billing/Billing"));
const HiringATSIntegrationPage = React.lazy(() => import("../pages/Hiring/ATSIntegration/Index"));
const HiringCandidatesIndexPage = React.lazy(() => import("../pages/Hiring/Candidates"));
const HiringCandidateMatchesIndexPage = React.lazy(() => import("../pages/Hiring/Candidates/Matches"));
const HiringJobsIndexPage = React.lazy(() => import("../pages/Hiring/Jobs"));
const SelfServiceNewJobPage = React.lazy(() => import("../pages/SelfServiceJobs/NewJob"));
const SelfServiceUpdateJobPage = React.lazy(() => import("../pages/SelfServiceJobs/UpdateJob"));
const SelfServiceReviewJobPage = React.lazy(() => import("../pages/SelfServiceJobs/ReviewJob"));
const SelfServiceSuccessPage = React.lazy(() => import("../pages/SelfServiceJobs/Success"));
const SelfServiceCancelPage = React.lazy(() => import("../pages/SelfServiceJobs/Cancel"));

const CandidatesMessageReplyPage = React.lazy(() => import("../pages/Candidates/MessageReplyPage"));
import { RESET_PASSWORD_MODALS } from "@app/components/common/SetPasswordModal";

const Content = ({
  userData,
  handleError,
  handleSuccess,
  handleSignInClick,
  handleJoinClick,
}) => {
  // TODO managing the invitation modal does not belong here. Quick fix hack to revisit.
  const { code, community, user, active, introExpired } = userData;
  const queryParams = new URLSearchParams(window.location.search);
  const { mergeQueryParams, getQueryParam } = useParams();
  const isLoggedIn = user && user.id;
  const dispatch = useDispatch();

  const showInviteHandler = () => {
    // TODO should not be managing modal state in params and redux
    dispatch(setActiveModal({ active: 'invite' }));
    mergeQueryParams({ modal: "invite" });
  };

  useEffect(() => {
    /** hack to show signup modal everwhere except email settings / password reset page **/
    const currentModal = getQueryParam("modal");
    const EMAIL_SETTINGS_PAGES = ["email-settings-unsubscribe", "email-settings-edit"];
    const skipSignup = RESET_PASSWORD_MODALS.includes(currentModal) || EMAIL_SETTINGS_PAGES.includes(userData.active);
    if (user && user.id && !user.application_complete && !skipSignup) {
      dispatch(setActiveModal({ active: "signup", data: { step: 2 }}))
    }
  }, [user]);

  useEffect(() => {
    if (code || queryParams.get("code")) {
      showInviteHandler();
    }
  }, [code, queryParams.get("code")]);

  const renderRouter = () => (
    <Suspense fallback={lazyLoadFallbackUI}>
      <Switch>
        <Route path="/terms">
          <TermsOfService />
        </Route>
        <Route path="/guidelines">
          <Guidelines />
        </Route>
        <Route path="/privacy">
          <PrivacyPolicy />
        </Route>
        <Route path="/copyright">
          <CopyrightDisputePolicy />
        </Route>
        <Route path="/hiring/home">
          <HiringIndexPage />
        </Route>
        <Route path="/hiring/team">
          <HiringTeamPage />
        </Route>
        <Route path="/hiring/company">
          <HiringCompanyPage />
        </Route>
        <Route path="/hiring/company/description">
          <HiringCompanyProfilePage />
        </Route>
        <Route path="/hiring/intros" exact>
          <Redirect to="/hiring/messages/company-intros" />
        </Route>
        <Route
          path={[
            "/hiring/hiring_matches/:uuid/company/accept",
            "/hiring/hiring_matches/:uuid/company/reject",
            "/hiring/hiring_matches/:uuid/candidate/accept",
            "/hiring/hiring_matches/:uuid/candidate/reject"
          ]}
        >
        </Route>

        <Route path="/hiring/intros/:updateHash/accept">
          {introExpired !== true && <HiringIntrosReplyByHashPage acceptOrReject="accept" />}
        </Route>
        <Route path="/hiring/intros/:updateHash/reject">
          {introExpired !== true && <HiringIntrosReplyByHashPage acceptOrReject="reject" />}
        </Route>

        {/**
          * DEPRECATED due to version of these endpoints above that don't
          * require the company slug. Remove these endpoints after a few weeks,
          * when presumably no employers will still be going through old emails
          * to accept/reject intros. (2021-06-30)
        */}
        <Route path="/hiring/companies/:slug/intros/:updateHash/accept">
          {introExpired !== true && <HiringIntrosReplyByHashPage acceptOrReject="accept" />}
        </Route>
        <Route path="/hiring/companies/:slug/intros/:updateHash/reject">
          {introExpired !== true && <HiringIntrosReplyByHashPage acceptOrReject="reject" />}
        </Route>

        <Route path="/hiring/messages">
          <HiringMessagesPage />
        </Route>
        <Route path="/hiring/jobs">
          <HiringJobsIndexPage />
        </Route>
        <Route path="/hiring/message_templates" exact>
          <Redirect to="/hiring/messages/templates" />
        </Route>
        <Route path="/hiring/message_templates/:id/edit">
          <Redirect to="/hiring/messages/templates/:id/edit" />
        </Route>
        <Route path="/hiring/message_templates/new">
          <Redirect to="/hiring/messages/templates" />
        </Route>
        <Route path="/hiring/billing">
          <HiringBillingPage />
        </Route>
        <Route path="/hiring/ats_integration">
          <HiringATSIntegrationPage />
        </Route>
        <Route path="/hiring/candidates/:uuid/intro_requested_without_match"></Route>
        <Route path="/hiring/candidates/:candidate_id/messages/:message_id/:choice">
        </Route>
        <Route path="/hiring/candidates/matches">
          <HiringCandidateMatchesIndexPage />
        </Route>
        <Route path="/hiring/candidates">
          <HiringCandidatesIndexPage />
        </Route>
        <Route path={["/candidates/messages/:uuid/yes", `/companies/:company/candidates/messages/:uuid/yes`]}>
          <CandidatesMessageReplyPage acceptOrReject="accept" />
        </Route>
        <Route path={["/candidates/messages/:uuid/no", `/companies/:company/candidates/messages/:uuid/no`]}>
          <CandidatesMessageReplyPage acceptOrReject="reject" />
        </Route>
        <Route path="/matches">
          <MatchesPage />
        </Route>
        <Route path="/directories">
          <DirectoriesIndexPage
            handleError={handleError}
            handleSuccess={handleSuccess}
            handleSignInClick={handleSignInClick}
            handleJoinClick={handleJoinClick}
          />
        </Route>
        <Route path="/d/:slug">
          <DirectoriesShowPage
            handleError={handleError}
            handleSuccess={handleSuccess}
            handleSignInClick={handleSignInClick}
            handleJoinClick={handleJoinClick}
            userData={userData}
          />
        </Route>
        <Route path="/ss/jobs/:id/cancel">
          <SelfServiceCancelPage/>
        </Route>
        <Route path="/ss/jobs/new/:id">
          <SelfServiceUpdateJobPage/>
        </Route>
        <Route path="/ss/jobs/new">
          <SelfServiceNewJobPage/>
        </Route>
        <Route path="/ss/jobs/:id/success">
          <SelfServiceSuccessPage />
        </Route>
        <Route path="/ss/jobs/:id">
          <SelfServiceReviewJobPage />
        </Route>
        <Route path="/jobs/mine">
          <JobDashboardPage/>
        </Route>
        <Route path={["/jobs", "/jobs/*"]} exact>
          <PositionsIndexPage />
        </Route>
        <Route path="/authenticate/magic_link_v2/:slug/:token">
          <ResetPasswordPage userData={userData} />
        </Route>
        <Route path={["/unsubscribe/:slug", "/account/email_settings/edit"]}>
          <EmailSettingsEditPage />
        </Route>
        <Route path="/messages">
          <MessagesIndexPage />
        </Route>
        <Route path="/notifications">
          <NotificationsPage />
        </Route>
        <Route path={["/account/edit_profile_image", "/c/:slug/edit_profile_image"]}>
          <div className="account-updater col-xs-12 col-sm-6 col-sm-offset-3">
            <div dangerouslySetInnerHTML={{ __html: userData.html }} />
          </div>
        </Route>
        <Route path={["/c/top", "/c/new"]}>
          <CommunitiesIndexPage />
        </Route>
        <Route path="/c/:slug/experts">
          <CommunitiesExpertShowPage />
        </Route>
        <Route path="/expert_sessions/:hash/book/:hash/:status"></Route>
        <Route path="/c/:slug/directory">
          <CommunitiesDirectoryShowPage />
        </Route>
        <Route path="/c/:slug">
          <CommunitiesShowPage />
        </Route>
        <Route path={["/account/edit", "/admin_dash/members/:username/edit"]}>
          <EditAccountPage />
        </Route>
        <Route path="/search">
          <SearchPage />
        </Route>
        <Route path="/invite">
          <InvitationsIndexPage />
        </Route>
        <Route path="/onboarding/step1">
          <OnboardingStepOne />
        </Route>
        <Route path="/onboarding/step2-salary-database">
          <OnboardingStepTwoSalaryDatabase />
        </Route>
        <Route path="/onboarding/step2">
          <OnboardingStepTwo />
        </Route>
        <Route path="/onboarding/step3">
          <OnboardingStepThree />
        </Route>
        <Route path="/onboarding/step4">
          <OnboardingStepFour />
        </Route>
        <Route path="/onboarding/step5">
          <OnboardingStepFive />
        </Route>
        <Route path="/onboarding/step6">
          <OnboardingStepSix />
        </Route>
        <Route path="/onboarding/step7">
          <OnboardingStepSeven />
        </Route>
        <Route path="/onboarding/step8">
          <OnboardingStepEight />
        </Route>
        <Route path={["/companies", "/companies/filter/*"]} exact>
          <CompaniesIndexPage />
        </Route>
        <Route path="/companies/:slug/jobs/:id">
          <PositionsShowPage />
        </Route>
        <Route path="/companies/success">
        </Route>
        <Route path="/companies/partnerships">
        </Route>
        <Route path="/companies/:slug">
        </Route>
        <Route path="/posts/:path/:slug/edit">
          <NewPostPage />
        </Route>
        <Route path="/posts/new">
          <NewPostPage />
        </Route>
        <Route path="/resources/workplaces-for-women-top-10">
          <TopCompaniesShowPage />
        </Route>
        <Route path="/members/anonymous"></Route>
        <Route path={["/account", "/members/:username"]}>
          <MembersShowPage />
        </Route>
        <Route path="/events/job-seekers-chat"></Route>
        <Route path="/events/:slug">
          <EventsShowPage />
        </Route>
        <Route path="/admin_dash/directories/messages">
          <MessagesDashAdminPage />
        </Route>
        <Route path="/admin_dash/users">
          <UsersDashAdminPage />
        </Route>
        <Route path="/admin_dash/new_users">
          <UsersDashAdminPage />
        </Route>
        <Route path="/admin_dash/pending_posts">
          <PostsDashAdminPage />
        </Route>
        <Route path="/auth/linkedin/success">
          <LinkedInLoginSuccess />
        </Route>
        <Route path={["/top", "/new", "/best_of", "/all", "/i/:code", "/invitations/:code"]}>
          {community ? <CommunitiesShowPage /> : <PostsFeed />}
        </Route>
        <Route path="/" exact>
          <>
            <HomeIndexPage />
            <HomeModalOverlay />
          </>
        </Route>
      </Switch>
    </Suspense>
  ); 

  if (isPageWithSSR(userData)) {
    return null;
  }
  if (isPageWithTwoSideBars({ user, active })) {
    return (
      <ThreeColumnWrapper>
        <LeftSideBar />
        {renderRouter()}
        <RightSideBar />
      </ThreeColumnWrapper>
    );
  }
  if (isPageWithOneSideBar({ user, active })) {
    return (
      <TwoColumnWrapper>
        <LeftSideBar />
        {renderRouter()}
      </TwoColumnWrapper>
    );
  }
  return (
    <div>
      <div className="visible-xs"><LeftSideBar /></div>
      {renderRouter()}
    </div>
  );
};

export default Content;
