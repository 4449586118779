import { AtomicBlockUtils, EditorState } from "draft-js";
import React, { useRef } from "react";
import { ToolbarChildrenProps } from "@draft-js-plugins/static-toolbar/lib/components/Toolbar";

import styles from "./index.module.scss";
import { Button, Glyphicon } from "react-bootstrap";

const AddImageField: React.FC<ToolbarChildrenProps> = ({
  theme,
  getEditorState,
  setEditorState,
  onOverrideContent,
}) => {
  const inputRef = useRef<HTMLInputElement>();

  const insertImage = (src: string) => {
    const editorState = getEditorState();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  const closeAddImageField = () => {
    inputRef.current.value = "";
    onOverrideContent(undefined);
  };

  const handleClickAdd = () => {
    const imgSrc = inputRef.current.value;
    const newEditorState = insertImage(imgSrc);
    setEditorState(newEditorState);

    closeAddImageField();
  };

  return (
    <div className={styles.root}>
      Image URL:
      <input
        type="url"
        ref={inputRef}
        placeholder="https://example.com/photo.jpg"
        className={styles.input}
      />
      <div className={theme.buttonWrapper}>
        <button onClick={handleClickAdd} className={theme.button}>
          <Glyphicon glyph="plus" />
        </button>
      </div>
      <div className={theme.buttonWrapper}>
        <button onClick={closeAddImageField} className={theme.button}>
          <Glyphicon glyph="remove" />
        </button>
      </div>
    </div>
  );
};

export default AddImageField;
