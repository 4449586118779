import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import CompanyRating from "@app/types/CompanyRating";
import moment from "moment";
import {
  currentEmploymentStatusOpts,
  deptsOpts,
  experienceOpts,
  genderOptions,
  raceEthnicityOptions,
  sexOptions,
} from "@app/components/pages/Companies/utils";
import { jobStatusOpts, activeEmployeeOpts } from "@app/components/common/AddToTopCompaniesDatabase/utils";
import { setCompanyRating } from "@app/redux/companyRatings";
import parseError from "@app/helpers/parseError";
import { track } from "@app/components/common/analytics";
import accountAPI from "@app/redux/account/api";
import User from "@app/types/User";
import { setCompanyRatingSubmitted } from "@app/redux/userData";


export interface State {
  errorMessage?: string;
  isSaving?: boolean;
}

const initialState: State = {
  errorMessage: undefined,
  isSaving: false,
};

const form = createSlice<State, SliceCaseReducers<State>>({
  name: "companyRating/form",
  initialState,
  reducers: {
    setErrorMessage(state, action: PayloadAction<string | undefined>) {
      state.errorMessage = action.payload;
    },
    setIsSaving(state, action: PayloadAction<boolean | undefined>) {
      state.isSaving = action.payload;
    },
  },
});

export const { setErrorMessage } = form.actions;

const getSaveCompanyRatingPayload = (companyRating: CompanyRating) => {
  const getRatingValue = (key) => {
    if (companyRating[key] && companyRating[key].length > 0) {
      return companyRating[key][0];
    }
    return companyRating[key];
  };


  return {
    company: companyRating.company,

    activeEmployee: (activeEmployeeOpts.find(o => o.value === companyRating.activeEmployee) || {}).value,
    employmentStatus: (jobStatusOpts.find(o => o.value === companyRating.employmentStatus) || {}).value,
    lastYearEmployed: companyRating.lastYearEmployed,
    rating: companyRating.rating,
    review: companyRating.review,

    title: companyRating.title,
    department: (deptsOpts.find(o => o.value === companyRating.department) || {}).label,
    executive: parseBoolean(companyRating.executive),

    womanManager: parseBoolean(companyRating.womanManager),
    womenInLeadership: companyRating.womenInLeadership,
    leadershipReceptiveToFeedback: companyRating.leadershipReceptiveToFeedback, 
    mentorSponsor: companyRating.mentorSponsor,
    networking: companyRating.networking,
    fairlyCompensated: companyRating.fairlyCompensated,
    workLife: companyRating.workLife,


    pto: companyRating.pto,
    leadersTakePto: companyRating.leadersTakePto,
    matLeave: parseBoolean(companyRating.matLeave),
    patLeave: parseBoolean(companyRating.patLeave),
    enoughPaidLeave: companyRating.enoughPaidLeave,
    takeLeave: parseBoolean(companyRating.takeLeave),
    leaveImpactedSucessActual: getRatingValue("leaveImpactedSucessActual"),
    leaveImpactedSucessPredicted: getRatingValue("leaveImpactedSucessPredicted"),
    flexibleWork: getRatingValue("flexibleWork"),
    wfh: getRatingValue("wfh"),

    sexismHarassment: getRatingValue("sexismHarassment"),
    microaggressions: getRatingValue("microaggressions"),
    genderSuccess: getRatingValue("genderSuccess"),


    experience: (experienceOpts.find(o => o.value === companyRating.experience) || {}).label,


    raceEthnicity: (companyRating.raceEthnicity || []).map(raceEthOpt => raceEthnicityOptions.find(o => o.value === raceEthOpt).label),
    age: companyRating.age,
    city: companyRating.location.city,
    state: companyRating.location.state,
    country: companyRating.location.country_short,
    gender: (genderOptions.find(o => o.value === companyRating.gender) || {}).label,
    sexualOrientation: (companyRating.sexualOrientation || []).map(sexOpt => sexOptions.find(o => o.value === sexOpt).label),

    anything: companyRating.anything,

    submittedOn: moment().format("MM/DD/YYYY"),
  };
};

const parseBoolean = value => {
  if (value === true) return "Yes";
  if (value === false) return "No";
  return null;
};
export const saveCompanyRating = (
  companyRating: CompanyRating,
  onSuccess: Function,
  onError: Function,
) => async dispatch => {
  const { setIsSaving } = form.actions;
  dispatch(setIsSaving(true));
  dispatch(setCompanyRating(companyRating));
  try {
    const companyRatingPayload = getSaveCompanyRatingPayload(companyRating);
    const { data } = await accountAPI.submitCompanyRating(companyRatingPayload);
    track("Company rating data added");
    dispatch(setCompanyRatingSubmitted(true))
    onSuccess && onSuccess();
  } catch (e) {
    const message = parseError(e, "There was an error saving your information. Please try again.");
    dispatch(setErrorMessage(message));
    onError && onError(message);
  } finally {
    dispatch(setIsSaving(false));
  }
};

export default form.reducer;
