import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

/**
 * @typedef Tab
 * @property {string} label - The machine name of the tab, for checking whether
 * it is currently selected.
 * @property {string} title - The human-readable tab title.
 * @property {function} [onClick] - If provided, the tab will be rendered as an
 * `<a />` tag with this `onClick` handler attached to it. If not provided, `to`
 * must be provided.
 * @property {string} [to] - If provided, the tab will be rendered as a
 * react-router `<Link />`, using this `to` value as the `to` prop for the
 * `<Link />`. If not, provided, `onClick` must be provided.
 */

/**
 * @param {object} props
 * @param {string} props.selectedTab - The label of the tab that should be
 * selected.
 * @param {Tab[]} props.tabs - The tabs to render.
 */
const Tabs = ({ selectedTab, tabs, extra, clazz }) => (
  <div className={classNames("elpha-tabs", "elpha-tabs-scroll", clazz)}>
    <div className="elpha-tabs-container">
      <div className="elpha-tabs-scroll-container">
        {tabs.map(({ label, title, onClick, to }, index) => {
          const className = `elpha-tab ${
            selectedTab === label ? "active" : ""
          }`;
          if (to) {
            return (
              <Link key={index} to={to} className={className}>
                {title}
              </Link>
            );
          }
          return (
            <span
              onClick={() => onClick && onClick(label)}
              key={index}
              className={className}
            >
              {title}
            </span>
          );
        })}
        {extra || null}
      </div>
    </div>
  </div>
);

export default Tabs;
