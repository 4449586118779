import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { showErrorAlertFromErr } from "@app/redux/alert";
import Contact from "./Contact";

const SCOPE = "https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/userinfo.profile"
const ImportContacts = ({ onInvite }) => {
  const [GoogleAuth, setGoogleAuth] = useState(undefined);
  const [isGoogleAuthed, setIsGoogleAuthed] = useState(false);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  const [nextContactsToken, setContactsNextToken] = useState(null);
  const [othersNextToken, setOthersNextToken] = useState(null);
  const [contactsList, setContactsList] = useState([]);
  const [expiresAt, setExpiresAt] = useState(undefined);
  const [accessToken, setAccessToken] = useState(undefined);
  const dispatch = useDispatch();
  const userData = useSelector(state => state.userData);
  const isMobileApp = useSelector(state => state.userData.isMobileApp);
  const { googlePeopleApiKey, googlePeopleClientId } = userData;

  useEffect(() => {
    if (!isMobileApp) loadGoogleScript();
  }, []);

  useEffect(() => {
    if (GoogleAuth && !isMobileApp) {
      GoogleAuth.isSignedIn.listen(setSigninStatus);
      setSigninStatus();
    }
  }, [GoogleAuth]);

  useEffect(() => {
    if (accessToken) {
      loadContacts();
    }
  }, [accessToken]);

  const loadContacts = () => {
    if (isLoadingContacts) {
      return;
    }

    const data = {
      access_token: accessToken,
      others_next_token: othersNextToken,
      contacts_next_token: nextContactsToken,
      load_more: contactsList.length > 0,
      expires_at: expiresAt
    };
    setIsLoadingContacts(true);
    $.ajax({
      url: "/account/contacts",
      method: "POST",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify(data),
      success: ({ others, others_next_token, contacts, contacts_next_token }) => {
        setOthersNextToken(others_next_token);
        setContactsNextToken(contacts_next_token);
        setIsLoadingContacts(false);
        const newContactsList = Array.of(...contactsList, ...contacts, ...others);
        setContactsList(newContactsList);
      },
      error: e => {
        dispatch(showErrorAlertFromErr(e));
        setIsLoadingContacts(false);
      }
    });
  };

  const loadGoogleScript = () => {
    return new Promise(function(resolve, reject){
      var script = document.createElement('script');
      script.src = "https://apis.google.com/js/api.js";
      script.addEventListener("load", () => gapi.load("client:auth2", initClient));
      script.addEventListener("error", () => console.log("ERROR"));
      document.body.appendChild(script);
    })
  };

  const initClient = () => {
    gapi.client.init({
        "apiKey": googlePeopleApiKey,
        "clientId": googlePeopleClientId,
        "discoveryDocs": ["https://people.googleapis.com/$discovery/rest?version=v1"],
        "scope": SCOPE
    }).then(() => {
      setGoogleAuth(gapi.auth2.getAuthInstance());
    }, (error) => {
      console.log(error);
    });
  };

  const handleAuthClick = () => GoogleAuth.signIn();

  const setSigninStatus = () => {
    const user = GoogleAuth.currentUser.get();
    if (user) {
      setIsGoogleAuthed(user.hasGrantedScopes(SCOPE));
    }
    if (user && !accessToken) {
      setAccessToken(user.getAuthResponse().access_token);
      setExpiresAt(user.getAuthResponse().expires_at);
    }
  };

  if (isMobileApp) return null;

  if (contactsList.length) {
    return (
      <>
        <div className="contacts-container overflow-scroll-container">
          <InfiniteScroll
            pageStart={0}
            loadMore={loadContacts}
            hasMore={othersNextToken || nextContactsToken}
            loader = {<div onClick={loadContacts} className="contacts-loader">Load more contacts</div>}
          >
            {contactsList.map((contact, index) => <Contact onInvite={onInvite} contact={contact} key={index} />)}
          </InfiniteScroll>
        </div>
        <hr/>
      </>
    );
  }
  if (GoogleAuth && !isGoogleAuthed) {
    return (
      <button
        disabled={!GoogleAuth}
        onClick={handleAuthClick}
        className="btn-full btn btn-default btn-contacts"
      >
        Invite contacts through gmail
      </button>
    );
  }
  if (isLoadingContacts) {
    return (
      <button
        disabled={true}
        className="btn-full btn btn-default btn-contacts"
      >
        Loading your contacts...
      </button>
    );
  }
  return <></>;
}

export default ImportContacts;
