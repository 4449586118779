import React from "react"
import { Col, Row } from "react-bootstrap";

const ThreeColumnWrapper = ({ children }) => {
  return (
    <Row>
      <Col sm={4} md={3}>{children[0]}</Col>
      <Col sm={4} md={6} mdOffset={0} >{children[1]}</Col>
      <Col sm={4} md={3}>{children[2]}</Col>
    </Row>
  );
};
export default ThreeColumnWrapper;
