/**
 * To open this modal, dispatch a `setActiveModal` Redux action with an object
 * containing an `active` key set to `signup`:
 *
 * ```
 * dispatch(setActiveModal({ active: "book-office-hours" }));
 * ```
 *
 * You can also add a `data` key to the object to set the click source. This
 * will be used to determine what text to show the user:
 *
 * ```
 * dispatch(setActiveModal({ active: "book-office-hours", data: { clickSource: "posts-index" } }));
 * ```
 *
 * Note that this modal will only show up if the user is logged out. That is,
 * dispatching the action as described above will have no effect for logged-in
 * users.
 */
import React from "react"
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap"
import JoinBox from "../JoinBox"
import AlreadyAMemberButton from "@app/components/Register/AlreadyAMemberButton";
import SupporterButton from "@app/components/Register/SupporterButton";
import styles from "./index.module.scss";
import { track } from "@app/components/common/analytics"
import useParams from "@app/hooks/useParams";
import constants from "./constants";

const CloseXOut = ({ onClick }) => (
  <button type="button" onClick={onClick} className="close" aria-label="Close">
    <span aria-hidden="true">×</span>
  </button>
);
const Caption = () => (
  <p className={styles.caption}>* Your membership must be approved before scheduling your coaching session.</p>
);

const ActionsContainer = ({ activeModal }) => (
  <>
    <div className={styles.joinBoxContainer}>
      <JoinBox ctaOverride="Become a member" />
      <AlreadyAMemberButton />
      <SupporterButton />
    </div>
    {activeModal === constants.BOOK_OFFICE_HOURS && <Caption />}
  </>
);

const Title = ({ text }) => (
  <div className={styles.title}>{text}</div>
);

const Body = ({ text }) => (
  <div className={styles.body}><p>{text}</p></div>
);

const SpecialSignUpModal = () => {
  const activeModal = useSelector(state => state.modals.active);
  const { removeQueryParams } = useParams();

  const showModal = constants.PAGES_ARRAY.indexOf(activeModal) !== -1;
  const isLoggedIn = useSelector(state => state.userData.user && state.userData.user.id);

  const titleText = isQuiz ? constants.QUIZ_RESULTS_TITLE : constants.OFFICE_HOURS_TITLE;

  const isQuiz = constants.QUIZ_RESULTS_ARR.indexOf(activeModal) !== -1;
  const quizBody = isLoggedIn ? constants.LOGGED_IN_BODY : constants.LOGGED_OUT_BODY;
  const bodyText = isQuiz ? quizBody : constants.OFFICE_HOURS_BODY;

  if (showModal) {
    gtag("event", "screen_view", { screen_name: "Sign Up Modal" })
    track("Sign Up Modal", { source: activeModal })
  }

  const handleClose = () => removeQueryParams(["modal"]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
    >
      <Modal.Body>
        {isLoggedIn && <CloseXOut onClick={handleClose} />}
        <Title text={titleText} />
        <Body text={bodyText} />
        {!isLoggedIn && <ActionsContainer activeModal={activeModal} />}
      </Modal.Body>
    </Modal>
  );
}
export default SpecialSignUpModal;
