import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faImage } from '@fortawesome/free-solid-svg-icons';

const ImageUploader = ({ onChange, picture = undefined, buttonOnly = false, showBackground = true }) => {
  const reader = new FileReader();
  reader.addEventListener(
    "load",
    () => {
      // convert image file to base64 string
      onChange(reader.result);
    },
    false
  );

  const handleDelete = () => {
    onChange(undefined);
  };

  const onDrop = useCallback(
    files => {
      const newPicture = Array.from(files)[0];
      reader.readAsDataURL(newPicture);
    },
    [reader]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (picture) {
    return (
      <div className="image-preview">
        <img className="image-preview-actual" src={picture} />
        {showBackground && <img className="image-preview-background" src={picture} />}
        <span className="image-trash" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </span>
      </div>
    );
  }

  if (buttonOnly) {
    return (
      <span className="btn image-drop-btn" {...getRootProps()}>
        <input {...getInputProps()} />
        <FontAwesomeIcon icon={faImage} />
      </span>
    );
  }
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <div className="image-preview-drop active">Drop image here</div>
      ) : (
        <div className="image-preview-drop">Drop image here, or click to select a file</div>
      )}
    </div>
  );
};
export default ImageUploader;
