import { combineReducers } from "redux";

import account from "./account";
import alert from "./alert";
import articles from "./articles";
import atsIntegration from "./atsIntegration";
import candidate from "./candidate";
import events from "./events";
import hiring from "./hiring";
import messagesData from "./messagesData";
import modals from "./modals";
import sidebar from "./sidebar";
import userData from "./userData";
import introRequests from "./introRequests";
import community from "./community";
import post from "./post";
import comment from "./comment";
import salaryInfo from './salaryInfo';
import companyRatings from "./companyRatings";

export default combineReducers({
  account,
  alert,
  articles,
  atsIntegration,
  candidate,
  events,
  hiring,
  messagesData,
  modals,
  sidebar,
  userData,
  introRequests,
  community,
  post,
  comment,
  salaryInfo,
  companyRatings,
});
