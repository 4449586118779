import React from "react"
import classNames from "classnames";
import styles from "./index.module.scss";

const Spinner = ({ clazz }) => {
  return (
    <div>
      <div className={classNames(clazz, styles.ring)} />
    </div>
  );
};
export default Spinner;
