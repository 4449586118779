import axios from "axios";

const getCookie = name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return decodeURIComponent(
      parts
        .pop()
        .split(";")
        .shift()
    );
  }
  return null;
};

export const getCsrfToken = () => {
  return getCookie("CSRF-TOKEN");
};

export const setCSRFTokenForRequest = action => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = getCsrfToken();
  axios.defaults.headers.common.Vary = "X-Requested-With";
  return action();
};
