
export const TOTAL_STEPS = 1;
export const DONE_STEP = "thank-you";
export const ONE_LINER = "Once you've submitted your details, you'll have access to thousands of real company ratings from Elpha members.";
export const getErrorMessageFor = (field, errors) => errors[field] && errors[field].message;

export const ratingOpts = [
  {value: "0", label: "0"},
  {value: "1", label: "1"},
  {value: "2", label: "2"},
  {value: "3", label: "3"},
  {value: "4", label: "4"},
  {value: "5", label: "5"},
  {value: "6", label: "6"},
  {value: "7", label: "7"},
  {value: "8", label: "8"},
  {value: "9", label: "9"},
  {value: "10", label: "10"},
];

export const activeEmployeeOpts = [
  { label: "Yes, I'm a current employee", value: "Current employee" },
  { label: "No, I'm a former employee", value: "Former employee" }
];

export const jobStatusOpts = [
  { label: "Full-time", value: "Full-time" },
  { label: "Part-time", value: "Part-time" },
  { label: "Freelance / Contract", value: "Freelance / Contract" },
  { label: "Internship", value: "Internship" }
];
