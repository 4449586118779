import { memberCount } from "@app/constants/text";
const TAKE_THE_NEXT_STEP = `Take the next step at work and join ${memberCount} women working in tech on Elpha.`;
const LATEST_HAPPENINGS = "While you're here, check out the latest happenings on Elpha.";

const BOOK_OFFICE_HOURS = "book-office-hours";
const SHOULD_QUIT_JOB_QUIZ = "should-quit-job-quiz";
const AM_I_UNDERPAID_QUIZ = "am-i-underpaid-at-work-quiz";

export default {
  BOOK_OFFICE_HOURS,
  SHOULD_QUIT_JOB_QUIZ,
  AM_I_UNDERPAID_QUIZ,
  PAGES_ARRAY: [BOOK_OFFICE_HOURS, SHOULD_QUIT_JOB_QUIZ, AM_I_UNDERPAID_QUIZ],
  QUIZ_RESULTS_ARR: [SHOULD_QUIT_JOB_QUIZ, AM_I_UNDERPAID_QUIZ],
  QUIZ_RESULTS_TITLE: "Your results are on the way 🙌",
  OFFICE_HOURS_TITLE: "You're on the list",
  OFFICE_HOURS_BODY: "Congrats! You are on the list for free Office Hours with an Elpha Career Coach. To schedule your 15 minute session, become an Elpha member (it's also free!). *",
  LOGGED_OUT_BODY: `While you're here – are you ready to level up? Take the next step at work and join ${memberCount} women working in tech on Elpha.`,
  LOGGED_IN_BODY: "We've sent you an email with your results. While you're here, check out the latest happenings on Elpha."
};
