import React from "react"

const CloseModalButton = ({ onClick }) => {
  return (
    <button
        type="button"
        onClick={onClick}
        className="close"
        aria-label="Close"
    >
        <span aria-hidden="true">×</span>
    </button>
  );
};
export default CloseModalButton;
