import React from "react";
import { useSelector } from "react-redux";

import LoggedInSideBar from "./LoggedIn";
import LoggedOutSideBar from "./LoggedOut";

const LeftSideBar = () => {
  const isLoggedIn  = useSelector(state => state.userData.user && state.userData.user.id);
  return isLoggedIn ? <LoggedInSideBar /> : <LoggedOutSideBar />;
};

export default LeftSideBar;
