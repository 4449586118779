import React, { useState } from "react";
import classNames from "classnames";
import { Glyphicon } from "react-bootstrap";
import Thumbnail from "@app/components/common/Thumbnail";
import Toggle from "../Toggle";
import styles from "./index.module.scss";

const Button = props => {
  const {
    caption,
    glyph,
    emoji,
    onClick,
    active,
    thumbnail,
    body
  } = props;

  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);
  const handleClick = () => onClick && onClick();

  const renderThumbnail = () => {
    if (thumbnail) {
      return (
        <span className={styles.thumbContainer}>
          {body && <Toggle show={show} onClick={handleToggle} />}
          {thumbnail && (
            <Thumbnail imgUrl={thumbnail} onClick={handleClick} clazz={styles.thumbnail}/>
          )}
        </span>
      );
    }
    return null;
  };

  return (
    <div
      onClick={handleClick}
      className={classNames(
        styles.button,
        {
          [styles.active]: active,
          [styles.toggle]: thumbnail && body,
          [styles.withBody]: show && body
        }
      )}
    >
      {glyph && <span className={styles.glyph}><Glyphicon glyph={glyph} /></span>}
      {renderThumbnail()}
      <span className={styles.caption}>{caption}</span>
      {show && body}
    </div>
  );
};
export default Button;
