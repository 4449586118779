import React from "react";
import Select from "react-select";
import classNames from "classnames";

const ReactSelectWrapper = ({
  value,
  placeholder,
  isMulti,
  isDisabled,
  onChange,
  options,
  isClearable,
  className
}) => (
  <Select
    className={classNames("Select", className)}
    classNamePrefix="Select"
    placeholder={placeholder}
    isMulti={isMulti}
    isDisabled={isDisabled}
    isClearable={isClearable}
    value={value}
    options={options}
    onChange={onChange}
    getOptionLabel={({ label }) => label}
    getOptionValue={({ value }) => value}
  />
);
export default ReactSelectWrapper;
