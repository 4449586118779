import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import SalaryInfo from "@app/types/SalaryInfo";
import User from "@app/types/User";
import combineSlices from "@app/helpers/combineSlices";
import {
  companySizesOptsV2,
  companyTypeOpts,
  currentEmploymentStatusOpts,
  deptsOpts,
  educationOptions,
  experienceOpts,
  genderOptions,
  raceEthnicityOptions,
  roundsOpts,
  sexOptions,
} from "@app/components/pages/Companies/utils";
import parseError from "@app/helpers/parseError";
import accountAPI from "@app/redux/account/api";
import { setSalarySubmitted } from "@app/redux/userData";


export interface State {
  salaryInfo?: SalaryInfo;
  form: {
    errorMessage?: string;
    isSaving?: boolean;
  }
}

const initialState: State = {
  salaryInfo: undefined,
  form: {
    errorMessage: undefined,
    isSaving: false,
  }
};

const salaryInfo = createSlice<State, SliceCaseReducers<State>>({
  name: "salaryInfo",
  initialState,
  reducers: {
    setSalaryInfo(state, action: PayloadAction<SalaryInfo | undefined>) {
      state.salaryInfo = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<string | undefined>) {
      state.form.errorMessage = action.payload;
    },
    setIsSaving(state, action: PayloadAction<boolean | undefined>) {
      state.form.isSaving = action.payload;
    },
  },
});


const getSaveSalaryInfoPayload = (salaryInfo: SalaryInfo) => {
  return {
    yearly_pay: salaryInfo.yearly_pay,
    total_comp: salaryInfo.total_comp,
    equity_received: salaryInfo.equity_received,
    equity_desc: salaryInfo.equity_desc,
    bonus_pay: salaryInfo.bonus_pay,
    did_negotiate: salaryInfo.did_negotiate ? "Yes" : "No",
    negotiation_success: salaryInfo.negotiation_success ? "Yes" : "No",
    experience: experienceOpts.find(o => o.value === salaryInfo.experience).label,
    department: deptsOpts.find(o => o.value === salaryInfo.work_type).label,
    title: salaryInfo.title,
    age: salaryInfo.age,
    city: salaryInfo.location.city,
    state: salaryInfo.location.state,
    country: salaryInfo.location.country_short,
    race_ethnicity: salaryInfo.race_ethnicity.map(raceEthOpt => raceEthnicityOptions.find(
      o => o.value === raceEthOpt).label),
    gender: salaryInfo.gender.map(genderOpt => genderOptions.find(o => o.value === genderOpt).label),
    sex: salaryInfo.sex.map(sexOpt => sexOptions.find(o => o.value === sexOpt).label),
    education: educationOptions.find(o => o.value === salaryInfo.education).label,
    job_status: currentEmploymentStatusOpts.find(o => o.value === salaryInfo.job_status).label,
    company_type: salaryInfo.company_type ? salaryInfo.company_type.map(companyTypOpt => companyTypeOpts.find(
      o => o.value === companyTypOpt).label) : [],
    round_type: salaryInfo.round_type ? salaryInfo.round_type.map(companyStageOpt => roundsOpts.find(
      o => o.value === companyStageOpt).label) : [],
    company_size: salaryInfo.company_size ? (companySizesOptsV2.find(
      o => o.value === salaryInfo.company_size).label) : "",
    rating: (salaryInfo.rating && salaryInfo.rating.length > 0) ? salaryInfo.rating[0] : salaryInfo.rating
  };
};

export const saveSalaryInfo = (
  salaryInfo: SalaryInfo,
  onSuccess: Function,
  onError: Function,
  isFinalStep: boolean = false
) => async dispatch => {
  dispatch(setIsSaving(true));
  dispatch(setSalaryInfo(salaryInfo));
  if (isFinalStep) {
    try {
      const salaryPayload = getSaveSalaryInfoPayload(salaryInfo);
      const { data } = await accountAPI.submitSalary(salaryPayload);
      dispatch(setSalarySubmitted(true))
      onSuccess && onSuccess();
    } catch (e) {
      const message = parseError(e, "There was an error saving your information. Please try again.");
      dispatch(setErrorMessage(message));
      onError && onError(message);
    } finally {
      dispatch(setIsSaving(false));
    }
  } else {
    onSuccess && onSuccess();
    dispatch(setIsSaving(false));
  }
};

export const { setErrorMessage, setIsSaving, setSalaryInfo } = salaryInfo.actions;

export default combineSlices(salaryInfo.reducer, initialState, {});
