import { createSlice } from "@reduxjs/toolkit";
import communityAPI from "./api";
import { showErrorAlertFromErr } from "../alert";

const community = createSlice({
  name: "community",
  initialState: {
    slug: undefined,
    name: undefined,
    description: undefined,
    guidelines: undefined,
    saving: false,
    loaded: false,
    editing: false,
  },
  reducers: {
    setCommunity(state, action) {
      state = action.payload;
      state.loaded = true;
      return state;
      // state.slug = action.payload.slug;
      // state.name = action.payload.name;
      // state.description = action.payload.description;
      // state.guidelines = action.payload.guidelines;
    },
    setSaving(state, action) {
      state.saving = action.payload;
    },
    updateCommunityValues(state, action) {
      state = { ...state, ...action.payload }
      return state;
    },
    setEditing(state, action) {
      state.editing = action.payload;
      return state;
    },
  },
});

export const {
  updateCommunityValues,
  setEditing
} = community.actions;

export const updateCommunity = () => async (dispatch, getState) => {
  const { updateCommunityValues, setSaving } = community.actions;
  const { slug, name, description, guidelines } = getState().community;
  dispatch(setSaving(true));
  try {
    const data = await communityAPI.updateCommunity(slug, name, description, guidelines);
    dispatch(updateCommunityValues({ name, description, guidelines }));
    dispatch(setEditing(false));
    dispatch(setSaving(false));
  } catch (e) {
    dispatch(setSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const initializeCommunity = () => async (dispatch, getState) => {
  const { setCommunity, setSaving } = community.actions;
  try {
    const data = document.getElementById("data");
    const stringData = data && data.getAttribute("data-react");
    const parsedData = JSON.parse(stringData || "{}");
    dispatch(setCommunity(parsedData.community));
  } catch (e) {
    // NOOP
  }
};

export const leaveCommunity = () => async (dispatch, getState) => {
  const { setCommunity, setSaving } = community.actions;
  const { slug } = getState().community;
  dispatch(setSaving(true));
  try {
    const { data } = await communityAPI.leaveCommunity(slug);
    dispatch(setCommunity(data.community));
    dispatch(setSaving(false));
  } catch (e) {
    dispatch(setSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const joinCommunity = () => async (dispatch, getState) => {
  const { setCommunity, setSaving } = community.actions;
  const { slug } = getState().community;
  dispatch(setSaving(true));
  try {
    const { data } = await communityAPI.joinCommunity(slug);
    dispatch(setCommunity(data.community));
    dispatch(setSaving(false));
  } catch (e) {
    dispatch(setSaving(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export default community.reducer;
