import axios from "axios";

import AcceptOrReject from "@app/types/AcceptOrReject";
import { setCSRFTokenForRequest } from "@app/api/utils";

const candidatesAPI = {
  fetchCandidate: (viewas: string) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;
    return setCSRFTokenForRequest(() => {
      return axios.get("/candidates/me.json", { params: params })
    });
  },

  fetchDashboardMatches: (viewas: string) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;
    return setCSRFTokenForRequest(() => {
      return axios.get("/candidates/dashboard_matches.json", { params: params });
    })
  },

  fetchSavedPositions: (viewas: string) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;
    return setCSRFTokenForRequest(() => {
      return axios.get("/candidates/dashboard_saved.json", { params: params });
    })
  },

  fetchInterviewingPositions: (viewas: string) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;
    return setCSRFTokenForRequest(() => {
      return axios.get("/candidates/dashboard_interviewing.json", { params: params });
    })
  },

  fetchAppliedPositions: (viewas: string) => {
    const params: { viewas?: string } = {};
    if (viewas) params.viewas = viewas;
    return setCSRFTokenForRequest(() => {
      return axios.get("/candidates/dashboard_applied.json", { params: params });
    })
  },

  /**
   * @param {object} candidate - The candidate object to save. If it has an `id`
   * property, it will be updated in the database; otherwise, it will be
   * created.
   * @param {boolean} [isFinalStep] - Whether this is the final step of the
   * form. If `true`, an extra param will be sent to the backend to fire off job
   * alerts to the user.
   */
  saveCandidate: (candidate, isFinalStep = false) => {
    return setCSRFTokenForRequest(() => {
      if (candidate.id) {
        return axios.put("/candidates.json", {
          candidate,
          process_position_recs: isFinalStep,
        });
      } else {
        // This only happens on the first step, so, no need to set
        // `process_position_recs`.
        return axios.post("/candidates.json", { candidate });
      }
    });
  },

  parseResume: (fileName: string, resumeBase64: string, lastModified: Date) => {
    return setCSRFTokenForRequest(() => {
      return axios.post("/candidates/parse_resume.json", {
        file_name: fileName,
        resume_base64: resumeBase64,
        last_modified: lastModified.toISOString(),
      });
    });
  },

  replyToCandidateMessageByUUID: ({
    acceptOrReject,
    rejectionReason,
    rejectionReasonOther,
    identifier,
  }: {
    acceptOrReject: AcceptOrReject;
    identifier: string;
    rejectionReason?: string;
    rejectionReasonOther?: string;
  }) => {
    return setCSRFTokenForRequest(() => {
      return axios.get(
        `/candidates/messages/${identifier}/${
          acceptOrReject === "accept" ? "yes" : "no"
        }.json`,
        {
          params: {
            rejection_reason: rejectionReason,
            rejection_reason_other: rejectionReasonOther,
          },
        }
      );
    });
  },
};

export default candidatesAPI;
