import { createSlice } from "@reduxjs/toolkit";

import combineSlices from "@app/helpers/combineSlices";
import hiringAPI from "@app/redux/hiring/api";
import matches from "./matches";
import { showErrorAlertFromErr } from "@app/redux/alert";
import AcceptOrReject from "@app/types/AcceptOrReject";
import parseError from "@app/helpers/parseError";
import { setItems } from "../companyIntros";

const dateTimeFormatterForCandidateMessages = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});

const initialState = {
  totalCount: undefined,
  candidates: [],
  skippedCandidateIds: [],
  savedCandidateIds: [],
  messagedCandidates: [],
  sourcedByElpha: {},
  isLoading: true,
};

const candidates = createSlice({
  name: "hiring/candidates",
  initialState,
  reducers: {
    addMessage(state, action) {
      const updatedMessages = state.messagedCandidates.slice(0);
      updatedMessages.push(action.payload);
      state.messagedCandidates = updatedMessages;
    },
    saveCandidate(state, action) {
      const updatedCandidateIds = state.savedCandidateIds.slice(0);
      updatedCandidateIds.push(action.payload);
      state.savedCandidateIds = updatedCandidateIds;
      state.candidates = state.candidates.filter(value => value.id != action.payload);
    },
    unsaveCandidate(state, action) {
      var updatedCandidateIds = state.savedCandidateIds.filter(
        value => value !== action.payload
      );
      state.savedCandidateIds = updatedCandidateIds;
      state.candidates = state.candidates.filter(value => value.id != action.payload);
    },
    skipCandidate(state, action) {
      const updatedCandidateIds = state.skippedCandidateIds.slice(0);
      updatedCandidateIds.push(action.payload);
      state.skippedCandidateIds = updatedCandidateIds;
      state.candidates = state.candidates.filter(value => value.id != action.payload);
    },
    unskipCandidate(state, action) {
      var updatedCandidateIds = state.skippedCandidateIds.filter(
        value => value !== action.payload
      );
      state.skippedCandidateIds = updatedCandidateIds;
      state.candidates = state.candidates.filter(value => value.id != action.payload);
    },
    clearTabSettings(state, action) {
      state.totalCount = undefined;
      state.candidates = [];
      state.skippedCandidateIds = [];
      state.savedCandidateIds = [];
      state.messagedCandidates = [];
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    clearCandidates(state, action) {
      state.totalCount = undefined;
      state.candidates = [];
      state.skippedCandidates = [];
      state.savedCandidates = [];
      state.messagedCandidates = [];
    },
    setFilterElement(state, action) {
      const key = action.payload.key;
      const value = action.payload.value;
      state.filters = { ...state.filters, [key]: value };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCandidateData(state, action) {
      state.candidates = action.payload.candidates;
      state.skippedCandidateIds = action.payload.skippedCandidateIds;
      state.savedCandidateIds = action.payload.savedCandidateIds;
      state.messagedCandidates = action.payload.messagedCandidates;
      state.sourcedByElpha = action.payload.sourcedByElpha;
      state.totalCount = action.payload.totalCount;
    },
    replyToMatchFinished(state, action) {
      delete state.sourcedByElpha[action.payload.id];

      // Create a pseudo-message that doesn't actually exist in the database
      // yet, since candidate messages are created asynchronously after "accept"
      // replies are finished.
      if (action.payload.acceptOrReject === "accept") {
        state.messagedCandidates.push({
          id: action.payload.id,
          uuid: action.payload.uuid,
          created_at: dateTimeFormatterForCandidateMessages.format(new Date()),
          status: "Waiting on candidate",
        });
      }
    },
  },
});

export const {
  addMessage,
} = candidates.actions;

export const {
  clearTabSettings,
  setFilters,
  setFilterElement,
  clearCandidates,
  replyToMatchFinished,
} = candidates.actions;

export const messageCandidate = (
  candidateUuid,
  body,
  company,
  source,
  hiringMatchUuid,
) => async (dispatch, getState) => {
  const { addMessage } = candidates.actions;

  try {
    const { data } = await hiringAPI.messageCandidate(
      candidateUuid,
      body,
      company,
      source,
      hiringMatchUuid
    );
    dispatch(addMessage(data.new_message));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export const fetchFilteredCandidates = (filters, tab, offset, viewas) => async (
  dispatch,
  getState
) => {
  const { setIsLoading, setCandidateData } = candidates.actions;

  const newFilters = { ...filters, tab };

  try {
    dispatch(setIsLoading(true));
    const { data } = await hiringAPI.fetchFilteredCandidates(
      newFilters,
      offset,
      viewas
    );

    const {
      saved_candidate_ids,
      skipped_candidate_ids,
      messaged_candidates,
      replyable_intros,
      sourced_by_elpha,
      candidates,
      total_count,
    } = data;
    dispatch(
      setCandidateData({
        messagedCandidates: messaged_candidates,
        skippedCandidateIds: skipped_candidate_ids,
        savedCandidateIds: saved_candidate_ids,
        sourcedByElpha: sourced_by_elpha,
        totalCount: total_count,
        candidates,
      })
    );
    dispatch(setItems({ items: replyable_intros }));
    dispatch(setIsLoading(false));
  } catch (e) {
    dispatch(setIsLoading(false));
    dispatch(showErrorAlertFromErr(e));
  }
};

export const unsaveCandidate = candidateId => async dispatch => {
  const { unsaveCandidate } = candidates.actions;

  try {
    await hiringAPI.unsaveCandidate(candidateId);
    dispatch(unsaveCandidate(candidateId));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export const saveCandidate = candidateId => async dispatch => {
  const { saveCandidate } = candidates.actions;

  try {
    await hiringAPI.saveCandidate(candidateId);
    dispatch(saveCandidate(candidateId));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export const unskipCandidate = candidateId => async dispatch => {
  const { unskipCandidate } = candidates.actions;

  try {
    await hiringAPI.unskipCandidate(candidateId);
    dispatch(unskipCandidate(candidateId));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export const skipCandidate = candidateId => async dispatch => {
  const { skipCandidate } = candidates.actions;

  try {
    await hiringAPI.skipCandidate(candidateId);
    dispatch(skipCandidate(candidateId));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }
};

export default combineSlices(candidates.reducer, initialState, { matches });
