import React from "react";
import { Dropdown, MenuItem } from "react-bootstrap";
import classNames from "classnames"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COMPANY_PAGES } from "@app/helpers/navbarHelper";
import { useMediaQuery } from "react-responsive";

import { dompurify } from "@app/components/common/utils";
import Thumbnail from "@app/components/common/Thumbnail";
import styles from "../shared.module.scss";

const EmployerDropdown = ({ active, user }) => {
  const { thumbnail_url } = user || {};
  const isMobile = useMediaQuery({ query: '(max-width: 768px)'});
  const isTalentAdmin = (user || {}).talent_admin;

  return (
    <div className="btn-group" id="account_menu">
      <Dropdown
        pullRight
        className="nav-profile-dropdown"
        id="EmployerDropdown"
      >
        <Dropdown.Toggle noCaret className="nav-profile-more">
          <span className="nav-profile-container">
            <Thumbnail imgUrl={dompurify.sanitize(thumbnail_url)} />
          </span>
          <div className="nav-profile-more-dd">
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {isMobile && (
            <>
              <MenuItem
                eventKey="home"
                href="/hiring"
                className={classNames({
                  [styles.active]: active === "hiring-index"
                })}
              >
                Home
              </MenuItem>
              <MenuItem
                eventKey="candidates"
                href="/hiring/candidates"
                className={classNames({
                  [styles.active]: active === "candidates-index"
                })}
              >
                Browse candidates
              </MenuItem>
              <MenuItem
                eventKey="messages-home"
                href="/hiring/messages"
                className={classNames({
                  [styles.active]: active === "messages-home"
                })}
              >
                Messages
              </MenuItem>
              <MenuItem
                eventKey="job-postings"
                href="/hiring/jobs"
                className={classNames({
                  [styles.active]: active === "jobs-index" || active === "jobs-show"
                })}
              >
                Job postings
              </MenuItem>
              <MenuItem
                eventKey="company-profile"
                href="/hiring/company"
                className={classNames({
                  [styles.active]: COMPANY_PAGES.includes(active)
                })}
              >
                Company profile
              </MenuItem>
            </>
          )}
          {isTalentAdmin && (
            <>
              <MenuItem
                eventKey="team"
                href="/hiring/team"
                className={active === "hiring-team" ? "active" : ""}
              >
                Manage team
              </MenuItem>

              <MenuItem
                className={active === "ats_integration-home" ? "active" : ""}
                eventKey="ats-integration"
                href="/hiring/ats_integration"
              >
                ATS integration
              </MenuItem>

              <MenuItem
                className={active === "hiring-billing" ? "active" : ""}
                eventKey="billing"
                href="/hiring/billing"
              >
                Billing
              </MenuItem>
            </>
          )}

          <MenuItem divider />

          <MenuItem className="signout" href="/top" eventKey="back-to-elpha">
            Back to elpha.com
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default EmployerDropdown;
