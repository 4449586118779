export const identify = user => {
  if (typeof analytics === 'undefined') {
    return;
  }
  const es = user.email_setting || {};
  const candidate = user.candidate || {};
  const employer = user.employer || {};
  analytics.identify(user.mp_id, {
    created_at: user.created_at,
    created_at_week: user.created_at_week,
    created_at_month: user.created_at_month,
    updated_at: user.updated_at,
    username: user.username,
    userId: user.id,
    email: user.account_email || user.email,
    approved: user.approved,
    slug: user.slug,
    hasApplyUrl: !!user.apply_url,
    hasPicture: !!user.picture_url,
    fullName: user.full_name,
    firstName: user.first_name,
    title: user.title,
    company: user.company,
    rating: user.rating,
    hiring: user.hiring,
    technical: user.technical,
    marketer: user.marketer,
    swe: user.swe,
    designer: user.designer,
    inProduct: user.in_product,
    inOperations: user.in_operations,
    inBD: user.in_biz_dev_or_strategy,
    inCustomerFocusedRole: user.in_customer_focused_role,
    founder: user.founder,
    inAnalytics: user.in_data_science,
    inBayArea: user.bay_area_based,
    identities: user.identities.join(", "),
    approvedAt: user.approved_at,
    firstFeedViewAt: user.first_feed_view_at,
    firstMobileFeedViewAt: user.first_mobile_feed_view_at,
    receiveDigest: es.frequency,
    unsubscribed: es.unsub_all,
    unsubJobs: es.unsub_jobs,
    unsubMemberMatches: es.unsub_member_matches,
    unsubDms: es.unsub_message,
    unsubFun: es.unsub_fun,
    unsubSunday: es.unsub_sunday,
    unsubNotifications: es.get_notifications,
    unsubHash: es.hash,
    referredBy: user.referrer || false,
    lastSeen: user.last_seen,
    code: user.icode,
    points: user.points,
    candidate: !!user.candidate,
    l1_rewards: user.l1_rewards,
    totalKarma: user.karma,
    lastSync: user.last_sync,
    city: user.city,
    country: user.country,
    candidate: user.is_candidate,
    employer: user.employer_name,
    employerTalentTeam: user.talent_team_member,
    employerType: user.employer_partner_type,
    jobHuntingStatus: user.gigs,
    department: user.department_v2,
    experience: user.experience
  });
};

export const alias = user => {
  if (typeof analytics === 'undefined') {
    return;
  }
  analytics.alias(user.mp_id);
};

export const track = (event, data) => {
  if (typeof analytics === 'undefined') {
    return;
  }
  analytics.track(event, data);
};

export const trackCandidateIntroRequest = (company, email, sentByElpha, user, queryParams) => {
  if (typeof analytics === 'undefined') {
    return;
  }
  analytics.track("Candidate Requested Intro v4", {
    CompanyName: company.name,
    CompanyPartnerType: company.partner_type,
    CompanySlug: company.slug,
    CandidateLoggedIn: user && user.id,
    CandidateEmail: email,
    Source: queryParams.get("source"),
    SentByElpha: !!sentByElpha,
    HiringMatchUUID: queryParams.get("hiringMatchUUID")
  });
};

export const trackPostViewed = (post, user, userSlug) => {
  if (typeof analytics === 'undefined') {
    return;
  }
  if (!user && !user.id && userSlug) {
    // Logged out users who arrive with a u parameter set are tracked on the server side instead. That way we can associate the event with the user who matches the u slug.
    return;
  }
  analytics.track("Post Viewed", {
    postId: post.id,
    postTitle: post.title,
    title: post.title,
    group: (post.communities || {}).map(c => c.slug).join(", "),
    createdAt: post.created_at,
    isPublic: post.public_type,
    isFeatured: post.featured
  });
};

export const trackPositionEngagement = ({
  eventName,
  position,
  email,
}) => {
  if (typeof analytics === 'undefined') {
    return;
  }
  analytics.track(eventName, {
    CompanyName: position.company.name,
    CompanySlug: position.company.slug,
    CompanyPartnerType: position.company.partner_type,
    PositionId: position.id,
    PositionDepartment: position.department_v2,
    PositionTitle: position.title,
    PositionUrl: position.url,
    PositionSeniority: position.seniority,
    PositionLocation: position.location,
    Email: email
  });
};
