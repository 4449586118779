import { createSlice } from "@reduxjs/toolkit";

const messagesData = createSlice({
  name: "messages",
  initialState: {
    chatRecipients: [],
    showMessageModal: false,
    sbConnected: false,
  },
  reducers: {
    connectSb(state) {
      state.sbConnected = true;
    },
    newMessage(state) {
      state.showMessageModal = true;
    },
    closeMessage(state) {
      state.showMessageModal = false;
      state.chatRecipients = [];
    },
    startMessage(state, action) {
      state.chatRecipients = action.payload || [];
    },
  },
});

export const {
  connectSb,
  newMessage,
  closeMessage,
  startMessage,
} = messagesData.actions;

export default messagesData.reducer;
