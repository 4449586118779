import axios from "axios";
import $ from "jquery";

import { setCSRFTokenForRequest } from "@app/api/utils";

const communityAPI = {
  updateCommunity: (communitySlug, name, description, guidelines) => {
    return setCSRFTokenForRequest(() => {
      return axios.put(`/c/${communitySlug}`, {
        group: {
          name,
          description,
          guidelines
        }
      });
    });
  },
  sendInvitations: (communitySlug, emails) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/c/${communitySlug}/invitations`, {
        emails: emails.split(",")
      });
    });
  },
  sendExistingMemberInvitation: (communitySlug, username) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/c/${communitySlug}/member_invitations`, {
        username
      });
    });
  },
  leaveCommunity: (communitySlug) => {
    return setCSRFTokenForRequest(() => {
      return axios.delete(`/c/${communitySlug}/members`)
    });
  },
  joinCommunity: (communitySlug) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/c/${communitySlug}/members`)
    });
  },
  fetchPosts: ({
    communitySlug,
    type,
    page,
    isLoggedIn,
    tag
  }) => {
    let url = `/c/${communitySlug}/logged_out/${type}_posts.json`;
    if (isLoggedIn) url = `/c/${communitySlug}/${type}_posts.json`;

    const paramHash = { page: page };

    if (tag) paramHash.tag = tag;
    const params = $.param(paramHash);
    url = `${url}?${params}`;

    return setCSRFTokenForRequest(() => {
      return axios.get(url)
    });
  },
  fetchCommunities: (type, page) => {
    let url = `/c/${type}_communities.json`
    if (page !== 1) url = `${url}?page=${page}`;
    return setCSRFTokenForRequest(() => {
      return axios.get(url)
    });
  },
  fetchMembers: (communitySlug, page, full = false) => {
    return setCSRFTokenForRequest(() => {
      return axios.get(`/c/${communitySlug}/members?page=${page}${full ? '&full=true' : ''}`)
    });
  },
  fetchFeaturedMembers: (communitySlug) => {
    return setCSRFTokenForRequest(() => {
      return axios.get(`/c/${communitySlug}/members?featured=true`)
    });
  },
  fetchLeadMembers: (communitySlug) => {
    return setCSRFTokenForRequest(() => {
      return axios.get(`/c/${communitySlug}/members?leads=true`)
    });
  },
  fetchExperts: (communitySlug, featured = false) => {
    return setCSRFTokenForRequest(() => {
      return axios.get(`/c/${communitySlug}/expert-sessions${featured ? '?featured=true' : ''}`)
    });
  },
  bookSession: ({ communitySlug, expertSessionUuid, why }) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/c/${communitySlug}/expert_sessions/${expertSessionUuid}/book`, { why })
    });
  },
};
export default communityAPI;
