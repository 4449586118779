/**
 * To open this modal, dispatch a `setActiveModal` Redux action with an object
 * containing an `active` key set to `signup`:
 *
 * ```
 * dispatch(setActiveModal({ active: "signup" }));
 * ```
 *
 * You can also add a `data` key to the object to set the click source. This
 * will be used to determine what text to show the user:
 *
 * ```
 * dispatch(setActiveModal({ active: "signup", data: { clickSource: "positions-index" } }));
 * ```
 *
 * Note that this modal will only show up if the user is logged out. That is,
 * dispatching the action as described above will have no effect for logged-in
 * users.
 */
import React, { useEffect } from "react"
import { Modal } from "react-bootstrap"
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import useParams from "@app/hooks/useParams";
import { getStepOneText, getStepTwoText } from "./utils";
import { clearActiveModal } from "@app/redux/modals";
import { ModalCloseXOut, ModalTitle, ModalBody } from "@app/components/common/Modal";
import JoinBox from "@app/components/Register/JoinBox"
import AlreadyAMemberButton from "@app/components/Register/AlreadyAMemberButton";
import SupporterButton from "@app/components/Register/SupporterButton";
import styles from "./index.module.scss";
import { track } from "@app/components/common/analytics"

const JoinModal = () => {
  const dispatch = useDispatch();
  const active = useSelector(state => state.userData.active);
  const inviterName = useSelector(state => state.userData.inviterName);
  const isLoggedIn = useSelector(state => state.userData.user && state.userData.user.id);
  const step = useSelector(state => (state.modals.data || {}).step);
  const showStep1 = useSelector(
    state =>
    (state.modals.active === "signup") && (step !== 2 && !isLoggedIn)
  );
  const showStep2 = useSelector(
    state =>
    (state.modals.active === "signup" && step === 2)
  );

  const showModal = showStep1 || showStep2;

  const isSupporter = useSelector(state => !!(state.modals.data || {}).isSupporter);
  const isMobileApp = useSelector(state => state.userData.isMobileApp);

  const { removeQueryParams } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsSource = queryParams.get("signup-source");

  const clickSource = useSelector(state => (state.modals.data || {}).clickSource);

  /** location where logged out user clicked to trigger the signup modal **/
  const source = clickSource || queryParamsSource;


  /** for step 2 of signing up, location where user originally clicked to sign up **/
  const signupSource = state => state.userData.user && state.userData.user.signup_source;

  const stepOneText = getStepOneText(source, active, isSupporter);
  const stepTwoText = getStepTwoText(signupSource);

  useEffect(() => {
    if (showModal && !isLoggedIn) {
      gtag("event", "screen_view", { screen_name: "Sign Up Modal" });
      track("Sign Up Modal", { source });
    }
  }, [showModal]);

  const handleCloseModal = () => {
    if (step !== 2) {
      removeQueryParams(["modal"]);
      dispatch(clearActiveModal());
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      id="join_step_one_modal"
      className={classNames(styles.fullMobileModal, {
        [styles.fullPageModalOverride]: isMobileApp
      })}
    >
      <Modal.Body className={classNames(styles.modalBody, styles.modalBody)}>
        {step !== 2 && (
          <Step1
            onClose={handleCloseModal}
            inviterName={inviterName}
            title={isSupporter ? "Support Elpha" : "Join Elpha"}
            text={stepOneText}
          />
        )}
        {step === 2 && <Step2 text={stepTwoText} />}
      </Modal.Body>
    </Modal>
  );
};

const InviterName = ({ name }) => (
  <p>{name} thought you'd enjoy Elpha!</p>
);

const Step2 = ({ text }) => (
  <div className="flex-row">
    <div className="left-col">
      <ModalTitle text="A quick review" />
      <ModalBody text={<p>{text}</p>} />
    </div>
    <div className="right-col">
      <div className="flex-col-middle">
        <JoinBox />
      </div>
    </div>
  </div>
);

const Step1 = ({ inviterName, onClose, title, text }) => (
  <div className="flex-row">
    <div className="left-col">
      <ModalCloseXOut onClick={onClose} />
      <ModalTitle text={title} />
      <ModalBody
        text={
          <>
            {inviterName && <InviterName name={inviterName} />}
            <p>{text}</p>
            <SupporterButton leftAlign={true} />
          </>
        }
      />
    </div>
    <div className="right-col">
      <div className="flex-col-middle">
        <JoinBox />
        <AlreadyAMemberButton />
      </div>
    </div>
  </div>
);

export default JoinModal;
