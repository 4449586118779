import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { oneLiner, memberCount, whatYouGet } from "@app/constants/text";

const useTestChecker = () => {
  const active = useSelector(state => state.userData.active);
  const userData = useSelector(state => state.userData);
  const slug = useSelector(state => state.userData.slug);
  const post = useSelector(state => state.userData.post);
  const isShadowPost = useSelector(state => state.userData.isShadowPost || false);

  const compRelatedSlugs = [
    "state-of-pay-for-women-2022",
    "transparent-salary-playbook",
    "salary-negotiation-tips"
  ];
  const jobRelatedSlugs = [
    "job-search-anxiety",
    "evaluate-next-step-in-career",
    "companies-provide-management-training",
    "technical-interview-process",
    "paid-maternity-leave-companies",
    "post-covid-career-moves-should-you-stay-or-should-you-go",
    "companies-with-inspiring-missions",
    "how-to-prepare-for-and-ace-your-software-engineering-interview",
    "desired-salary",
    "how-to-break-into-senior-roles-starting-today",
    "remote-first-companies",
    "healthtech-startups-hiring-right-now",
    "companies-with-salary-ranges-in-job-descriptions",
    "20-mission-driven-companies",
    "what-to-do-when-laid-off"
  ];
  const postPages = ["posts-active", "posts-recent", "posts-show"];
  const resourcePages = ["resources-show"];
  const isPostPage = postPages.indexOf(active) !== -1;
  const isResourcePage = resourcePages.indexOf(active) !== -1;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const testVersionParam = queryParams.get("tv");

  const tests = postPages + resourcePages;;

  // update for the next test
//  const testVersion = tests.indexOf(active) !== -1 && testVersionParam;
  const testVersion = testVersionParam;

  const isInCommunity = (slug) => {
    return isPostPage && post && post.communities && post.communities.length && post.communities.findIndex((c) => c.slug === slug) !== -1;
  };

  /** TODO short term text **/
  const isContentDownload = isResourcePage && slug === "how-to-quit-your-job-just-started";
  const contentToDownload = "The Transition Document Template";
  const linkToContentToDownload = "https://bit.ly/3zTNGXI";
  const isJobRelatedPage = jobRelatedSlugs.indexOf(slug) !== -1;
  const isCompRelatedPage = compRelatedSlugs.indexOf(slug) !== -1;

  let titleText = "Join Elpha";
  let bodyText = <>Elpha is {oneLiner.toLowerCase()}. Become a member to access {whatYouGet}<br/><br/></>;
  if (isShadowPost) {
    bodyText = <>This post is only available to members. Join Elpha to access this post and more candid, insider conversations from {memberCount} women behind the scenes in tech.<br/><br/></>;

  }
  if (isResourcePage) {
    bodyText = <>Access these exclusive insights and more candid, insider conversations from {memberCount} women behind the scenes in tech.<br/><br/></>;
  }
  if (isContentDownload) {
    titleText = "Want to leave your job gracefully?";
    bodyText = <>The best way to support your coworkers is to put together a detailed transition plan.<br/><br/>We made you a Transition Plan template - get a copy for free!<br/><br/></>;
  }
  if (isJobRelatedPage) {
    titleText = "Join Elpha's Talent Pool";
    bodyText = <>Get matched with companies with the cultural qualities you care about. Create your profile in the Elpha Talent Pool to get contacted by tech's leading workplaces like Grammarly, Gem, Superhuman and more.<br/><br/></>;
  }
  if (isCompRelatedPage) {
    bodyText = <>Talk compensation with {memberCount} women behind the scenes in tech.  Become a member to access {whatYouGet}<br/><br/></>;
  }

  return {
    testVersion,
    isPostPage,
    isResourcePage,
    isJobRelatedPage,
    bodyText,
    titleText,
    isContentDownload,
    contentToDownload,
    linkToContentToDownload,
    isCompRelatedPage,
  };
};

export default useTestChecker;
