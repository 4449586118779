import React, { useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import InputField from "@app/components/common/InputField";
import Alert from "@app/redux/alert/Alert";
import { showSuccessAlert, showErrorAlertFromErr } from "@app/redux/alert";
import InvitationLink from "../../InvitationLink";
import SocialShare from "../../SocialShare";
import ImportContacts from "../../ImportContacts";
import styles from "./index.module.scss";

const ModalContent = ({ onCloseModal, source }) => {
  const modals = useSelector(state => state.modals);
  const { data, active } = modals;
  const { socialUrl, socialText, socialTitle, autoNag, clickSource } = data;
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [emails, setEmails] = useState(undefined);

  const handleEmailChange = emails => {
    setEmails(emails);
  };

  const ackInviteNag = () => {
    $.ajax({
      url: "/account/invite_nagged",
      method: "PUT",
      dataType: "json",
      contentType: "application/json"
    });
  };

  const handleSend = () => {
    const emailObjs = emails.split(",").map(e => { return { email: e, account_type: "member" } });
    $.ajax({
      url: "/invitations",
      method: "POST",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({ emails: emailObjs, type: autoNag ? "nag-modal" : "modal", signup_source: clickSource || source }),
      success: () => {
        setEmails(undefined);
        setSaving(false);
        dispatch(showSuccessAlert("Invited! Thank you :)"))
        dispatch(updateOnboardingChecklist({ key: "sent_invite" }));
      },
      error: e => {
        setSaving(false);
        dispatch(showErrorAlertFromErr(e));
      }
    });
  }

  return (
    <>
      <Alert />
      {!autoNag && (<>
        <p>
          <label className={styles.label}>1. Sharing your invitation code in places like Slack and WhatsApp</label>
          <InvitationLink overrideCode={clickSource === "salary-database" ? clickSource : null} />
        </p>
        <p>
          <label className={styles.labelNoBottom}>2. Spreading the word on social media</label>
          <SocialShare
            socialUrl={socialUrl}
            socialText={socialText}
            socialTitle={socialTitle}
          />
        </p>
      </>)}
      <p>
        <label>{autoNag ? 'S' : '3. Or s'}ending them an email</label>
        <ImportContacts />
        <InputField
          value={emails || ""}
          placeholder="A comma separated list of email addresses"
          onChange={handleEmailChange}
          componentClass="textarea"
        />
      </p>
      <button className="pull-left btn btn-default-two" onClick={onCloseModal}>
        Close
      </button>
      <button
        className="pull-right btn btn-primary"
        disabled={saving || !emails}
        onClick={handleSend}
      >
        Send invitations
      </button>
    </>
  );
};
export default ModalContent;
