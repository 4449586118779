import axios from "axios";

import { setCSRFTokenForRequest } from "@app/api/utils";

const applicationsAPI = {
  createApplication: (position, newStatus, skipAts) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/hiring/applications/jobs/${position.id}`,
        { status: newStatus, skip_ats: skipAts }
      );
    });
  },
  updateApplication: (position, newStatus, skipAts) => {
    return setCSRFTokenForRequest(() => {
      return axios.put(`/hiring/applications/jobs/${position.id}`,
        { status: newStatus, skip_ats: skipAts }
      );
    });
  },
};

export default applicationsAPI;
