import React, { useState } from "react";
import { useSelector } from "react-redux";
import { dompurify } from "@app/components/common/utils";
import classNames from "classnames";
import styles from "./index.module.scss";

const InvitationLink = ({ overrideCode }) => {
  const [copied, setCopied] = useState(false);
  const userData = useSelector(state => state.userData);
  const user = userData.user || {};
  const username = dompurify.sanitize((user || {}).username);
  let code = overrideCode || `from-${username || 'a-friend'}`;
  const inviteUrl = `https://elpha.com/invitations/${code}`;
  const inviteUrlRef = React.createRef();

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(inviteUrl);
    } catch (err) {
      inviteUrlRef.current.select();
      inviteUrlRef.current.setSelectionRange(0, 99999); /* For mobile devices */
      document.execCommand("copy");
    } finally {
      setCopied(true);
    }
  };

  return (
    <div className={styles.link}>
      <input
        className={classNames(styles.input, {
          [styles.animateAction]: copied
        })}
        disabled
        readOnly
        value={inviteUrl}
        placeholder={inviteUrl}
        ref={inviteUrlRef}
        onClick={copyToClipboard}
      />
      <button className={classNames(styles.buttonPrimary, styles.buttonSmall, styles.buttonCopy)} onClick={copyToClipboard}>
        {copied ? "Copied" : "Copy"}
      </button>
    </div>
  );
};
export default InvitationLink;
