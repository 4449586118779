import React from "react";
import { FormGroup, FormControl, ControlLabel, HelpBlock } from "react-bootstrap";

const InputField = props => {
  const handleChange = e => {
    props.onChange(e.target.value);
  };

  const renderWarning = () => {
    if (props.warning) {
      return <div className="type-warning">{props.warning}</div>;
    }
    return null;
  };

  const renderLabel = () => {
    if (props.label) {
      return <ControlLabel>{props.label}</ControlLabel>;
    }
    return null;
  };

  const optionalProps = {};
  if (props.required) {
    optionalProps.validationState = props.value ? "success" : "error";
  }
  return (
    <FormGroup {...optionalProps} controlId={props.controlId}>
      {renderLabel()}
      {renderWarning()}
      <FormControl
        id={props.id}
        disabled={props.disabled}
        type={props.type || "text"}
        {...props.formControlProps}
        componentClass={props.componentClass}
        value={props.value}
        autoComplete={props.autocomplete}
        placeholder={props.placeholder}
        onChange={handleChange}
      >
        {props.children}
      </FormControl>
      <FormControl.Feedback />
      {props.helpBlock && <HelpBlock>{props.helpBlock}</HelpBlock>}
    </FormGroup>
  );
};
export default InputField;
