import axios from "axios"
import { setCSRFTokenForRequest } from "./utils";

export default {
  clickToDownload: (email, contentToDownload, linkToContentToDownload) => {
    return setCSRFTokenForRequest(() => {
      return axios.post('/download', {
        email,
        content_to_download: contentToDownload,
        link_to_content_to_download: linkToContentToDownload
      });
    });
  },
  fetchUserData: () => {
    const data = document.getElementById("data");
    const stringData = data && data.getAttribute("data-react");
    const parsedData = JSON.parse(stringData || "{}");
    return parsedData;
  },
  bookmarkPost: (path, slug) => {
    return setCSRFTokenForRequest(() => axios.put(`/posts/${path}/${slug}/bookmark`))
  },
  unbookmarkPost: (path, slug) => {
    return setCSRFTokenForRequest(() => axios.put(`/posts/${path}/${slug}/unbookmark`))
  },
};
