import React from "react";
import classNames from "classnames";
import NewPost from "./NewPostObject";
import styles from "./index.module.scss";

const NewPostPage = () => {
  return (
    <div classNames={classNames(styles.root, "row")}>
      <div className="col-sm-push-2 col-sm-8 col-xs-12">
        <NewPost />
      </div>
    </div>
  );
}
export default NewPostPage;
