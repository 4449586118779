import constants from "./constants";

export const getStepTwoText = (signupSource) => {
  if (signupSource === constants.TOP_WORKPLACES_SOURCE) {
    return constants.RATE_COMPANY_REVIEW_TEXT;
  }
  return constants.DEFAULT_REVIEW_TEXT;
};
export const getStepOneText = (source, active, isSupporter) => {
  if (source === constants.COMMUNITY_SOURCE) {
    return constants.COMMUNITY_TEXT;
  }
  if (source === constants.SALARY_DATABASE_SOURCE) {
    return constants.SALARY_DATABASE_TEXT;
  }
  if (source === constants.TOP_WORKPLACES_SOURCE) {
    return constants.TOP_WORKPLACES_TEXT;
  }
  if (isSupporter) {
    return constants.SUPPORTER_TEXT;
  }
  if (source === constants.GET_INTRO_SOURCE) {
    return constants.GET_INTRO_TEXT;
  }
  if (constants.JOB_SOURCES_ARRAY.indexOf(source) !== -1) {
    return constants.JOBS_TEXT;
  }
  if (constants.APPLY_TO_JOBS_SOURCES_ARRAY.indexOf(source) !== -1) {
    return constants.APPLY_TO_JOBS_TEXT;
  }
  if (source === constants.ANON_POST_SOURCE) {
    return constants.ANON_POST_TEXT;
  }
  return constants.CUSTOM_TEXT(source || active) || constants.DEFAULT_TEXT;
};

