import { createSlice } from "@reduxjs/toolkit";
import accountAPI from "./api";

const account = createSlice({
  name: "account",
  initialState: {
    notificationsCount: 0,
    messagesCount: 0,
  },
  reducers: {
    accountAlertsLoaded(state, action) {
      state.notificationsCount = action.payload.notificationsCount;
      state.messagesCount = action.payload.messagesCount;
      return state;
    },
  },
});

export const { accountAlertsLoaded } = account.actions;

export const fetchAccountAlerts = () => async dispatch => {
  try {
    const data = await accountAPI.fetchAccountAlerts();
    return dispatch(accountAlertsLoaded(data));
  } catch (e) {
    // NOOP
  }
};

export default account.reducer;
