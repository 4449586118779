import axios from "axios";

import { setCSRFTokenForRequest } from "@app/api/utils";

const postAPI = {
  fetchFeaturedMemberFavors: () => {
    return setCSRFTokenForRequest(() => (
      axios.get("/posts/featured-member-favors.json")
    ))
  },
  fetchFeaturedMemberOffers: () => {
    return setCSRFTokenForRequest(() => (
      axios.get("/posts/featured-member-offers.json")
    ))
  },
  fetchPostsNeedingEngagement: () => {
    return setCSRFTokenForRequest(() => (
      axios.get("/posts/needing-engagement.json")
    ))
  },
  markSpam: (postPath, postSlug) => {
    return setCSRFTokenForRequest(() => {
      return axios.put(`/posts/${postPath}/${postSlug}/spam`);
    });
  },
  deletePost: (postPath, postSlug) => {
    return setCSRFTokenForRequest(() => {
      return axios.delete(`/posts/${postPath}/${postSlug}`);
    });
  },
  createReaction: (postPath, postSlug, unicode, shortcode) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/posts/${postPath}/${postSlug}/reaction`, { unicode, shortcode });
    });
  },
  deleteReaction: (postPath, postSlug, unicode) => {
    return setCSRFTokenForRequest(() => {
      return axios.delete(`/posts/${postPath}/${postSlug}/reaction/${unicode}`);
    });
  },
  fetchLoggedOutPosts: (label, type = null, limit = null, testParam = null) => {
    const params: {
      limit?: boolean,
      tv?: string
    } = {};
    if (limit) params.limit = limit;
    if (testParam) params.tv = testParam;

    const url = label === "latest" ? "/logged_out/latest.json" :
      `/logged_out/${type}/${label}.json`;

    return setCSRFTokenForRequest(() => {
      return axios.get(url, { params });
    });
  },
  flagContent: (postOrCommentUrl, params) => {
    return setCSRFTokenForRequest(() => {
      return axios.put(`${postOrCommentUrl}/flag`, params);
    });
  },
  blockMember: (username) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/members/${username}/block`);
    });
  },
};

export default postAPI;
