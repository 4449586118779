import {
  BlockquoteButton,
  BoldButton,
  CodeBlockButton,
  CodeButton,
  HeadlineThreeButton,
  HeadlineTwoButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton,
} from "@draft-js-plugins/buttons";
import React, { useState } from "react";
import { Separator } from "@draft-js-plugins/static-toolbar";
import { ToolbarChildrenProps } from "@draft-js-plugins/static-toolbar/lib/components/Toolbar";

import "@draft-js-plugins/static-toolbar/lib/plugin.css";

import AddImageButton from "./AddImageButton";

import styles from "./index.module.scss";

const addAlignmentCorrectionClass = (
  externalProps: React.PropsWithChildren<ToolbarChildrenProps>
): React.PropsWithChildren<ToolbarChildrenProps> => ({
  ...externalProps,
  theme: {
    ...externalProps.theme,
    buttonWrapper: `${externalProps.theme.buttonWrapper} ${styles.alignmentCorrection}`,
  },
});

const RichTextToolbar = ({ Toolbar, LinkButton }) => (
  <Toolbar>
    {externalProps => (
      <>
        <BoldButton {...externalProps} />
        <ItalicButton {...externalProps} />
        <UnderlineButton {...externalProps} />
        <CodeButton {...externalProps} />
        <Separator />
        <HeadlineTwoButton {...addAlignmentCorrectionClass(externalProps)} />
        <HeadlineThreeButton {...addAlignmentCorrectionClass(externalProps)} />
        <Separator />
        <UnorderedListButton {...externalProps} />
        <OrderedListButton {...externalProps} />
        <CodeBlockButton {...externalProps} />
        <Separator />
        <AddImageButton {...externalProps} />
        <LinkButton {...externalProps} />
      </>
    )}
  </Toolbar>
);

export default RichTextToolbar;
