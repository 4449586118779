import React from "react";
import { useSelector } from "react-redux";
import styles from "./index.module.scss";
import Thumbnail from "@app/components/common/Thumbnail";

const THUMBNAIL_URL = "https://d27zilrr3yp6cg.cloudfront.net/landing/Lindsey-nova-credit-thumb.png";

const SuccessStoriesWidget = () => {
  const isJobPost = useSelector(state => state.userData.isJobPost);
  const isJobCommunity = useSelector(state => ["job-board", "job-hunting"].includes((state.userData.community || {}).slug));
  if (isJobPost || isJobCommunity) {
    return (
      <div className={styles.root}>
        <div className={styles.sideBarSubheader}>
          <span className={styles.subheaderMainText}>success stories</span>
          <a target="_blank" href="/companies/success" className={styles.subheaderSubtext}>read more</a>
        </div>
        <blockquote className={styles.blockquote}>
          <p className={styles.quoteText}>After joining Elpha, I made a single post about my frustrations and got over a dozen responses from supportive women willing to help/offer advice...</p>
        </blockquote>
        <div className={styles.thumbnailContainer}>
          <Thumbnail clazz={styles.thumbnail} imgUrl={THUMBNAIL_URL} />
          <span className={styles.thumbnailNameCaption}>Lindsey Signorelli</span>
        </div>
      </div>
    );
  }
  return null;
};
export default SuccessStoriesWidget;
