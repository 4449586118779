import React from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import { setActiveModal } from "@app/redux/modals";
import styles from "./index.module.scss";

const SideBarSignUp = () => {
  const dispatch = useDispatch();

  const handleJoinClick = () => {
    dispatch(setActiveModal({ active: "signup" }));
  };

  return (
    <div className={styles.root}>
      <div className={styles.caption}>
        Find support, learn from experts, and make friends
      </div>
      <button className={classNames(styles.buttonPrimary, styles.button)} onClick={handleJoinClick}>Sign up for free</button>
    </div>
  );
};
export default SideBarSignUp;
