import axios from "axios";

import { setCSRFTokenForRequest } from "@app/api/utils";

const introRequestsAPI = {
  lookupIntro: (companySlug) => {
    return setCSRFTokenForRequest(() => {
      return axios.get(`/me/companies/${companySlug}/intros`);
    });
  },
  requestIntro: (company, intro) => {
    return setCSRFTokenForRequest(() => {
      return axios.post(`/me/companies/${company.slug}/intros/`, {
        company: company.name,
        first_name: intro.first_name,
        last_name: intro.last_name,
        description: intro.description,
        linkedin: intro.linkedin,
        email: intro.email
      });
    });
  },
};
export default introRequestsAPI;
