import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import SideBarSpinner from "@app/components/SideBars/Spinner";
import ThumbnailWrapper from "../ThumbnailWrapper";
import { track } from "@app/components/common/analytics";
import styles from "./index.module.scss";
import useFeaturedMemberFavorsLoader from "./useFeaturedMemberFavorsLoader";

const Posts = ({ posts }) => {
  const handleClick = (post) => {
    track("Featured Member Ask Clicked", {
      postTitle: post.title,
      postId: post.id
    });
  };

  return (
    <>
    {posts.map((post => {
      return (
        <div key={post.url} className={styles.thumbnailContainerNoPadding}>
          <a href={post.url} className={styles.url} onClick={() => handleClick(post)}>
            <ThumbnailWrapper user={post.author} />
            <div className={styles.thumbnailTextContainer}>
              <div className={styles.thumbnailTitle}>{post.title}</div>
              <div className={styles.thumbnailNameCaption}>{post.author.username}</div>
            </div>
          </a>
        </div>
      );
    }))}
    </>
  );
};

const FeaturedMemberFavorsWidget = () => {
  const { isLoading, posts } = useFeaturedMemberFavorsLoader();

  return (
    <div className={styles.root}>
      <div className={styles.sideBarSubheader}>
        <span className={styles.text}>🙏 member favors</span>
        <a target="_blank" href="/posts/new?community_slug=favors&community=favors" className={styles.subtext}>post</a>
      </div>
      { isLoading ? <SideBarSpinner /> : <Posts posts={posts} /> }
      { !isLoading && posts.length === 0 && <div className={styles.none}>No favors right now</div>}
    </div>
  );
};
export default FeaturedMemberFavorsWidget;
