import axios from "axios";

import { setCSRFTokenForRequest } from "@app/api/utils";

const articlesAPI = {
  getTopArticles: (filters, count) => {
    return setCSRFTokenForRequest(() => {
      return axios.get(`/resources/articles_for_section?filters=${filters}&count=${count}`);
    });
  },
};

export default articlesAPI;
