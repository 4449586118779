import React from "react";
import { MONTH_ABBRS } from "@app/constants/dateOptions";
import DOMPurify from "dompurify";

// Add a hook to make all links open a new window
DOMPurify.addHook("afterSanitizeAttributes", function(node) {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "nofollow");
  }
  // set non-HTML/MathML links to xlink:show=new
  if (
    !node.hasAttribute("target") &&
    (node.hasAttribute("xlink:href") || node.hasAttribute("href"))
  ) {
    node.setAttribute("xlink:show", "new");
  }
});

export const dompurify = DOMPurify;

export const decodeHtml = html => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

export const parseAddress = (addressArr, type, isShort) => {
  const ele =
    addressArr.find(element => {
      return element.types.indexOf(type) !== -1;
    }) || {};
  return isShort ? ele.short_name : ele.long_name;
};

export const cleanUrl = dirtyUrl => {
  let url = (dirtyUrl || "").trim();
  if (url) {
    url = url.indexOf("://") === -1 ? `http://${url}` : url;
    return url.indexOf("://") === -1 ? `https://${url}` : url;
  }
  return undefined;
};

const colors = [
  "anon-yellow",
  "anon-pink",
  "anon-salmon",
  "anon-purple",
  "anon-blue",
];
export const textToColor = text => {
  const rand = (text || "")[0].charCodeAt(0);
  return colors[Math.floor(rand % colors.length)];
};

/**
 * Render a date range as a string, showing ` - current` if the "to" date is
 * missing. Currently only handles months and years (not day of month), but
 * could be extended to handle this in the future.
 */
export const getDateRange = (fromMonth, fromYear, toMonth, toYear) => {
  const fromMonthName = fromMonth ? MONTH_ABBRS[fromMonth - 1].label : undefined;
  const toMonthName = toMonth ? MONTH_ABBRS[toMonth - 1].label : undefined;

  let dates = "";
  if (fromMonthName && fromYear && !toMonthName && !toYear) {
    dates = `${fromMonthName} ${fromYear} - present`;
  } else if (fromMonthName && fromYear && toMonthName && toYear) {
    dates = `${fromMonthName} ${fromYear} - ${toMonthName} ${toYear}`;
  } else if (fromMonthName && fromYear && toMonthName) {
    dates = `${fromMonthName} ${fromYear} - ${toMonthName}`;
  } else if (fromMonthName && fromYear && toYear) {
    dates = `${fromMonthName} ${fromYear} - ${toYear}`;
  }

  return dates;
};

export const parseError = e => {
  if (e && e.responseJSON && e.responseJSON.errors) {
    return e.responseJSON.errors.join(". ");
  }
  if (e && e.response && e.response.body) {
    return e.response.body.errors;
  }
  if (e && e.errorMsg) {
    return e.errorMsg;
  }
  return "Something went wrong! Please contact support@elpha.com for help.";
};

export const replaceAtMentionsWithLinks = text => {
  const textString = text || "";
  if (/@{{([a-z\d_]+)}}/.test(textString)) {
    return textString.replace(
      /@{{([a-z\d_]+)}}/gi,
      '<a href="https://elpha.com/members/$1">@$1</a>'
    );
  }
  return textString.replace(
    /( |^)@([a-z\d_]+)/gi,
    '$1<a href="https://elpha.com/members/$2">@$2</a>'
  );
};

