import React from "react";
import { EntryComponentProps } from "@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry";

import styles from "./index.module.scss";
import Thumbnail from "@app/components/common/Thumbnail";

/**
 * Display a single mention in the mentions dropdown. This follows the "Custom
 * Themed Mention Example" at https://www.draft-js-plugins.com/plugin/mention
 */
const MentionEntry: React.FC<EntryComponentProps> = props => {
  const { mention, theme, searchValue, isFocused, ...parentProps } = props;

  return (
    <div {...parentProps}>
      <div className={styles.root}>
        <Thumbnail
          className={styles.image}
          imgUrl={mention.avatar}
          alt={`The thumbnail for ${mention.name}`}
        />

        <div className={styles.text}>
          <span>{mention.name}</span>

          {mention.fullName && (
            <span className={styles.fullName}>{mention.fullName}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default MentionEntry;
