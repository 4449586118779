import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showErrorAlertFromErr } from "@app/redux/alert";
import CommunityMember from "./CommunityMember";
import SideBarSpinner from "@app/components/SideBars/Spinner";
import styles from "./shared.module.scss";

const CommunityWidget = ({ fetchMembers, sectionTitle, skipSelf, primaryCTA }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const loggedInUsername = useSelector(state => (state.userData.user || {}).username);
  const communitySlug = useSelector(state => (state.userData.community || {}).slug);

  const fetchMembersWrapper = async () => {
    try {
      const { data } = await fetchMembers(communitySlug);
      if (skipSelf) {
        setMembers((data.members || []).filter((member) => member.username !== loggedInUsername));
      } else {
        setMembers(data.members || []);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch(showErrorAlertFromErr(e));
    }
  };

  useEffect(() => {
    fetchMembersWrapper();
  }, []);

  if (!isLoading && members.length === 0) {
    return null;
  }
  return (
    <div className={styles.widgetRoot}>
      <div className={styles.header}>{sectionTitle}</div>
      {isLoading && <SideBarSpinner />}
      {
        members.map((member, index) => (
          <CommunityMember key={index} member={member} />
        ))
      }
      {primaryCTA}
    </div>
  );
};
export default CommunityWidget;
