import { createSlice } from "@reduxjs/toolkit";
import articlesAPI from "./api";
import { showErrorAlertFromErr } from "../alert";

const initialState = {
  articles: [],
  loading: false,
};

const articles = createSlice({
  name: "articles",
  initialState,
  reducers: {
    setArticles(state, action) {
      state.articles = action.payload; 
    },
    setLoading(state, action) {
      state.loading = action.payload;
    }
  },
});

export const fetchTopArticles = (filters, count) => async dispatch => {
  const { setArticles, setLoading } = articles.actions;
  try {
    dispatch(setLoading(true));
    const { data } = await articlesAPI.getTopArticles(filters, count);
    dispatch(setArticles(data));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(setLoading(false));
    dispatch(showErrorAlertFromErr(e));
  }
}

export default articles.reducer;
