import { createSlice } from "@reduxjs/toolkit";

import combineSlices from "@app/helpers/combineSlices";
import hiringAPI from "../api";
import parseError from "@app/helpers/parseError";
import replyMadLibs from "./replyMadLibs";
import replyMadLibsByHash from "./replyMadLibsByHash";

const initialState = {
  items: [],
  isFetching: false,
  errorMessage: undefined,
  hasData: false,
  replyingToIntroID: undefined,
  initialMadLibsAcceptOrReject: undefined,
  replyMadLibsForIntroId: undefined,
  page: 1,
  totalPages: 1,
};

const companyIntros = createSlice({
  name: "hiring/companyIntros",
  initialState,
  reducers: {
    setItems(state, action) {
      state.items = action.payload.items;
      state.totalPages = action.payload.total_pages;
      state.hasData = true;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    setReplyingToIntroID(state, action) {
      state.replyingToIntroID = action.payload;
    },
    openReplyMadLibs(state, action) {
      state.replyMadLibsForIntroId = action.payload.id;
      state.initialMadLibsAcceptOrReject = action.payload.acceptOrReject;
    },
    closeReplyMadLibs(state) {
      state.replyMadLibsForIntroId = undefined;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
  },
});

export const {
  openReplyMadLibs,
  closeReplyMadLibs,
  setItems,
} = companyIntros.actions;

/**
 * @param {object} [filters] - Additional params to pass in the request, such as
 * `connectionStates`, `scope`, etc.
 * @param {number} [page] - Which page number to fetch.
 * @param {number} [perPage] - How many items per page to fetch.
 */
export const fetchIntros = (filters, page, perPage) => async dispatch => {
  const {
    setItems,
    setIsFetching,
    setErrorMessage,
    setPage,
  } = companyIntros.actions;

  dispatch(setErrorMessage(false));
  dispatch(setIsFetching(true));
  dispatch(setPage(page));

  try {
    const { data } = await hiringAPI.fetchIntros(filters, page, perPage);
    dispatch(setItems(data));
  } catch (e) {
    const errorMessage = parseError(
      e,
      "There was an error loading the intros."
    );
    dispatch(setErrorMessage(errorMessage));
  }

  dispatch(setIsFetching(false));
};

/**
 * @param {string} id - The database ID of the intro
 * @param {("accept" | "reject")} acceptOrReject - Whether to "accept" or
 * "reject" the intro
 * @param {object} [filters] - Additional params to pass in the request, such as
 * `connectionStates`, `scope`, etc.
 * @param {number} [page] - Which page number to fetch in the returned intros.
 * This should be the same as was used to generate the list in the UI that a
 * user accepted/rejected this intro for, because this action will update that
 * list from the response from the server.
 * @param {number} [perPage] - How many items per page to fetch. This should be
 * the same as was used to generate the list in the UI that a user
 * accepted/rejected this intro for, because this action will update that list
 * from the response from the server.
 */
export const replyToIntro = (
  id,
  acceptOrReject,
  filters,
  page,
  perPage
) => async (dispatch, getState) => {
  const { setItems, setReplyingToIntroID } = companyIntros.actions;

  const { items } = getState().hiring.companyIntros;
  const intro = items.find(intro => intro.id === id);
  if (!intro) {
    return dispatch(
      showErrorAlert(
        "The intro you were trying to reply to could not be found."
      )
    );
  }

  dispatch(setReplyingToIntroID(id));

  try {
    const { data: updatedIntros } = await hiringAPI.replyToIntro(
      id,
      acceptOrReject,
      filters,
      page,
      perPage
    );
    dispatch(setItems(updatedIntros));
  } catch (e) {
    dispatch(showErrorAlertFromErr(e));
  }

  dispatch(setReplyingToIntroID(undefined));
};

export default combineSlices(companyIntros.reducer, initialState, {
  replyMadLibs,
  replyMadLibsByHash,
});
