import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Alert from "@app/redux/alert/Alert";
import InputField from "@app/components/common/InputField";
import useTestChecker from "@app/hooks/useTestChecker";
import useSignupToDownload from "@app/hooks/useSignupToDownload";
import styles from "./shared.module.scss";

const EmailToDownload = ({
  show,
  onClose
}) => {

  const handleClose = () => {
    onClose && onClose();
    resetForm();
  };

  const { contentToDownload, linkToContentToDownload } = useTestChecker();

  const { resetForm, handleEmailChange, handleSubmit, saving, success, email } = useSignupToDownload({
    defaultEmail: undefined,
    analyticsEventName: "Email to Download Popup",
    contentToDownload,
    linkToContentToDownload
  });


  return (
    <div className={styles.formRoot}>
      <Alert />
      {success && <div className={styles.successAlert}>Nice! Your free template is in your inbox  🎉.</div>}
      {!success && (<InputField
        value={email || ""}
        placeholder="Your email*"
        onChange={handleEmailChange}
      />)}
      {!success && (<Button
          bsStyle="primary"
          type="submit"
          className={styles.formButton}
          disabled={saving || !email}
          onClick={handleSubmit}
        >
          Get it!
      </Button>)}
    </div>
  );
};
export default EmailToDownload;
